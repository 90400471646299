import React, { useState } from "react"

// component
import Container from "../Common/Container/Container"
import SubTitle from "../Common/SubTitle/SubTitle"

// keys
import keys from "../../config/keys"

const Content = ({ userData }) => {
  const [isError, setIsError] = useState(false)

  const getCharacter = () => {
    return userData.nickname.charAt(0)
  }

  return (
    <div className="py-8  px-4 sm:px-0 sm:py-20 xl:py-24">
      <Container>
        <SubTitle title1="Your" title2="Account" />
        <div className="mt-8">
          <div className="flex gap-2 items-center flex-row sm:items-stretch">
            <div className="w-20 h-20 rounded-full overflow-hidden bg-txt-light flex items-center justify-center">
              {!isError ? (
                <img
                  src={userData?.picture}
                  alt={userData?.nickname}
                  className="w-full h-full object-cover"
                  onError={() => setIsError(true)}
                />
              ) : (
                <span className="text-white text-hd-sm md:hd-lg lg:text-hd-xl font-semibold capitalize">
                  {getCharacter()}
                </span>
              )}
            </div>
            <div className="flex flex-col justify-end items-center sm:items-start">
              <p className="text-txt-dark text-hd-sm md:hd-lg lg:text-hd-xl font-semibold capitalize">
                {userData?.nickname}
              </p>
              <span className="text-txt-light text-sm">{userData?.email}</span>
            </div>
          </div>
          <p className="mt-8">
            <span className="font-bold">Course purchased</span>:{" "}
            {userData?.app_metadata?.plan || "none"}
          </p>
          <p className="mt-1">
            <span className="font-bold">Course purchased date</span>:{" "}
            {userData?.app_metadata?.purchased_date}
          </p>
          <p className="mt-1">
            <span className="font-bold">Course expiration date</span>:{" "}
            {userData?.app_metadata?.expiration_date}
          </p>
        </div>
      </Container>
    </div>
  )
}

export default Content
