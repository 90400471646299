import React, { useState, useEffect } from "react"
import "../../styles/custom.css"

import { FaQuestion } from "react-icons/fa"

const Todo = ({ id, data, toRight, hanldeClick }) => {
  const { title, isChecked, description } = data

  const [checked, setChecked] = useState(isChecked || false)

  useEffect(() => {
    setChecked(isChecked || false)
  }, [isChecked])

  const handleChange = () => {
    setChecked(!isChecked)
    hanldeClick(data, !isChecked)
  }

  return (
    <div className="flex items-center gap-2">
      <input
        id={id}
        type="checkbox"
        value=""
        onChange={handleChange}
        checked={checked}
        className="flex-shrink-0"
      />
      <label
        for={id}
        className={`text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer select-none hover:opacity-80 ${
          checked && "line-through"
        }`}
      >
        {title}
      </label>
      {description && (
        <div className="relative">
          <span className="w-6 h-6 rounded-full bg-[#ecf5fa] peer flex items-center justify-center cursor-pointer">
            <FaQuestion className="text-[#81acc9]" />
          </span>
          <div
            className={`absolute top-[2rem] w-[280px] p-4  bg-[#ecf5fa] rounded text-xs text-txt-med hidden z-50 peer-hover:block ${
              toRight ? "right-0" : "right-0 sm:right-auto sm:left-0"
            }`}
          >
            {description}
          </div>
        </div>
      )}
    </div>
  )
}

export default Todo
