import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useAuth0 } from "@auth0/auth0-react"

// components
import Dropdown from "./Dropdown"
import Profile from "./Profile"

// styles
import "./Header.css"

export function LogoNavbar2() {
  return <StaticImage src="../../images/logoNavbar.png" alt="Esteekey Logo" />
}

export default function Header() {
  const { user, isAuthenticated, isLoading, loginWithRedirect } =
    useAuth0() || {
      user: undefined,
      isAuthenticated: false,
      isLoading: false,
      loginWithRedirect: null,
    }

  const [showMenu, setShowMenu] = useState(false)

  const toggle = useCallback(
    () => setShowMenu(!showMenu),
    [showMenu, setShowMenu]
  )

  return (
    <div className="fixed top-0 xl:static z-[999] w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800">
      <div className="flex flex-col max-w-screen-xl px-4 mx-auto xl:items-center xl:justify-between xl:flex-row xl:px-6  lg:px-8 2xl:px-0 lg:px-4">
        <div className="py-4 flex flex-row items-center justify-between">
          <Link to="/">
            <div className="-ml-1 w-32 z-50">
              <LogoNavbar2 />
            </div>
          </Link>
          <button
            className="w-6 h-6 block xl:hidden rounded-lg focus:outline-none focus:shadow-outline"
            onClick={toggle}
          >
            <span
              aria-hidden="true"
              className={
                showMenu
                  ? "block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out rotate-45"
                  : "block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out -translate-y-1.5"
              }
            ></span>
            <span
              aria-hidden="true"
              className={
                showMenu
                  ? "block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out opacity-0"
                  : "block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out"
              }
            ></span>
            <span
              aria-hidden="true"
              className={
                showMenu
                  ? "block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out -rotate-45"
                  : "block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out translate-y-1.5"
              }
            ></span>
          </button>
        </div>
        <nav
          className={
            showMenu
              ? "transition-all flex-col flex-grow flex pb-4 xl:pb-0 xl:flex xl:justify-end xl:flex-row"
              : "flex-col flex-grow hidden pb-4 xl:pb-0 xl:flex xl:justify-end xl:flex-row xl:items-center"
          }
        >
          <Link
            className="transition duration-200 ease-in-out px-0 lg:px-4 py-2 mt-2 text-sm hover:text-blue300 focus:text-blue300 rounded-lg dark-mode:bg-gray-700  xl:mt-0 focus:outline-none focus:shadow-outline"
            to="/"
          >
            Регистрация
          </Link>
          <Link
            className="transition duration-200 ease-in-out px-0 lg:px-4 py-2 mt-2 text-sm hover:text-blue300 focus:text-blue300 xl:mt-0 xl:ml-4 focus:outline-none focus:shadow-outline"
            to="/dashboard"
          >
            Личный кабинет
          </Link>
          <Link
            className="transition duration-200 ease-in-out px-0 lg:px-4 py-2 mt-2 text-sm hover:text-blue300 focus:text-blue300 xl:mt-0 xl:ml-4 focus:outline-none focus:shadow-outline"
            to="/videos"
          >
            Видео
          </Link>
          <Link
            className="transition duration-200 ease-in-out px-0 lg:px-4 py-2 mt-2 text-sm hover:text-blue300 focus:text-blue300 xl:mt-0 xl:ml-4 focus:outline-none focus:shadow-outline"
            to="/recipes"
          >
            Рецепты
          </Link>
          <Link
            className="transition duration-200 ease-in-out px-0 lg:px-4 py-2 mt-2 text-sm hover:text-blue300 focus:text-blue300 xl:mt-0 xl:ml-4 focus:outline-none focus:shadow-outline"
            to="/shopping-list"
          >
            Список покупок
          </Link>
          <Dropdown />
          {/* <Link
            className="transition duration-200 ease-in-out px-0 lg:px-4 py-2 mt-2 text-sm hover:text-blue300 focus:text-blue300 xl:mt-0 xl:ml-4 focus:outline-none focus:shadow-outline"
            to="/plans"
          >
            Plans
          </Link> */}
          {!isLoading && (
            <>
              {!isAuthenticated ? (
                <button
                  className="transition duration-200 ease-in-out btn-sign cursor-pointer hover:bg-blue200 ml-0 bg-blue300 text-white font-bold py-2 px-0 lg:px-4 rounded-lg text-center mr-0  lg:ml-4 lg:mr-3 mt-5 xl:mt-0"
                  style={{
                    boxShadow: "0px 12px 35px rgba(123, 165, 199, 0.3)",
                  }}
                  onClick={() => {
                    if (!isAuthenticated) {
                      loginWithRedirect()
                    }
                  }}
                >
                  <span className="xl:px-2">Войти</span>
                </button>
              ) : (
                <Profile user={user} />
              )}
            </>
          )}
        </nav>
      </div>
    </div>
  )
}
