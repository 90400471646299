import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { Link } from "gatsby"

// components
import { TodoList, Tracker } from ".."
import Container from "../Common/Container/Container"
import MealPlan from "../MealPlan/MealPlan"
import WeeklyPlan from "../WeeklyPlan/WeeklyPlan"
import MealChangeModal from "../Common/Modal/MealChangeModal"
import NewMealChangeModal from "../Common/Modal/NewMealChangeModal"
import MealGenerateModal from "../Common/Modal/MealGenerateModal"
import { SubTitle } from ".."

// util
import { getTotalCalories } from "../../utils/helper"

// Context
import UserContext from "../../context/UserAuth"

// assets
import { MdOutlineAddShoppingCart } from "react-icons/md"

const Dashboard = ({
  list,
  recipeList,
  userData,
  setUserData,
  generate,
  setCurrWeek,
  currWeek,
  startDate,
  setWeekDay,
  weekDay,
}) => {
  // const [mealList, setMealList] = useState(list[0].meal_plan)
  const [showMealModal, setShowMealModal] = useState(false)
  const [showGenModal, setShowGenModal] = useState(false)
  const [showNewMealModal, setShowNewMealModal] = useState(false)
  const [mealData, setMealData] = useState(null)
  const [calories, setCalories] = useState(null)
  const [total, setTotal] = useState(0)

  const [disableWeekDays, setDisableWeekDays] = useState(false)

  const [newList, setNewList] = useState([])
  useEffect(() => {
    if (userData && recipeList) {
      setNewList(
        getFilterList(currWeek.mealDay, userData?.mealPlan || [], recipeList)
      )
    }
  }, [recipeList, userData])

  useEffect(() => {
    if (newList)
      setTotal(
        removeDuplicate(newList).reduce(
          (acc, cur) => acc + (cur?.calories || cur?.mealCals),
          0
        )
      )
  }, [newList])

  const removeDuplicate = _list => {
    let uniqueList = _list.filter(
      (value, index, self) => index === self.findIndex(t => t._id === value._id)
    )

    return uniqueList
  }

  // useEffect(() => {
  //   if (newList.length > 0)
  //     setNewList(getFilterList(0, userData.mealPlan, recipeList))
  // }, [currWeek])

  const onListChange = _day => {
    if (userData.mealPlan) {
      let filterList = getFilterList(_day, userData?.mealPlan || [], recipeList)
      setNewList(filterList)
      setCurrWeek({
        ...currWeek,
        mealDay: _day,
      })
    }
  }

  const toggleMealModal = (_check, _data) => {
    if (_check) {
      setMealData(_data)
      setShowMealModal(_check)
      document.body.style.overflow = "hidden"
    } else {
      setShowMealModal(_check)
      document.body.style.overflow = "visible"
    }
  }

  const toggleNewMealModal = (_check, _data) => {
    if (_check) {
      setMealData(_data)
      setShowNewMealModal(_check)
      document.body.style.overflow = "hidden"
    } else {
      setShowNewMealModal(_check)
      document.body.style.overflow = "visible"
    }
  }

  const toggleGenModal = _check => {
    if (_check) {
      setShowGenModal(_check)
      document.body.style.overflow = "hidden"
    } else {
      setShowGenModal(_check)
      document.body.style.overflow = "visible"
    }
  }

  const onMealChange = (_id, _day, _servings, _meal) => {
    let userMealList = userData.mealPlan.map(item => {
      if ((item?._id || item?.meal) === _id && _day === item.day) {
        return {
          ..._meal,
          day: _day,
        }
      } else {
        return item
      }
    })

    let data = {
      ...userData,
      mealPlan: userMealList,
    }

    setUserData(data)

    axios({
      method: "post",
      url: "https://esteekeyapi.com/update-meal",
      data: data,
    })
      .then(res => {
        toast.success("Meal plan updated.")
      })
      .catch(err => {})
  }

  const onCaloryChange = (_calories, _cb) => {
    // setCalories(_calories)
    generate(_calories, _cb)
  }

  const getFilterList = (_day, _list, _recList) => {
    let filterList = _list.filter(item => item.day === _day)
    let imgMealList = filterList.map(item => {
      for (let i = 0; i < _recList.length; i++) {
        let servings =
          item?.multiplier === 1
            ? Math.round(item?.servings || 1)
            : item?.servings || 1
        if (item?.meal === _recList[i]._id) {
          return {
            ..._recList[i],
            carbs: _recList[i].carbs * servings,
            fat: _recList[i].fat * servings,
            protein: _recList[i].protein * servings,
            calories: _recList[i].calories * servings,
            day: item.day,
            servings: servings,
            mealCals: item.mealCals || null,
          }
        } else if (item?._id === _recList[i]._id) {
          return {
            ...item,
            day: item.day,
            // ..._recList[i],
            // servings: item.servings,
          }
        }
      }
    })

    imgMealList = imgMealList.filter(item => item !== undefined)
    return imgMealList
  }

  return (
    <>
      {/* xl:py-24 */}
      <div className="py-8 px-4 sm:px-0 sm:py-20 xl:pt-[71px] xl:pb-0">
        <Container>
          <WeeklyPlan
            onListChange={onListChange}
            setCurrWeek={setCurrWeek}
            currWeek={currWeek}
            startDate={startDate}
            setWeekDay={setWeekDay}
            isDisable={disableWeekDays}
          />
          <Tracker
            userData={userData}
            // selectedDay={currWeek.currDay}
            selectedDay={weekDay}
            setUserData={setUserData}
          />
          <TodoList
            userData={userData}
            // selectedDay={currWeek.currDay}
            selectedDay={weekDay}
            setUserData={setUserData}
            isDisable={disableWeekDays}
            setIsDisable={setDisableWeekDays}
          />
          <div className="mt-8 md:mt-28 flex flex-col-reverse gap-2 items-start sm:gap-0 sm:flex-row sm:items-center justify-between">
            <SubTitle title2="Меню и тренировки на день" />
            <Link
              to="/shopping-list"
              className="flex items-center gap-2.5 self-end sm:self-auto"
            >
              <span className="text-sm font-semibold text-txt-dark">
                Список покупок
              </span>
              <MdOutlineAddShoppingCart className="text-primary w-5 h-5 " />
            </Link>
          </div>

          <div className="mt-8 md:mt-36">
            <MealPlan list={newList} dark toggle={toggleNewMealModal} />
          </div>
          <div className="mt-8 md:mt-16 flex flex-col-reverse gap-4 items-center xxs:gap-0 xxs:flex-row xxs:justify-between text-base text-primary">
            <button
              className="py-2.5 px-4 bg-gray-100 rounded-md cursor-pointer text-sm text-gray-500 font-medium capitalize"
              onClick={() => toggleGenModal(true)}
              // onClick={generate}
            >
              Создать новое меню
            </button>
            <div>
              <span className="font-semibold">
                Общее количество калорий за день:
              </span>
              <span className="ml-2">{Math.round(total)} кал</span>
            </div>
          </div>
        </Container>
      </div>
      {showNewMealModal && (
        <NewMealChangeModal
          recipeList={recipeList}
          data={mealData}
          toggle={toggleNewMealModal}
          onMealChange={onMealChange}
          userData={userData}
        />
      )}
      {showMealModal && (
        <MealChangeModal
          data={mealData}
          toggle={toggleMealModal}
          onMealChange={onMealChange}
        />
      )}
      {showGenModal && (
        <MealGenerateModal
          toggle={toggleGenModal}
          onCaloryChange={onCaloryChange}
        />
      )}
    </>
  )
}

export default Dashboard
