import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"

// component
import Item from "./Item"
import Spinner from "../Spinner"

// mockup
import mockup from "../../utils/data"

// utils
import {
  getInitialList,
  getNextDates,
  getPrevDates,
  getCurrentDate,
  getCurrWeekDays,
  getDiff,
} from "../../utils/dateHelper"

import { translateMonth } from "../../utils/helper"

// assets
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"

import FadeIn from "../Animation/FadeIn"

// const startDate = "2021-10-15"

const WeeklyPlan = ({
  onListChange,
  setCurrWeek,
  currWeek,
  startDate,
  setWeekDay,
  isDisable,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const [currDate, setCurrDate] = useState(null)
  const [dates, setDates] = useState([])

  useEffect(() => {
    // let current = getCurrentDate(new Date())
    let current = getCurrentDate(new Date())
    setCurrDate(current)

    // let currDay = getDiff(startDate, current.prettier.startDate)
    // onListChange(currWeek.currDay)

    setDates(getCurrWeekDays(currWeek.currDay, startDate))

    setActiveIndex(currWeek.currDay)
  }, [])

  const handleActive = (_id, _date) => {
    setActiveIndex(_date)
    onListChange(_id)
    setWeekDay(_date)
    // console.log({ _id, _date })
  }

  const onNextClick = () => {
    // 32, 6
    if (currWeek.week < 6) {
      let lastIndex = dates[dates.length - 1]
      let nextDates = getNextDates(lastIndex.now, lastIndex.day)
      setDates(nextDates.slice(1, nextDates.length))
      setCurrDate(nextDates[1])
      setCurrWeek({
        ...currWeek,
        week: currWeek.week + 1,
      })
    } else {
      // toast.warn("You can see up to two weeks in front")
      toast.warn("You're viewing the last week.")
    }
  }

  const onPrevClick = () => {
    if (currWeek.week > 1) {
      // if (currWeek.week !== 2) {
      //   let firstIndex = dates[0]
      //   let prevDates = getPrevDates(
      //     firstIndex.now,
      //     currWeek.week,
      //     firstIndex.day_id
      //   )
      //   console.log("on prev", prevDates)
      //   setDates(prevDates.slice(0, prevDates.length - 1))
      //   setCurrDate(prevDates[0])
      // } else {
      //   let initial = getInitialList(startDate)
      //   setDates(initial)
      //   setCurrDate(initial[0])
      // }

      let firstIndex = dates[0]
      let prevDates = getPrevDates(firstIndex.now, currWeek.week)
      setDates(prevDates.slice(0, prevDates.length - 1))
      setCurrDate(prevDates[0])

      setCurrWeek({
        ...currWeek,
        week: currWeek.week - 1,
      })
    } else {
      toast.warn("You are viewing the first course week.")
    }
  }

  return (
    <FadeIn>
      <div
        className={`relative max-w-180 flex flex-col gap-3 sm:p-2 sm:rounded-2xl sm:flex-row sm:border sm:border-gray-EEEEEE md-800:p-5 md:max-w-856`}
      >
        {isDisable && (
          <div className="flex items-center justify-center absolute inset-[0] bg-[rgba(177,177,177,.25)] z-10 sm:rounded-2xl">
            <Spinner />
          </div>
        )}

        <div className="flex items-center justify-start sm:justify-center sm:rounded-lg sm:px-8 sm:py-6">
          <span className="text-sm text-txt-light font-semibold sm:text-base">
            {translateMonth(currDate?.prettier?.month)}
          </span>
        </div>
        <div className="flex gap-2 xs-350:gap-4 md-800:gap-3">
          <div className="flex items-center justify-center">
            <span
              className="text-sm text-primary opacity-70 flex items-center justify-center cursor-pointer lg:text-xl"
              onClick={onPrevClick}
            >
              <IoIosArrowBack />
            </span>
          </div>
          {dates.map((item, i) => (
            <Item
              key={item.id}
              data={item}
              activeIndex={activeIndex}
              handleActive={handleActive}
            />
          ))}
          <div className="flex items-center justify-center">
            <span
              className="text-sm text-primary opacity-70 flex items-center justify-center cursor-pointer lg:text-xl"
              onClick={onNextClick}
            >
              <IoIosArrowForward />
            </span>
          </div>
        </div>
      </div>
    </FadeIn>
  )
}

// max-w-180 flex flex-col gap-3 md-800:p-5 md-800:rounded-2xl md-800:flex-row md-800:border md-800:border-gray-EEEEEE

// flex items-center justify-start md-800:justify-center md-800:rounded-lg md-800:px-8 md-800:py-6

export default WeeklyPlan
