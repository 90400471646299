import * as React from "react"

function ClockDarkIcon(props) {
  return (
    <svg
      width={9}
      height={9}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 8.438a3.938 3.938 0 100-7.876 3.938 3.938 0 000 7.875z"
        stroke="#81ACC9"
        strokeWidth={0.438}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 2.25V4.5l1.125 1.125"
        stroke="#81ACC9"
        strokeWidth={0.438}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ClockDarkIcon
