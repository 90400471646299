import * as React from "react"

function DotsIcon(props) {
  return (
    <svg
      width={42}
      height={39}
      viewBox="0 0 42 39"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={7.5} cy={7.5} r={2.25} fill="#81ACC9" />
      <circle cx={7.5} cy={24} r={2.25} fill="#81ACC9" />
      <circle cx={7.5} cy={15.75} r={2.25} fill="#81ACC9" />
      <circle cx={7.5} cy={32.25} r={2.25} fill="#81ACC9" />
      <circle cx={25.5} cy={7.5} r={2.25} fill="#81ACC9" />
      <circle cx={25.5} cy={24} r={2.25} fill="#81ACC9" />
      <circle cx={25.5} cy={15.75} r={2.25} fill="#81ACC9" />
      <circle cx={25.5} cy={32.25} r={2.25} fill="#81ACC9" />
      <circle cx={16.5} cy={7.5} r={2.25} fill="#81ACC9" />
      <circle cx={16.5} cy={24} r={2.25} fill="#81ACC9" />
      <circle cx={16.5} cy={15.75} r={2.25} fill="#81ACC9" />
      <circle cx={16.5} cy={32.25} r={2.25} fill="#81ACC9" />
      <circle cx={34.501} cy={7.5} r={2.25} fill="#81ACC9" />
      <circle cx={34.501} cy={24} r={2.25} fill="#81ACC9" />
      <circle cx={34.501} cy={15.75} r={2.25} fill="#81ACC9" />
      <circle cx={34.501} cy={32.25} r={2.25} fill="#81ACC9" />
    </svg>
  )
}

export default DotsIcon
