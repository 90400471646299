// extracted by mini-css-extract-plugin
export var btnNext = "userInput-module--btnNext--ckWZl";
export var disabled = "userInput-module--disabled--dNPGq";
export var userInputPageCard = "userInput-module--userInputPageCard--UYsUE";
export var userInputPage = "userInput-module--userInputPage--R7C7x";
export var ellipse = "userInput-module--ellipse--YLF32";
export var allergies = "userInput-module--allergies--zkWU1";
export var select = "userInput-module--select--tVOmQ";
export var allergiesSelect = "userInput-module--allergiesSelect--tgAM4";
export var control = "userInput-module--control--sXlaW";
export var multiValueContainer = "userInput-module--multiValueContainer--jzCFc";
export var success = "userInput-module--success--v2Rsv";
export var circle = "userInput-module--circle--9MKW-";