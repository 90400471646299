import React from "react"
import Select, { components } from "react-select"

import * as styles from "../../styles/userInput.module.css"

// utils
import { convertUnit } from "../../utils/helper"

const units = [
  { value: "cm/kg", label: "cm/kg" },
  // { value: "m/kg", label: "m/kg" },
  // { value: "m/pounds", label: "m/pounds" },
]

const IndicatorsContainer = props => {
  return (
    <div className="bg-gray-lightest md:bg-white">
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const controlStyles = {
  color: "white",
}

const ControlComponent = props => (
  <div style={controlStyles}>
    <components.Control className={"w-full " + styles.control} {...props} />
  </div>
)

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer
    className="bg-gray-lightest md:bg-white h-6 md:h-8"
    {...props}
  >
    {children}
  </components.ValueContainer>
)
const MultiValueContainer = props => {
  return (
    <components.MultiValueContainer
      className={styles.multiValueContainer}
      {...props}
    />
  )
}

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
)

const ProfileInfo = ({ formData, setFormData, totalCalories }) => {
  return (
    <div>
      {/* Form */}
      <div className="flex flex-col md:my-2 md:mx-6">
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70 font-medium">
          Units:
          <Select
            closeMenuOnSelect={true}
            components={{
              IndicatorsContainer,
              ValueContainer,
              SingleValue,
              ControlComponent,
            }}
            options={units}
            onChange={data => {
              setFormData({
                ...formData,
                units: data,
              })
            }}
            value={formData.units}
            styles={{
              singleValue: base => ({ ...base, color: "white" }),
              valueContainer: base => ({
                ...base,
                color: "#1F1F1F",
                width: "100%",
                padding: "4px",
                fontSize: "10px",
                borderRadius: "5px 0 0 5px",
              }),
              singleValue: base => ({
                ...base,
                padding: 5,
                borderRadius: 5,
                opacity: "60%",
                color: "#1F1F1F",
                display: "flex",
              }),
              indicatorsContainer: base => ({
                ...base,
                borderRadius: "0 5px 5px 0",
                height: "25px",
                display: "flex",
              }),
              indicatorSeparator: base => ({
                ...base,
                display: "none",
              }),
              control: base => ({
                ...base,
                minHeight: "25px",
                border: "none",
                marginTop: "0.2rem",
              }),
            }}
            className={
              "opacity-100 text-gray-darkest md:my-2 text-opacity-60 border-none md:shadow-xs " +
              styles.select
            }
          />
        </div>
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70 font-medium">
          Рост:
          <input
            type="text"
            placeholder={`175 ${formData.units.value === "cm/kg" ? "cm" : "m"}`}
            className="w-full h-6 md:h-8 bg-gray-lightest md:bg-white  opacity-100 text-gray rounded my-1 md:my-2 p-3 focus:outline-blue "
            id="height"
            name="height"
            onChange={e => {
              setFormData({
                ...formData,
                height: e.target.value,
              })
            }}
            value={formData.height}
          />
        </div>
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70 font-medium">
          Вес:
          <input
            type="text"
            placeholder={`85 ${
              formData.units.value === "m/pounds" ? "pounds" : "kg"
            }`}
            className="w-full h-6 md:h-8 bg-gray-lightest md:bg-white opacity-100 text-grayrounded my-1 md:my-2 p-3 focus:outline-blue "
            id="weight"
            name="weight"
            onChange={e => {
              setFormData({
                ...formData,
                weight: e.target.value,
              })
            }}
            value={formData.weight}
          />
        </div>
        {/* <div className="mt-4">
          <p className="text-primary text-base">
            TotalCalories: {totalCalories}
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default ProfileInfo
