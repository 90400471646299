import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import { navigate } from "gatsby"
import { toast } from "react-toastify"

import { Arrow, Check, HeaderMan } from "../images/icons/icons"
import BigTickIcon from "../images/icons/BigTick"

import * as styles from "../styles/userInput.module.css"
import "react-circular-progressbar/dist/styles.css"

// utils
import { calculateCalories, convertUnit, goalValue } from "../utils/helper"

// forms
import BasicInfo from "./UserInput/BasicInfo"
import ProfileInfo from "./UserInput/ProfileInfo"
import FinalInfo from "./UserInput/FinalInfo"

// Context
import UserContext from "../context/UserAuth"

export default function UserInput({ allergies, types, getMealPlan }) {
  const { user, isRegenerate, checkRegenerate } = useContext(UserContext) || {
    user: null,
    isRegenerate: false,
    checkRegenerate: null,
  }

  const [isDisabled, setIsDisabled] = useState(true)

  const [formData, setFormData] = useState({
    age: "",
    gender: "male",
    goal: "",
    units: { value: "cm/kg", label: "cm/kg" },
    height: "",
    weight: "",
    type: "",
    allergies: [],
    isJob: false,
    shakes: false,
  })
  const [totalCalories, setTotalCalories] = useState(0)
  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1)

  useEffect(() => {
    let total = calculateCalories(formData)
    setTotalCalories(Number.isNaN(total) ? 0 : total)

    if (page === 1) {
      if (formData.age === "" || formData.goal === "") {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    } else if (page === 2) {
      if (
        formData.height === "" ||
        formData.weight === "" ||
        formData.units === ""
      ) {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    } else if (page === 3) {
      if (formData.type === "") {
        setIsDisabled(true)
      } else {
        setIsDisabled(false)
      }
    }
  }, [formData, page])

  function goToNext() {
    if (page === 1) {
      if (formData.age === "" || formData.goal === "") {
        alert("Please fill in all the fields")
      } else if (isNaN(formData.age)) {
        alert("Please enter a number")
      } else if (formData.age.includes(".")) {
        alert("Please enter a whole number")
      } else {
        let age = parseInt(formData.age)
        if (age >= 8 && age <= 90) {
          setIsDisabled(true)
          setPage(2)
        } else {
          alert("Age must be between 8 - 90")
        }
      }
    } else if (page === 2) {
      if (
        formData.height === "" ||
        formData.weight === "" ||
        formData.units === ""
      ) {
        alert("Please fill in all the fields")
      } else if (isNaN(formData.weight) || isNaN(formData.height)) {
        alert("Please enter a number")
      } else if (
        formData.weight.includes(".") ||
        formData.height.includes(".")
      ) {
        alert("Please enter a whole number")
      } else {
        let weight = parseInt(formData.weight)
        let height = parseInt(formData.height)

        if (weight >= 30 && weight <= 250 && height >= 100 && height <= 225) {
          setIsDisabled(true)
          setPage(3)
        } else {
          alert("Weigth must be 30 - 500kg\n Height must be 100 - 250cm")
        }
      }
    }
  }

  const handleSubmit = () => {
    if (formData.type === "") {
      alert("Please fill in all the fields")
      return
    }

    if (
      formData.age === "" ||
      formData.goal === "" ||
      formData.height === "" ||
      formData.weight === "" ||
      formData.units === ""
    ) {
      alert("Please fill in all the fields")
      return
    }

    console.log(formData)

    if (totalCalories > 4000) {
      toast.info("Sorry, total calories must be < 4000")
      return
    }

    let unit = convertUnit(
      formData.units.value,
      formData.height,
      formData.weight
    )

    let data = {
      _id: user.sub,
      dietType: formData.type.labelEN,
      calories: totalCalories,
      allergies: formData.allergies.map(item => item?.labelEN),
      height: unit.height,
      weight: unit.weight,
      age: formData.age,
      email: user.email,
      shakes: Number(formData.shakes),
      goal: goalValue(formData.goal.valueEN),
    }

    let check = getMealPlan || null

    setLoading(true)
    axios({
      method: "post",
      url: "https://esteekeyapi.com/userinput",
      // url: "http://localhost:4000/userinput",
      data: data,
    })
      .then(res => {
        setLoading(false)
        setPage(4)

        if (check) {
          setTimeout(() => {
            getMealPlan(user.sub)
          }, 10000)
        }
      })
      .catch(err => {
        setLoading(false)
      })
  }

  return (
    <div className="mb-8 mt-6">
      {/* overflow-hidden */}
      <div className="flex gap-6 mt-10 md:overflow-visible  justify-center md:justify-center ">
        <div
          className={
            "flex-100 md:flex-95 md:rounded-2xl px-4 my-8 ml-0 md:shadow-userInput md:max-w-92 xl:max-w-7/10 "
          }
        >
          {/* Header */}
          <div className="hidden m-4 mb-0 py-4 md:flex justify-start items-end border-b-2 border-black border-opacity-10">
            <HeaderMan />
            <div
              className="font-bold text-2xl leading-7 ml-5 text-black"
              // onClick={() => {
              //   checkRegenerate(!isRegenerate)
              //   console.log("on click")
              // }}
            >
              Ваша <span className="text-blue">информация</span>
            </div>
          </div>

          <div className="md:flex">
            {/* Form page No. */}
            {page === 4 ? null : (
              <div className="flex flex-row md:flex-col justify-evenly items-start  md:justify-start w-full md:w-1/4 h-8 md:h-full m-8 mb-12 md:m-0 ml-0 md:-ml-8">
                <div
                  className={`w-1/3  text-center md:text-start  flex flex-col text-xs font-medium items-center justify-center cursor-pointer md:flex-row md:mt-3 md:mb-0 md:rounded-3xl md:justify-start md:m-12 md:w-full md:px-8 md:pt-2 md:pb-1 ${
                    page === 1 ? "md:shadow-xs" : "md:opacity-40"
                  } `}
                  onClick={() => {
                    setPage(1)
                    // goToNext()
                  }}
                >
                  <div
                    className={`w-6 h-6 rounded-full mb-1 leading-8 md:mr-4 ${
                      page === 1 ? "bg-primary text-white " : "bg-gray-light"
                    } flex items-center justify-center`}
                  >
                    1
                  </div>
                  Общая информация
                </div>
                <div
                  className={`w-2/3  text-center md:text-start flex flex-col text-xs font-medium items-center justify-center cursor-pointer md:flex-row md:mt-3 md:mb-0 md:rounded-3xl md:justify-start md:m-12 md:w-full md:px-8 md:pt-2 md:pb-1 ${
                    page === 2 ? "md:shadow-xs" : "md:opacity-40"
                  } `}
                  onClick={() => {
                    setPage(2)
                    // goToNext()
                  }}
                >
                  <div
                    className={`w-6 h-6 mb-1 rounded-full leading-8 md:mr-4 ${
                      page === 2 ? "bg-primary text-white " : "bg-gray-light"
                    } flex items-center justify-center`}
                  >
                    2
                  </div>
                  Профиль
                </div>
                <div
                  className={`w-1/3  text-center md:text-start flex flex-col text-xs font-medium items-center justify-center cursor-pointer md:flex-row md:mt-3 md:mb-0 md:rounded-3xl md:justify-start md:m-12 md:w-full md:px-8 md:pt-2 md:pb-1 ${
                    page === 3 ? "md:shadow-xs" : "md:opacity-40"
                  } `}
                  onClick={() => {
                    setPage(3)
                    // goToNext()
                  }}
                >
                  <div
                    className={`w-6 h-6 mb-1 rounded-full leading-8 md:mr-4 ${
                      page === 3 ? "bg-primary text-white " : "bg-gray-light"
                    } flex items-center justify-center`}
                  >
                    3
                  </div>
                  Заключительная информация
                </div>
              </div>
            )}

            {/* Form */}
            <div
              className={` p-4 ml-0 mb-8 md:mx-auto md:mr-8 md:my-4  ${
                page !== 4
                  ? "flex-10 md:flex-60 shadow-xm md:shadow-none md:bg-gray-lightgray md:bg-opacity-10 md:rounded-2xl"
                  : "flex-grow"
              }`}
            >
              {page === 1 && (
                <BasicInfo
                  formData={formData}
                  setFormData={setFormData}
                  totalCalories={totalCalories}
                />
              )}
              {page === 2 && (
                <ProfileInfo
                  formData={formData}
                  setFormData={setFormData}
                  totalCalories={totalCalories}
                />
              )}
              {page === 3 && (
                <FinalInfo
                  formData={formData}
                  setFormData={setFormData}
                  totalCalories={totalCalories}
                  allergies={allergies}
                  mealTypes={types}
                />
              )}
              {page === 4 && <UserInputPage4 />}
              <div className="flex justify-end md:hidden">
                {page === 3 ? (
                  // Submit button
                  <button
                    onClick={handleSubmit}
                    className={`w-24 ${styles.btnNext} ${
                      isDisabled
                        ? "cursor-not-allowed " + styles.disabled
                        : "cursor-pointer"
                    }`}
                  >
                    Complete <Arrow className="h-3 w-3" />{" "}
                  </button>
                ) : page === 4 ? (
                  <div></div>
                ) : (
                  // next Button
                  <button
                    onClick={() => goToNext()}
                    className={`w-20 ${styles.btnNext} ${
                      isDisabled
                        ? "cursor-not-allowed " + styles.disabled
                        : "cursor-pointer"
                    }`}
                  >
                    Next
                    <Arrow className="h-3 w-3 relative top-1 text-white items-center" />{" "}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="md:flex justify-end hidden md:-mt-6 md:mr-8 md:mb-6">
            {page === 3 ? (
              // Submit button
              <button
                onClick={() => {
                  if (!loading) handleSubmit()
                }}
                className={`w-24 ${styles.btnNext} ${
                  isDisabled
                    ? "cursor-not-allowed " + styles.disabled
                    : "cursor-pointer"
                }`}
              >
                {!loading ? (
                  <>
                    Complete <Arrow className="h-3 w-3" />{" "}
                  </>
                ) : (
                  "Loading"
                )}
              </button>
            ) : page === 4 ? (
              <div></div>
            ) : (
              // next Button
              <button
                onClick={() => goToNext()}
                className={`w-20 ${styles.btnNext} ${
                  isDisabled
                    ? "cursor-not-allowed " + styles.disabled
                    : "cursor-pointer"
                }`}
              >
                Next
                <Arrow className="h-3 w-3 relative top-1 text-white items-center" />{" "}
              </button>
            )}
          </div>
          {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
        </div>
      </div>
    </div>
  )

  function UserInputPage4() {
    const [value, setValue] = useState(0)
    const [showSuccess, setShowSuccess] = useState(false)
    let interval = null

    useEffect(() => {
      interval = setInterval(() => {
        setValue(value => value + 1)
      }, 100)

      return () => clearInterval(interval)
    }, [])

    useEffect(() => {
      if (value >= 100) {
        setShowSuccess(true)
        navigate("/dashboard")

        clearInterval(interval)
      }
    }, [value])

    return (
      <div className={styles.success}>
        {showSuccess ? (
          <>
            <div className={styles.circle}>
              <BigTickIcon />
            </div>
            <h3 className="text-primary text-hd-sm mt-5 md:text-hd-lg lg:text-hd-xl font-bold">
              Success!
            </h3>
            <p className="text-sm text-txt-dark opacity-70 text-center mt-7 font-medium  lg:text-base">
              Your meal plan has been
            </p>
            <p className="text-sm text-txt-dark opacity-70 text-center font-medium lg:text-base">
              generated successfully.
            </p>
            <p className="text-xs text-txt-dark opacity-70 text-center font-medium lg:text-sm mt-2">
              redirecting you back to dashboard
            </p>
          </>
        ) : (
          <div>
            <CircularProgressbarWithChildren
              // counterClockwise={true}
              strokeWidth={4}
              value={value}
              maxValue={100}
              styles={buildStyles({
                pathColor: `${"#81ACC9"}`,
                trailColor: `${"rgba(129, 172, 201, 0.5)"}`,
              })}
            >
              <div className="flex flex-col gap-4 items-center justify-center">
                <span className="text-primary text-hd-sm md:text-hd-lg lg:text-hd-xl font-bold">
                  {value}%
                </span>
                <span className="text-gray-400 font-bold">
                  Generating meal plan....
                </span>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        )}
      </div>
    )
  }
}
