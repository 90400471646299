import React, { useState } from "react"

// assets
import CloseIcon from "../../../images/icons/Close"
import Dropdown from "./Dropdown"

// mockup
import mockup from "../../../utils/data"

const MealChangeModal = ({ data, toggle, onMealChange }) => {
  const [value, setValue] = useState({ id: -1, title: "Select Meal" })

  const handleClose = () => {
    toggle(false)
  }

  const handleClick = () => {
    if (value.id !== -1) {
      onMealChange(data.id, value)
      toggle(false)
    }
  }

  return (
    <div className="w-full h-full fixed z-10 flex items-center justify-center top-0 bg-black bg-opacity-10">
      <div className="w-11/12 max-w-90 bg-white relative flex flex-col items-center justify-center rounded-2xl shadow-video-card xxs:w-full">
        <CloseIcon
          className="self-end mt-2 mr-2 cursor-pointer"
          onClick={handleClose}
        />
        <div
          className="w-28 h-28 rounded-full overflow-hidden absolute -top-16"
          style={{
            boxShadow: "2px 2px 23px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img src={data.image} alt="meal item" className="h-full" />
        </div>
        <p className="mt-12 text-xl text-txt-dark font-semibold md:mt-14">
          {data.title}
        </p>
        <p className="mt-2.5 text-sm max-w-13 text-center text-txt-med opacity-50">
          {data.description}
        </p>
        <div className="mt-4 w-full px-4 md:mt-6">
          <p className="text-sm text-txt-md">Replacement meal</p>
          <Dropdown list={mockup.meal_plan} value={value} setValue={setValue} />
        </div>
        <button
          className={`transition-colors text-white bg-primary rounded-xl mt-5 mb-7 py-2.5 px-4 shadow-btn-xl md:mt-7 md:mb-9  ${
            value.id === -1
              ? "opacity-50"
              : "opacity-100 hover:bg-white hover:text-primary"
          }`}
          onClick={handleClick}
        >
          Replace Meal
        </button>
      </div>
    </div>
  )
}

export default MealChangeModal
