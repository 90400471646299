import React, { useEffect } from "react"
import { usePDF } from "@react-pdf/renderer"

// component
import PdfDocument from "../Common/PdfDocument/PdfDocument"

const Button = ({ list, bgWhite, isDialy }) => {
  const [instance, updateInstance] = usePDF({
    document: <PdfDocument key={isDialy ? "daily" : "weekly"} list={list} />,
  })

  useEffect(() => {
    if (list) {
      updateInstance(list)
    }
  }, [list])

  return (
    <a
      href={instance.url}
      download={
        !isDialy ? "Weekly Shopping List.pdf" : "Daily Shopping List.pdf"
      }
      className={`transition-opacity duration-100 shadow-xl py-3 px-7 md:px-10 text-sm font-bold rounded-lg -mr-1 hover:opacity-90 ${
        bgWhite
          ? "bg-gradient-to-b from-gradient1 to-gradient2 text-white"
          : "bg-white text-primary"
      }`}
    >
      Download
    </a>
  )
}

export default Button
