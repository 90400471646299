import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import { useAudio } from "../Common/Hooks/useAudio"

// assets
import ClockIcon from "../../images/icons/Clock"
import PlayIcon from "../../images/icons/Play"
import OrnamentLight from "../../images/newornament1.png"
import { FaPause } from "react-icons/fa"

// styles
import * as styles from "./secondary.module.css"
import "react-circular-progressbar/dist/styles.css"

// utils
import { getCurrentTime, getTotalDuration } from "../../utils/helper"

import { StaticImage } from "gatsby-plugin-image"

export function OrnamentLight2() {
  return (
    <StaticImage
      src="../../images/newornament1.png"
      alt="Esteekey Ornament Light"
      // width={98}
      // height={128}
    />
  )
}

let interval = null

const CarouselItem = forwardRef(({ data, onChange, dark }, ref) => {
  const [currentId, setCurrentId] = React.useState(null)
  const driveAudioUrl = data.audioUrl()
  const [audio, playing, duration, toggle, setAudio] = useAudio(driveAudioUrl)

  const [currentTime, setCurrentTime] = useState({
    time: 0,
    label: "00:00",
  })
  const [totalDuration, setTotalDuration] = useState("")

  useEffect(() => {
    interval = setInterval(() => {
      if (playing) {
        setCurrentTime({
          time: audio.currentTime,
          label: getCurrentTime(audio.currentTime),
        })
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [playing])

  useEffect(() => {
    if (duration) {
      setTotalDuration(getTotalDuration(duration))
    }
  }, [duration])

  const handleClick = () => {
    onChange(data.id)
    if (currentId !== data.id) {
      const _audio = new Audio(driveAudioUrl)
      _audio.id = data.id
      _audio.src = driveAudioUrl
      _audio.muted = false
      _audio.load()
      setAudio(_audio)
      setCurrentId(data.id)
      setTimeout(() => {
        toggle(!playing)
      }, 500)
    } else {
      toggle(!playing)
    }
  }

  useImperativeHandle(ref, () => ({
    listener(_flag) {
      toggle(_flag)
    },
  }))

  if (data.bgImage !== null) {
    return (
      <div
        className={"slick-item " + styles.card}
        style={{ background: "none" }}
      >
        <BackgroundImage
          {...convertToBgImage(getImage(data.bgImage.asset.localFile))}
          style={{ position: "absolute", width: "100%", height: "100%" }}
        />
        <div className="cursor-pointer relative z-10">
          <h4>{data.titleRU}</h4>
          {data.shortDescription && data?.shortDescription !== "-" && (
            <p className={styles.desc}>{data.shortDescription}</p>
          )}
          <div
            className={
              "invisible flex items-center gap-2 " + styles.time__wrapper
            }
          >
            <ClockIcon />
          </div>
        </div>
        <div
          className={"flex flex-col items-center gap-2 " + styles.play__wrapper}
        >
          {totalDuration && (
            <span>
              {currentTime.label} / {totalDuration}
            </span>
          )}
          <div className={styles.container} onClick={handleClick}>
            <CircularProgressbarWithChildren
              counterClockwise={true}
              // strokeWidth={14}
              strokeWidth={8}
              value={currentTime.time}
              maxValue={duration ? duration : 100}
              background
              styles={buildStyles({
                pathColor: `${!dark ? "rgba(255, 255, 255, 1)" : "#6797B8"}`,
                trailColor: `${!dark ? "rgba(255, 255, 255, .5)" : "#B8CFDF"}`,
                backgroundColor: "#ffffff",
              })}
            >
              {!playing ? (
                <PlayIcon className={styles.play__icon} />
              ) : (
                <FaPause className={styles.pause__icon} size={22} />
              )}
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={"slick-item " + styles.card}>
      {/* <Link to={data.to}> */}
      <div className="cursor-pointer">
        <h4>{data.titleRU}</h4>
        {data.shortDescription && data?.shortDescription !== "-" && (
          <p className={styles.desc}>{data.shortDescription}</p>
        )}
        <div
          className={
            "invisible flex items-center gap-2 " + styles.time__wrapper
          }
        >
          <ClockIcon />
          {/* <span>{data.time}</span> */}
        </div>
      </div>
      {/* </Link> */}
      <div
        className={"flex flex-col items-center gap-2 " + styles.play__wrapper}
      >
        <span>
          {currentTime.label} / {totalDuration}
        </span>
        <div className={styles.container} onClick={handleClick}>
          <CircularProgressbarWithChildren
            counterClockwise={true}
            // strokeWidth={14}
            strokeWidth={8}
            value={currentTime.time}
            maxValue={duration ? duration : 100}
            background
            styles={buildStyles({
              pathColor: `${!dark ? "rgba(255, 255, 255, 1)" : "#81ACC9"}`,
              trailColor: `${
                !dark ? "rgba(255, 255, 255, .5)" : "rgba(129, 172, 201, 0.5)"
              }`,
              backgroundColor: "#ffffff",
            })}
          >
            {!playing ? (
              <PlayIcon className={styles.play__icon} />
            ) : (
              <FaPause className={styles.pause__icon} size={22} />
            )}
          </CircularProgressbarWithChildren>
        </div>
      </div>

      {/* <div className={styles.test}>
        <OrnamentLight2 />
      </div> */}
    </div>
  )
})

export default CarouselItem
