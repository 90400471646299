import React from "react"

export default function ShoppingListItem({ data, bgWhite }) {
  const setUnit = () => {
    if (data.unit === "millilitres") return "ml"
    else if (data.unit === "grams") return "g"
    else if (data.unit === "kilograms") return "kg"
    else if (data.unit === "litres") return "l"
    else return data.unit
  }

  return (
    <div className="flex w-full h-5 items-center">
      <small
        className={`text-inherit font-md  flex-shrink-0 ${
          bgWhite ? "opacity-100" : "opacity-75"
        }`}
      >
        {data?.nameRU || data?.name}
      </small>
      <hr
        className={`mx-2 text-inherit font-md  w-full ${
          bgWhite ? "opacity-100" : "opacity-20"
        }`}
      />
      <small
        className={`text-inherit font-md  whitespace-nowrap ${
          bgWhite ? "opacity-100" : "opacity-75"
        }`}
      >
        {/* {data?.amount || ""} {setUnit()} */}
        {data?.amount || ""} {data?.unitRU}
      </small>
    </div>
  )
}
