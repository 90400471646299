import React from "react"
import { Link } from "gatsby"

import { FaTelegramPlane } from "react-icons/fa"

const SubTitle = ({ title1, title2, url, buttonText, text, dark, icon }) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <h2
          className={`text-hd-sm lg:text-4xl xl:text-hd-xl font-normal flex items-center gap-2 ${
            dark ? "text-white" : "text-blue-700"
          }`}
        >
          <span>{title1}</span>{" "}
          <span className={`${dark ? "text-white" : "text-blue-700 "}`}>
            {title2}
          </span>
          {icon && (
            <a href="https://t.me/+ZXZ-0Y8thUs3MGEy">
              <span className="cursor-pointer text-blue-700">
                <FaTelegramPlane />
              </span>
            </a>
          )}
        </h2>
        {url && (
          <Link to={url}>
            <span
              className={`transition-colors text-xs text-blue-200 cursor-pointer md:py-2 md:px-6 md:rounded-lg md:font-semibold md:shadow-btn-md xl:py-2.7 xl:px-7.5 xl:rounded-10 xl:shadow-btn-xl xl:text-btn-sm  ${
                dark
                  ? "md:bg-white md:text-primary hover:bg-primary-dark hover:text-white"
                  : "md:bg-primary-dark md:text-white hover:bg-white hover:text-primary"
              }`}
            >
              {buttonText}
            </span>
          </Link>
        )}
      </div>
      {text && (
        <p
          className={`text-xs mt-1  sm:pl-0 lg:mt-2.5 xl:text-base ${
            dark ? "text-white" : "text-txt-light"
          }`}
        >
          {text}
        </p>
      )}
    </>
  )
}

export default SubTitle
