import React from "react"

import * as styles from "./container.module.css"

const Container = ({ children, customPad = false, padding }) => {
  return (
    <div
      className={`${styles.container} ${
        !customPad ? "xs-r:px-2 sm:px-4 lg:px-8 2xl:px-0" : padding
      }`}
    >
      {children}
    </div>
  )
}

export default Container
