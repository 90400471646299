import React, { useState, useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import { toast } from "react-toastify"

// assets
import { BsPlusLg } from "react-icons/bs"
import { AiOutlineMinus } from "react-icons/ai"

// styles
import "react-circular-progressbar/dist/styles.css"

// network
import { updateWaterTake } from "../../utils/requests"

// utils
import { getPercentage, getPercentageNew, getMaxWaterIntake } from "./utils"

export function WaterCup() {
  return (
    <StaticImage
      src="../../images/water.png"
      alt="water cup"
      style={{ width: "24px", height: "30px" }}
    />
  )
}

const MAX_WATER_CONSUMED = 2250

let isSameDay = false

/**
 * selectedDay
 * if current Day has been passed waterTrack will remain same on weekDay change
 * If week Day has been passed waterTrack will show/update consumed value for passed week day.
 */

const WaterTrack = ({ userData, selectedDay, setUserData }) => {
  const [waterConsumed, setWaterConsumed] = useState(0)
  const [progress, setProgress] = useState(0)

  const [maxWaterInTake, setMaxWaterInTake] = useState(0)

  useEffect(() => {
    if (userData && maxWaterInTake === 0) {
      if (userData.weight && userData.age) {
        setMaxWaterInTake(
          getMaxWaterIntake(userData.weight, parseInt(userData.age))
        )
      } else {
        setMaxWaterInTake(2200)
      }
    }
  }, [userData])

  useEffect(() => {
    if (
      Number.isInteger(selectedDay) &&
      userData?.waterTracking !== undefined
    ) {
      const currentWaterConsumed = userData.waterTracking.find(
        item => item.day === selectedDay
      )

      if (currentWaterConsumed) {
        setWaterConsumed(currentWaterConsumed.waterConsumed)
        isSameDay = true
      } else {
        setWaterConsumed(0)
        isSameDay = false
      }
    }
  }, [userData, selectedDay])

  useEffect(() => {
    // selectedDay should be current day
    if (
      userData &&
      Number.isInteger(selectedDay) &&
      userData?.totalTasksCount
    ) {
      // setProgress(getPercentage(userData, selectedDay))
      setProgress(getPercentageNew(userData))
    }
  }, [userData, selectedDay])

  const handleIncrement = () => {
    if (waterConsumed < maxWaterInTake) {
      const consumed = waterConsumed + 250

      updateWaterTake(userData._id, selectedDay, consumed)
        .then(res => {
          updateStateData(selectedDay, consumed)
          // toast.success(`Added +250 mL of daily water intake!`)
        })
        .catch(err => {
          console.log(err)
          toast.error(err.messagae)
        })
    }
  }

  const handleDecrement = () => {
    if (waterConsumed === 0) return

    const consumed = waterConsumed - 250
    updateWaterTake(userData._id, selectedDay, consumed)
      .then(res => {
        updateStateData(selectedDay, consumed)
        // toast.success(`Substracted -250 mL from daily water intake!`)
      })
      .catch(err => {
        console.log(err)
        toast.error(err.messagae)
      })
  }

  const updateStateData = (_day, _consumed) => {
    let newWaterTracking = []

    if (isSameDay) {
      newWaterTracking = userData.waterTracking.map(item =>
        item.day === selectedDay
          ? { day: _day, waterConsumed: _consumed }
          : item
      )
    } else {
      if (userData?.waterTracking !== undefined)
        newWaterTracking = [
          ...userData.waterTracking,
          { day: _day, waterConsumed: _consumed },
        ]
      else newWaterTracking = [{ day: _day, waterConsumed: _consumed }]
    }

    setWaterConsumed(_consumed)
    setUserData({
      ...userData,
      waterTracking: [...newWaterTracking],
    })
  }

  return (
    <div className="relative flex items-center justify-center gap-12 mt-8 lg:mt-0 lg:block">
      <div className="static w-[fit-content] lg:w-auto lg:absolute top-[-17rem] right-[12rem]">
        <h3 className="font-normal text-xl text-blue-700 text-center">
          Progress
        </h3>
        <div className="w-[150px] h-[150px] sm:w-[200px] sm:h-[200px] mt-3  rotate-180">
          <CircularProgressbarWithChildren
            strokeWidth={8}
            value={progress}
            maxValue={100}
            styles={buildStyles({
              pathColor: `${"#81ACC9"}`,
              trailColor: `${"#F7F7F7"}`,
            })}
          >
            <div className="flex flex-col items-center justify-center rotate-180">
              <span className="text-[1.75rem] sm:text-[2.25rem] text-txt-dark font-medium leading-none">
                {parseFloat(progress).toFixed(1)}%
              </span>
              <span className="text-base sm:text-lg text-txt-med leading-none">
                complete
              </span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>

      <div className="static w-[fit-content]  lg:w-auto lg:absolute top-[-9rem] right-[3rem]">
        <h3 className="font-normal text-xl text-blue-700 text-center">Water</h3>
        <div className="w-[100px] h-[100px] sm:w-[150px] sm:h-[150px] mt-3 rotate-180">
          <CircularProgressbarWithChildren
            strokeWidth={8}
            value={waterConsumed}
            maxValue={maxWaterInTake}
            styles={buildStyles({
              pathColor: `${"#BABFD6"}`,
              trailColor: `${"#F7F7F7"}`,
            })}
          >
            <div className="flex flex-col items-center justify-center rotate-180">
              <WaterCup />
              {/*<span className="text-xs sm:text-sm text-txt-dark font-medium leading-none">
                1 стакан
              </span>*/}
              <span className="text-[10px] sm:text-xs text-txt-med leading-none pt-2">
                {waterConsumed} mL
              </span>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="mt-2 flex items-center justify-center gap-4">
          <button
            className="w-[28px] h-[28px] sm:w-[38px] sm:h-[38px] rounded-lg flex items-center justify-center bg-gray-100 outline-none border-none cursor-pointer hover:opacity-80"
            onClick={handleDecrement}
          >
            <AiOutlineMinus className="w-[8px] h-[8px] sm:w-[16px] sm:h-[16px]" />
          </button>
          <button
            className="w-[28px] h-[28px] sm:w-[38px] sm:h-[38px] rounded-lg flex items-center justify-center bg-gray-100 outline-none border-none cursor-pointer hover:opacity-80"
            onClick={handleIncrement}
          >
            <BsPlusLg className="w-[8px] h-[8px] sm:w-[16px] sm:h-[16px]" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default WaterTrack
