import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { motion, AnimatePresence } from "framer-motion"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Clock } from "../images/icons/icons"

import * as styles from "../styles/recipes.module.css"
import Container from "./Common/Container/Container"

import FadeIn from "./Animation/FadeIn"

export default function Recipes({ list, blog, link = "/recipeopened" }) {
  const [mealList, setMealList] = useState([])

  useEffect(() => {
    if (list) setMealList(list)
  }, [list])

  return (
    <Container>
      <div className="my-4 flex flex-col justify-center items-center mx-4 xs:mx-0 ">
        <div className="bg-white w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <AnimatePresence>
            {mealList.map((item, i) => (
              // <motion.div
              //   key={item._id}
              //   className="flex flex-col"
              //   initial={{ opacity: 0 }}
              //   animate={{ opacity: 1 }}
              //   exit={{ opacity: 0 }}
              // >
              <FadeIn>
                <Link to={item?.slug?.current || item?.to}>
                  {/* Recipe Image */}
                  <div>
                    {/* <img
                      className={"w-full " + styles.img}
                      src={item?.image?.asset?.url || item?.image}
                      alt="recipes"
                    /> */}
                    <GatsbyImage
                      imgClassName={styles.img}
                      image={getImage(item.image.asset.localFile)}
                      alt={item.nameRU}
                    />
                  </div>
                  <div className="">
                    {/* Recipe Name */}
                    <h1
                      className={`${styles.meal_title} text-2xl text-black font-bold my-3`}
                    >
                      {item?.nameRU || item?.title}
                    </h1>
                    <div>
                      {/* Recipe Data */}
                      <p className="text-sm leading-4 text-gray-light2 text-opacity-50 text-left">
                        {item?.description || (
                          <>
                            <b>Калории:</b> {item?.calories} <br />
                            <b>Белки:</b> {item?.protein} <br />
                            <b>Углеводы:</b> {item?.carbs} <br />
                            <b>Жиры:</b> {item?.fat}
                          </>
                        )}
                      </p>
                      <div className="flex justify-between my-4 items-center">
                        <div className="flex items-center">
                          <Clock />
                          {/* Time and Date */}
                          <span className="text-xs text-gray-light2 text-opacity-50 leading-3 mx-2">
                            {item?.mealTime?.nameRU || item?.time}
                          </span>
                        </div>
                        <button
                          className={
                            "bg-white text-xs font-light text-gray-light2 text-opacity-60 bor leading-3 border-opacity-30 px-4 py-2 border border-black rounded hover:text-white hover:border-transparent bg-gradient-to-r hover:from-gradient1 hover:to-gradient2 transition-all  "
                          }
                        >
                          Читать
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
                {/* </motion.div> */}
              </FadeIn>
            ))}
          </AnimatePresence>
        </div>
      </div>
    </Container>
  )
}
