import React from "react"
import { motion } from "framer-motion"

// components
import VideoItem from "./VideoItem"

const LatestVideos = props => {
  const { list, ...rest } = props

  return (
    <motion.div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
      {list.map((item, i) => (
        <>
          {!item?.node?.hidden && (
            <VideoItem i={i} key={item?.node?.id || item.id} data={item} />
          )}
        </>
      ))}
    </motion.div>
  )
}

export default LatestVideos
