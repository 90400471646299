import { graphql, useStaticQuery } from "gatsby"

const useMeditationAudio = () => {
  const {
    allAudioJson: { nodes },
  } = useStaticQuery(graphql`
    query MeditationAudioQuery {
      allAudioJson {
        nodes {
          audioData {
            id
            label
            audio {
              publicURL
            }
          }
        }
      }
    }
  `)

  const { audioData } = nodes[0]
  return audioData
}

export default useMeditationAudio
