import React from "react"
import Slick from "react-slick"
import { Link } from "gatsby"

// components
import Container from "../Common/Container/Container"
import CarouselItem from "./CarouselItem"

const Exercise = ({ title, list }) => {
  const options = {
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 576,
        settings: {
          infinite: false,
          swipe: true,
          centerMode: true,
          dots: false,
          arrows: false,
          variableWidth: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    //  bg-exercise py-8  sm:py-20 sm:bg-primary xl:py-24
    <div className="pb-8 pt-12 sm:py-20  xl:pt-[135px] xl:pb-0">
      <Container>
        <div className="flex justify-between items-center px-4 sm:px-0">
          <h2 className="text-hd-sm text-blue-700 lg:text-4xl xl:text-hd-xl font-normal">
            {title}
          </h2>
          <Link to="/videos">
            <span
              className="transition-colors text-xs text-[#9abbd2] cursor-pointer md:bg-[#f3f3f3] md:text-[#9abbd2] md:py-2 md:px-6 md:rounded-lg md:font-semibold
            xl:py-2.7 xl:px-7.5 xl:rounded-10 xl:text-btn-sm hover:bg-primary-dark hover:text-white"
            >
              See more
            </span>
          </Link>
        </div>
        <p className="text-txt-dark text-xs mt-1 pl-4 sm:pl-0 lg:mt-2.5 xl:text-base">
          Наша коллекция
        </p>
        <div
          className="mt-10 pt-3 xs:rounded-18 xs:py-[18px] xs:px-3"
          style={{
            background: `linear-gradient(162.45deg, #81acc9 0%, #9cc4d6 100%)`,
          }}
        >
          <Slick
            {...options}
            className="xs:grid xs-r:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4  xs-r:gap-8 sm:gap-4 xs-r:justify-items-center"
          >
            {list.map((item, index) => (
              <CarouselItem key={index} data={item} index={index} />
            ))}
          </Slick>
        </div>
      </Container>
    </div>
  )
}

export default Exercise
