export function getListOfTasks(_tasks, _userTasks, _day) {
  const tasks = _tasks.find(task => task?.day === _day)

  if (!tasks) return null

  const basicTasks = tasks.basicTasks.map(task => ({
    ...task,
    isBasic: true,
  }))
  const extraTasks = tasks.extraTasks.map(task => ({
    ...task,
    isBasic: false,
  }))

  const mergeList = [...basicTasks, ...extraTasks]

  const userSelectedTasks = _userTasks?.find(task => task?.day === _day)

  if (!userSelectedTasks) return mergeList

  const mergeBasicTasks = basicTasks.map(task => {
    const _task = userSelectedTasks.basicTasks.find(
      uTask => uTask.title === task.title
    )

    return _task ? { ...task, isChecked: true } : { ...task, isChecked: false }
  })

  const mergeExtraTasks = extraTasks.map(task => {
    const _task = userSelectedTasks.extraTasks.find(
      uTask => uTask.title === task.title
    )

    return _task ? { ...task, isChecked: true } : { ...task, isChecked: false }
  })

  const newMergeList = [...mergeBasicTasks, ...mergeExtraTasks]

  return newMergeList
}

export function addTask(_task, _userTasks, _day) {
  const userSelectedTasks = _userTasks?.find(task => task?.day === _day)

  let basicTasks = []
  let extraTasks = []

  if (!userSelectedTasks) {
    basicTasks = _task.isBasic
      ? [{ title: _task.title, description: _task.description }]
      : []
    extraTasks = !_task.isBasic
      ? [{ title: _task.title, description: _task.description }]
      : []
  } else {
    basicTasks = _task.isBasic
      ? [
          ...userSelectedTasks.basicTasks,
          { title: _task.title, description: _task.description },
        ]
      : userSelectedTasks.basicTasks

    extraTasks = !_task.isBasic
      ? [
          ...userSelectedTasks.extraTasks,
          { title: _task.title, description: _task.description },
        ]
      : userSelectedTasks.extraTasks
  }

  const _tasks = {
    day: _day,
    basicTasks,
    extraTasks,
  }

  // if (!userSelectedTasks) return [_tasks]
  if (!userSelectedTasks) {
    _userTasks.push(_tasks)
    return _userTasks
  } else return _userTasks.map(item => (item.day === _day ? _tasks : item))
}

export function removeTask(_task, _userTasks, _day) {
  const userSelectedTasks = _userTasks?.find(task => task?.day === _day)

  let basicTasks = []
  let extraTasks = []

  if (userSelectedTasks) {
    basicTasks = _task.isBasic
      ? userSelectedTasks.basicTasks.filter(t => t.title !== _task.title)
      : userSelectedTasks.basicTasks

    extraTasks = !_task.isBasic
      ? userSelectedTasks.extraTasks.filter(t => t.title !== _task.title)
      : userSelectedTasks.extraTasks
  }

  const _tasks = {
    day: _day,
    basicTasks,
    extraTasks,
  }

  if (!userSelectedTasks) {
    _userTasks.push(_tasks)
    return _userTasks
  } else return _userTasks.map(item => (item.day === _day ? _tasks : item))
}

export function findTask(_list, _day) {
  return _list.find(t => t.day === _day)
}

export function getTotalCount(_data) {
  if (!_data) return 0

  let count = 0
  const trashDays = [195, 194, 190, 193]

  const filterList = _data.filter(task => {
    if (!task.hasOwnProperty("date") && !trashDays.includes(task.day)) {
      return task
    }
  })

  filterList.forEach(task => {
    const basicTasks = task.basicTasks.length
    const extraTasks = task.extraTasks.length

    count += basicTasks + extraTasks
  })

  return count
}
