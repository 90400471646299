import React from "react"
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer"

// Register font
Font.register({
  family: "Roboto",
  src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf",
})

const PdfDocument = ({ list }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Shopping List</Text>
        <View style={styles.section}>
          {list?.map((item, i) => (
            <View
              style={{
                ...styles.row,
                borderBottom:
                  i === list.length - 1 ? "1px solid #1f1f1f" : "none",
              }}
              key={i}
              wrap={false}
            >
              <Text style={styles.name}>{item?.nameRU || item?.name}</Text>
              <Text style={styles.quantity}>
                {item?.amount} {item?.unitRU}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}

export default PdfDocument

const styles = StyleSheet.create({
  page: {
    fontSize: 14,
    flexDirection: "column",
    padding: "16px 0",
  },
  title: {
    fontSize: 18,
    marginTop: 32,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight: "semibold",
  },
  section: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 32,
    paddingHorizontal: 24,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    borderTop: "1px solid #1F1F1F",
    borderRight: "1px solid #1F1F1F",
    borderLeft: "1px solid #1F1F1F",
  },
  name: {
    width: "80%",
    fontFamily: "Roboto",
  },
  quantity: {
    width: "20%",
    fontFamily: "Roboto",
    textAlign: "right",
  },
})
