import * as React from "react"

function BiSkipLeftIcon(props) {
  return (
    <svg
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.125 7a.875.875 0 011.75 0v5.684l10.967-6.363c.91-.528 2.158.075 2.158 1.218v12.922c0 1.143-1.248 1.747-2.158 1.218L7.875 15.316V21a.875.875 0 11-1.75 0V7zM19.25 8.108L9.093 14l10.157 5.892V8.108z"
        fill="#81ACC9"
      />
    </svg>
  )
}

export default BiSkipLeftIcon
