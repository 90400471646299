import React, { useState, useEffect } from "react"

// components
import ShoppingListItem from "./ShoppingListItem"
import Container from "../Common/Container/Container"
import Button from "./Button"

// util
import { changeUnit } from "../../utils/helper"

// icons
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai"

import FadeIn from "../Animation/FadeIn"

const ShoppingList = ({
  list,
  recipeList,
  currWeek,
  isToday,
  bgWhite,
  isDialy,
}) => {
  const [listOfItems, setListOfItems] = useState([])
  const [isRender, setIsRender] = useState(false)
  const [isShowAll, setIsShowAll] = useState(false)

  useEffect(() => {
    if (list && recipeList) {
      let currList = []

      if (isToday) currList = list.filter(item => item.day === currWeek.mealDay)
      else currList = list

      let mealList = currList.map(item => {
        for (let i = 0; i < recipeList.length; i++) {
          if ((item?.meal || item?._id) === recipeList[i]._id) {
            return {
              ...item,
              ingredients: recipeList[i]?.recipe?.ingredients,
              servings: setServings(recipeList[i].multiplier, item.servings),
              multiplier: recipeList[i].multiplier,
            }
          }
        }
      })

      let ingredients = []

      mealList.forEach(meal => {
        meal?.ingredients?.forEach(item => {
          let arr = ingredients
          let index = arr.findIndex(ing => item.name.name === ing.name)

          if (index > -1) {
            let temp = ingredients[index]
            ingredients[index] = {
              ...temp,
              amount: temp.amount + item.amount,
            }
          } else {
            ingredients.push({
              name: item.name.name,
              nameRU: item.name.nameRU,
              amount: item.amount * (meal?.servings || 1),
              unit: item.unit.name,
              unitRU: item.unit.nameRU,
            })
          }
        })
      })

      setListOfItems(ingredients)
      setIsRender(true)
    }
  }, [list, recipeList, currWeek])

  const handleClick = () => {
    setListOfItems(changeUnit(listOfItems))
  }

  const setServings = (_multiplier, _servings) => {
    if (!_servings) return 1

    return _multiplier === 1 ? Math.round(_servings) : _servings
  }

  const getListOfItem = () => {
    if (!isShowAll) {
      return listOfItems.slice(0, 5)
    } else {
      return listOfItems
    }
  }

  return (
    <div className="px-4 sm:px-0">
      <Container>
        <FadeIn>
          <div
            className={`shadow-xl  rounded-lg my-10 p-5 ${
              bgWhite
                ? "bg-white text-gray-600"
                : "bg-gradient-to-b from-gradient1 to-gradient2 text-white"
            }`}
          >
            <div className="flex justify-between items-center">
              <h3 className="pb-3 text-lg font-semibold text-inherit opacity-80">
                Список
              </h3>
              <small
                className="hidden pb-3 text-sm text-inherit opacity-75 cursor-pointer select-none"
                onClick={handleClick}
              >
                Change units
              </small>
            </div>
            {getListOfItem().map((item, i) => (
              <ShoppingListItem
                key={item.name.name}
                data={item}
                bgWhite={bgWhite}
              />
            ))}

            <div className="flex justify-between mt-6">
              <button
                className={`transition-opacity duration-100  shadow-xl py-3 px-2.5 md:px-10 text-[10px] md:text-sm font-bold rounded-lg -mr-1 hover:opacity-90 ${
                  bgWhite
                    ? "bg-gradient-to-b from-gradient1 to-gradient2 text-white"
                    : "bg-white text-primary"
                }`}
                onClick={() => setIsShowAll(!isShowAll)}
              >
                {!isShowAll ? (
                  <div className="flex items-center gap-2">
                    <span>Посмотреть полностью</span>
                    <AiOutlineArrowDown />
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <span>Скачать</span>
                    <AiOutlineArrowUp />
                  </div>
                )}
              </button>
              {isRender && (
                <Button
                  key={isDialy ? "daily" : "weekly"}
                  list={listOfItems}
                  bgWhite={bgWhite}
                  isDialy={isDialy}
                />
              )}
            </div>
          </div>
        </FadeIn>
      </Container>
    </div>
  )
}

export default ShoppingList
