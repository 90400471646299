import * as React from "react"

function ChevronIcon(props) {
  return (
    <svg
      width={11}
      height={8}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.399.2h-9.6l4.8 7.2 4.8-7.2z" fill="#787878" />
    </svg>
  )
}

export default ChevronIcon
