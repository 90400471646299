import * as React from "react"

function PreviousArrowIcon(props) {
  return (
    <svg
      width={8}
      height={15}
      viewBox="0 0 11 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 16.5L2 9l8-7.5"
        stroke="#ffffff"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default PreviousArrowIcon
