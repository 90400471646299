import React from "react"
import { Link } from "gatsby"

import "./Dropdown.css"

// sm:mt-4 sm:mb-2
// py-2 mt-2

export default function Dropdown() {
  return (
    <div className="flex items-center px-0  py-2 mt-2 lg:px-4 lg:py-0 xl:mt-0 xl:ml-4  z-50">
      <ul className="xl:w-full">
        <li className="dropdown flex justify-center items-center cursor-pointer  w-full   text-sm text-left dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline  focus:outline-none focus:shadow-outline relative">
          <a>Другое</a>
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            className="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform md:-mt-1"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <div className="transition duration-400 ease-in-out dropdown-menu pt-0 top-0 ml-20  md:ml-0 xl:top-0 absolute hidden xl:flex xl:opacity-0 h-auto  xl:pt-10 w-40 xl:-left-10">
            <ul className="block w-full bg-white shadow-audio-card">
              {/* <li className="py-1">
                <Link to="/podcast">
                  <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
                    Podcast
                  </a>
                </Link>
              </li> */}
              <li className="py-1 hidden">
                <Link to="/blog">
                  <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
                    Блог
                  </a>
                </Link>
              </li>
              <li className="py-1">
                <Link to="/meditations">
                  <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
                    Медитации
                  </a>
                </Link>
              </li>
              <li className="py-1">
                <Link to="/dashboard#pdf">
                  <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
                    PDF файлы
                  </a>
                </Link>
              </li>
              <li className="py-1">
                <Link to="/progress">
                  <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
                    Замеры
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  )
}
