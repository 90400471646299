import * as React from "react"

function BigTickIcon(props) {
  return (
    <svg
      width={80}
      height={64}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M67.579 2.511a7.344 7.344 0 0110.477 10.282l-39.069 48.86a7.342 7.342 0 01-10.575.196L2.503 35.94a7.344 7.344 0 1110.38-10.38l20.503 20.495L67.392 2.727c.062-.076.127-.147.196-.216h-.01z"
        fill="#fff"
      />
    </svg>
  )
}

export default BigTickIcon
