import React, { useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { useInView } from "react-intersection-observer"

const FadeIn = ({ children }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  const variants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
    },
  }

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5 }}
      variants={variants}
    >
      {children}
    </motion.div>
  )
}

export default FadeIn
