import React, { useState } from "react"

// assets
import CloseIcon from "../../../images/icons/Close"

const WeightChangeModal = ({ toggle, handleClick }) => {
  const [formData, setFormData] = useState({
    weight: "",
    glutes: "",
    waist: "",
  })

  const handleChange = e => {
    const { name, value } = e.target

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleClose = () => {
    toggle(false)
  }

  const handleSubmit = (_value, _name) => {
    if (!isEmpty()) {
      handleClick(formData)
      handleClose()
    } else {
      alert("Please enter some values")
    }
  }

  const isEmpty = () => {
    return (
      formData.weight === "" && formData.glutes === "" && formData.waist === ""
    )
  }

  return (
    <div className="w-full h-full fixed z-10 flex items-center justify-center top-0 bg-black bg-opacity-10">
      <div className="w-11/12 max-w-90 bg-white relative flex flex-col items-center justify-center rounded-2xl shadow-video-card xxs:w-full">
        <CloseIcon
          className="self-end mt-2 mr-2 cursor-pointer"
          onClick={handleClose}
        />
        <p className="text-xl text-txt-dark font-semibold">
          Введите свои значения
        </p>
        <p className="mt-2.5 text-sm max-w-13 text-center text-txt-med opacity-50">
          для отслеживания вашего недельного прогресса
        </p>
        <InputBox
          name="weight"
          label="Вес"
          value={formData.weight}
          handleChange={handleChange}
        />
        <InputBox
          name="glutes"
          label="Ягодицы"
          value={formData.glutes}
          handleChange={handleChange}
        />
        <InputBox
          name="waist"
          label="Талия"
          value={formData.waist}
          handleChange={handleChange}
        />
        <button
          className={`transition-colors text-white bg-primary ml-auto mr-4 mb-3 mt-8 rounded-lg  py-2.5 px-8 shadow-btn-xl  hover:bg-white hover:text-primary cursor-pointer`}
          onClick={handleSubmit}
        >
          Создать
        </button>
      </div>
    </div>
  )
}

function InputBox(props) {
  const { name, value, label, handleChange } = props

  return (
    <div className="mt-4 w-full px-4 mb-6">
      <p className="text-sm text-txt-md">{label}</p>
      <div className="mt-2 flex items-center gap-2.5">
        <input
          type="number"
          min="0"
          name={name}
          className="bg-gray-200 px-3 py-1.5 rounded-md w-full"
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default WeightChangeModal
