import React, { useRef, useEffect } from "react"
import Slick from "react-slick"
import { Link } from "gatsby"

// styles
import * as styles from "./secondary.module.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// assets
import NextArrowIcon from "../../images/icons/NextArrow"
import PreviousArrowIcon from "../../images/icons/PreviousArrow"

// components
import CarouselItem from "./CarouselItem"
import Container from "../Common/Container/Container"

import useMeditationAudio from "../../hooks/useMeditationAudio"

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <span
      className={`${className} ${styles.icon__wrapper} ${styles.prev}`}
      onClick={onClick}
    >
      <PreviousArrowIcon />
    </span>
  )
}
function NextArrow(props) {
  const { className, onClick } = props
  return (
    <span
      className={`${className} ${styles.icon__wrapper} ${styles.next}`}
      onClick={onClick}
    >
      <NextArrowIcon />
    </span>
  )
}



export default function CarouselSecondary({
  title1 = "Our",
  title2 = "Podcast",
  list,
  dark,
  subtitle = "Made for you",
  link,
}) {
  let refs = new useRef([])
  //let list = [1, 2, 3, 4];
  const meditationAudioData = useMeditationAudio()
  const options = {
    dots: false,
    speed: 500,
    // slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 3,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          infinite: true,
          variableWidth: true,
          swipeToSlide: true,
          centerMode: true,
          arrows: false,
          slidesToShow: 1,
          touchThreshold: 10,
        },
      },
    ],
  }

  useEffect(() => {
    refs.current = refs.current.slice(0, list.length)
  }, [])

  const onAudioChange = _id => {
    refs.current.forEach(el => {
      if (el.id !== _id) {
        el.listener(false)
      }
    })
  }

  return (
    <div
      // pt-8 pb-16 xs:pt-20 xs:pb-40 xl:pt-24 xl:pb-48
      className={`pt-8 pb-16 xs:pt-20 xs:pb-20 xl:!pt-[135px] xl:!pb-0 ${
        styles.seconday__wrapper
      } ${dark ? styles.dark : ""}`}
    >
      <Container>
        <div className={"flex justify-between items-center"}>
          <h2 className={"text-blue-700 font-normal " + styles.header}>
            {title1} <span className={"text-blue-700"}>{title2}</span>
          </h2>
          <Link to={`/${link}`}>
            <span className="text-xs text-blue-200 pr-4 sm:hidden">
              Browse All
            </span>
          </Link>
          <Link to={`/${link}`} className="hidden sm:block">
            <span className={"transition-colors " + styles.more}>See More</span>
          </Link>
        </div>
        <p
          className={
            "text-txt-md opacity-40 sm:opacity-100 sm:text-txt-light " +
            styles.info
          }
        >
          {subtitle}
        </p>
        <div className="mt-10">
          <Slick {...options}>
            {list &&
              list.map((item, i) => (
                <>
                  <CarouselItem
                    key={item.id}
                    ref={el => (refs.current[i] = { ...el, id: item.id })}
                    id={item.id}
                    data={{
                      ...item,
                      audioUrl: () => {
                        const aud = meditationAudioData.find(audio => {
                          return audio.id === item.id
                        })
                        //return `https://drive.google.com/uc?export=download&id=${aud.id}`
                        return aud.audio.publicURL
                        // return `https://esteekeyfitness.com${aud.file}`
                      },
                    }}
                    onChange={onAudioChange}
                    dark={dark}
                  />
                </>
              ))}
          </Slick>
        </div>
      </Container>
    </div>
  )
}
