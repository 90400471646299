import React, { useState, useEffect } from "react"

// component
import Container from "../Container/Container"
import Search from "../Search/Search"
import Tag from "../Tag/Tag"
import RecipeList from "../RecipeList"
import SubTitle from "../SubTitle/SubTitle"

// assets
import CloseIcon from "../../../images/icons/Close"

// mockup
import mockup from "../../../utils/data"

// utils
import { sortMealPlan } from "../../../utils/helper"

// const tags = [
//   "Breakfast",
//   "Lunch",
//   "Dinner",
//   "Protein",
//   "Snack",
//   "Fruit",
//   "Все",
// ]
const tags = ["Ужин", "Обед", "Перекус", "Фрукт", "Белки", "Завтрак", "Все"]
let tagList = []

const MealChangeModal = ({
  recipeList,
  data,
  toggle,
  onMealChange,
  userData,
}) => {
  const [list, setList] = useState([])
  const [tempList, setTempList] = useState([])
  const [activeTag, setActiveTag] = useState("")

  useEffect(() => {
    if (recipeList) {
      let newList = filterList(recipeList, data?.time || data?.mealTime?.nameRU)

      newList = newList.map(item => ({
        ...item,
        inRange: checkRange(item.calories),
      }))
      setActiveTag(data?.time || data?.mealTime?.nameRU)

      // setList(newList)
      // setTempList(
      //   recipeList.map(item => ({
      //     ...item,
      //     inRange: checkRange(item.calories),
      //   }))
      // )

      setList(sortMealPlan(data?.calories, newList))
      // setTempList(sortMealPlan(data?.calories, newList))
      setTempList(sortMealPlan(data?.calories, recipeList))
    }
  }, [recipeList])

  const removeAllergens = _list => {
    let items = []
    _list.forEach(item => {
      let check = true
      if (item.allergens.length > 0) {
        for (let i = 0; i < item.allergens.length; i++) {
          console.log(
            userData.allergies,
            item.allergens[i].name,
            userData.allergies.indexOf(item.allergens[i].name)
          )
          if (userData.allergies.indexOf(item.allergens[i].name) > -1) {
            // items.push(item)
            check = false
            break
          }
        }

        if (check) items.push(item)
      } else {
        items.push(item)
      }
    })

    // console.log("orignal list", _list)
    // console.log("step 1", items)

    let newList = []

    items.forEach(item => {
      item.mealType.forEach(type => {
        if (type.name.includes(userData.dietType)) newList.push(item)
      })
    })

    console.log("step 2", newList)

    return newList
  }

  const checkRange = _calories => {
    let max = (data?.mealCals || data?.calories || 0) + 50
    let min = (data?.mealCals || data?.calories || 0) - 50
    return _calories >= min && _calories <= max
  }

  const handleSearch = _value => {
    console.log(_value)
    if (activeTag !== "Все") {
      if (_value !== "") {
        setList(filterList(list, _value))
      } else {
        setList(removeAllergens(tagList))
      }
    } else {
      if (_value !== "") {
        setList(filterList(tempList, _value))
      } else {
        setList(removeAllergens(tempList))
      }
    }
  }

  const filterList = (_list, _value) => {
    let newList = _list.filter(
      item =>
        item.name.toLowerCase().indexOf(_value.toLowerCase()) !== -1 ||
        item.mealTime.nameRU.toLowerCase().indexOf(_value.toLowerCase()) !== -1
      // || item.tags.findIndex(item =>
      //   item.toLowerCase().includes(_value.toLowerCase())
      // ) > -1
    )

    return removeAllergens(newList)
  }

  const checkActive = _value => {
    return activeTag === _value
  }

  const changeTag = _label => {
    setActiveTag(_label)

    if (_label !== "Все") {
      let filter = tempList.filter(
        item =>
          // item.tags.findIndex(tag =>
          //   tag.toLowerCase().includes(_label.toLowerCase())
          // ) > -1
          item.mealTime.nameRU.toLowerCase().indexOf(_label.toLowerCase()) !==
          -1
      )

      // removeAllergens(filter)
      setList(removeAllergens(filter))
    } else {
      setList(removeAllergens(tempList))
    }
  }

  const handleClose = () => {
    toggle(false)
  }

  const handleChange = _meal => {
    onMealChange(data?.meal || data?._id, data.day, _meal.servings, _meal)
    toggle(false)
  }

  console.log("model", { recipeList, userData })

  return (
    <div
      className="w-full h-full fixed z-10 flex items-center justify-center top-0 bg-black bg-opacity-10"
      style={{ zIndex: "99" }}
    >
      <Container customPad padding="xs-r:px-2 sm:px-4 lg:px-8 2xl:px-0 w-full">
        <div className="w-full bg-white p-3 relative rounded-2xl shadow-video-card">
          <div className="flex justify-end">
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </div>
          <div className="mt-4">
            <SubTitle
              title1="Заменить"
              title2="блюдо"
              text="Swap your meal here"
            />
            <div className="flex justify-between items-center my-5 flex-col-reverse lg:flex-row gap-5 lg:gap-0 md:my-10 ">
              <div className="flex items-center gap-2 flex-wrap justify-center sm:justify-start">
                {tags.map((value, i) => (
                  <Tag
                    key={i}
                    label={value}
                    isActive={checkActive(value)}
                    changeTag={changeTag}
                  />
                ))}
              </div>
              <Search handleSearch={handleSearch} />
            </div>
          </div>
          <RecipeList list={list} handleChange={handleChange} />
        </div>
      </Container>
    </div>
  )
}

export default MealChangeModal
