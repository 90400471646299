import React, { useState, useEffect } from "react"
import Select, { components } from "react-select"
import Checkbox from "react-custom-checkbox"
import { Check } from "../../images/icons/icons"

import * as styles from "../../styles/userInput.module.css"

// Allergies options
// const options = [
//   { value: "Peanut", label: "Peanut" },
//   { value: "Soy", label: "Soy" },
//   { value: "Shellfish", label: "Shellfish" },
//   { value: "TreeNuts", label: "Tree Nuts" },
//   { value: "Fish", label: "Fish" },
//   { value: "Gluten", label: "Gluten" },
//   { value: "Eggs", label: "Eggs" },
//   { value: "Dairy", label: "Dairy" },
// ]
// Diet Types
const dietTypes = [
  { value: "Pescatarian", label: "Pescatarian" },
  { value: "Standard", label: "Standard" },
  { value: "Vegan", label: "Vegan" },
  { value: "Vegetarian", label: "Vegetarian" },
]

const IndicatorsContainer = props => {
  return (
    <div className="bg-gray-lightest md:bg-white">
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const controlStyles = {
  color: "white",
}

const ControlComponent = props => (
  <div style={controlStyles}>
    <components.Control className={"w-full " + styles.control} {...props} />
  </div>
)

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer
    className="bg-gray-lightest md:bg-white h-6 md:h-8"
    {...props}
  >
    {children}
  </components.ValueContainer>
)
const MultiValueContainer = props => {
  return (
    <components.MultiValueContainer
      className={styles.multiValueContainer}
      {...props}
    />
  )
}

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
)

const FinalInfo = ({
  formData,
  setFormData,
  totalCalories,
  allergies,
  mealTypes,
}) => {
  const [allergyOptions, setAllergyOptions] = useState([])
  const [mealOptions, setMealOptions] = useState([])

  useEffect(() => {
    if (allergies) {
      let options = allergies.map(item => {
        return {
          value: item.nameRU,
          label: item.nameRU,
          valueEN: item.name,
          labelEN: item.name,
        }
      })
      setAllergyOptions(options)
    }
  }, [allergies])

  useEffect(() => {
    if (mealTypes) {
      let options = mealTypes.map(item => {
        return {
          value: item.nameRU,
          label: item.nameRU,
          valueEN: item.name,
          labelEN: item.name,
        }
      })
      setMealOptions(options)
    }
  }, [mealTypes])

  return (
    <div>
      <div className="flex flex-col md:my-2 md:mx-6">
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70 font-medium">
          Тип питания:
          <Select
            closeMenuOnSelect={true}
            components={{
              IndicatorsContainer,
              ValueContainer,
              SingleValue,
              ControlComponent,
            }}
            options={mealOptions}
            onChange={data => {
              setFormData({
                ...formData,
                type: data,
              })
            }}
            value={formData.type}
            styles={{
              singleValue: base => ({ ...base, color: "white" }),
              valueContainer: base => ({
                ...base,
                color: "#1F1F1F",
                width: "100%",
                padding: "4px",
                fontSize: "10px",
                borderRadius: "5px 0 0 5px",
              }),
              singleValue: base => ({
                ...base,
                padding: 5,
                borderRadius: 5,
                opacity: "60%",
                color: "#1F1F1F",
                display: "flex",
              }),
              indicatorsContainer: base => ({
                ...base,
                borderRadius: "0 5px 5px 0",
                height: "25px",
                display: "flex",
              }),
              indicatorSeparator: base => ({
                ...base,
                display: "none",
              }),
              control: base => ({
                ...base,
                minHeight: "25px",
                border: "none",
                marginTop: "0.2rem",
              }),
            }}
            className={
              "opacity-100 text-gray-darkest md:my-2 text-opacity-60 border-none md:shadow-xs " +
              styles.select
            }
          />
        </div>
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70 font-medium">
          Аллергии:
          <Select
            closeMenuOnSelect={true}
            components={{
              IndicatorsContainer,
              ValueContainer,
              MultiValueContainer,
              Control: ControlComponent,
            }}
            onChange={data => {
              setFormData({
                ...formData,
                allergies: data,
              })
            }}
            value={formData.allergies}
            styles={{
              singleValue: base => ({ ...base, color: "white" }),
              valueContainer: base => ({
                ...base,
                color: "white",
                width: "100%",
                height: "30px",
                padding: "0px",
                fontSize: "10px",
                borderRadius: "5px",
                padding: "0 0.5rem",
              }),
              multiValue: base => ({
                ...base,
                border: "none",
                borderRadius: "10px",
                padding: "3px",
                margin: "4px",
                justifyContent: "center",
                alignItems: "flex-end",
                width: "70px",
                height: "22px",
                background:
                  "linear-gradient(180deg, #8FC6EC 0%, #A4C9E2 0.01%, #6897B7 100%)",
                boxShadow:
                  "0.672414px 4.7069px 10.0862px rgba(134, 176, 205, 0.42)",
              }),
              multiValueLabel: base => ({
                color: "#C0ECF5",
                fontWeight: "500",
                margin: "0 3px",
                position: "relative",
                left: "5px",
              }),
              multiValueRemove: base => ({
                ...base,
                border: `none `,
                height: "100%",
                ":hover": "none",
              }),
              indicatorsContainer: base => ({
                ...base,
                display: "none",
              }),
            }}
            isMulti
            options={allergyOptions}
            className={
              "opacity-100 mt-1 h-6 text-gray-darkest md:my-2 text-opacity-60 border-none " +
              styles.allergiesSelect
            }
          />
        </div>
        {/* <div className=" mt-6 py-1 relative flex items-center justify-between text-xs border-b-2 border-lightgray">
          <Checkbox
            name="checkbox"
            id="checkbox"
            label="Да, у меня физическая работа"
            icon={<Check />}
            labelStyle={{ marginLeft: 0 }}
            containerClassName="w-full flex-row-reverse justify-between"
            borderColor="#EDEDED"
            borderWidth={2}
            size={17}
            className={`overflow-hidden`}
            onChange={value => {
              setFormData({
                ...formData,
                isJob: value,
              })
            }}
            checked={formData.isJob}
          />
        </div> */}
        {totalCalories >= 1800 && (
          <div className=" mt-6 py-1 relative flex items-center justify-between text-xs border-b-2 border-lightgray">
            <Checkbox
              name="checkbox"
              id="checkbox"
              label="Хочу вкусные шейки в своём рационе!"
              icon={<Check />}
              labelStyle={{ marginLeft: 0 }}
              containerClassName="w-full flex-row-reverse justify-between"
              borderColor="#EDEDED"
              borderWidth={2}
              size={17}
              className={`overflow-hidden`}
              onChange={value => {
                setFormData({
                  ...formData,
                  shakes: value,
                })
              }}
              checked={formData.shakes}
            />
          </div>
        )}
      </div>
      {/* <div className="mt-4">
        <p className="text-primary text-base">TotalCalories: {totalCalories}</p>
      </div> */}
    </div>
  )
}

export default FinalInfo
