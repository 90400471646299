import React from "react"
import { Link, navigate } from "gatsby"
import { motion } from "framer-motion"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// assets
import ArrowRightIcon from "../../images/icons/ArrowRight"
import recipe1 from "../../images/recipe/recipe1.png"

import { GiHotMeal } from "react-icons/gi"
import { FaExchangeAlt } from "react-icons/fa"

// style
import * as style from "./mealPlan.module.css"

const MealItem = ({ i, data, dark, toggle }) => {
  const handleClick = () => {
    let check = toggle || null

    if (check) {
      toggle(true, data)
    }
  }

  // const variants = {
  //   visible: i => ({
  //     opacity: 1,
  //     transition: {
  //       delay: i * 0.3,
  //     },
  //   }),
  //   hidden: { opacity: 0 },
  // }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeInOut", duration: 0.25, delay: i * 0.1 }}
    >
      <div
        className={`
      ${dark ? style.meal_item_dark : style.meal_item} 
      transition-colors shadow-audio-card rounded-xl rounded-tl-50 rounded-bl-50  flex  items-center  relative cursor-pointer md:justify-center md:rounded-xl md:flex-col`}
        onClick={() =>
          navigate(`/recipes/${data?.slug?.current}?${data?.servings || 1}`)
        }
      >
        {data.image.asset?.url ? (
          <div
            className="w-12 h-12 rounded-full overflow-hidden ml-2 my-2 md:my-0 md:ml-0 md:w-38 md:h-38 md:absolute md:-top-20"
            style={{
              boxShadow: "5px 11px 23px rgba(0, 0, 0, 0.12)",
            }}
          >
            <img
              src={data.image.asset.url}
              alt={data.nameRu}
              className="h-full object-cover object-center"
            />
          </div>
        ) : (
          <GatsbyImage
            className="w-12 h-12 rounded-full overflow-hidden ml-2 my-2 md:my-0 md:ml-0 md:w-38 md:h-38 md:!absolute md:-top-20"
            style={{
              boxShadow: "0px 12px 20px rgba(123, 165, 199, 0.15)",
            }}
            image={getImage(data.image.asset.localFile ?? data.image.asset)}
            alt={data.nameRu}
            imgClassName="object-center rounded-full"
          />
        )}
        <div className="flex-grow flex items-center justify-between md:mt-24 md:text-center md:block">
          <div className="ml-7 md:ml-0">
            <p
              className={`transition-colors text-xss opacity-70 md:text-base md:opacity-100 ${
                dark ? "text-white" : "text-primary"
              }`}
            >
              <span className="font-semibold ">
                {/* {data?.time || data?.mealTime?.nameRU} */}
                {data?.time || data?.mealTime?.name}
              </span>{" "}
              - {Math.round(data?.mealCals) || Math.round(data?.calories) || 0}{" "}
              кал
            </p>
            <p
              className={`transition-colors text-xss opacity-70 font-medium mt-1 md:mt-0 md:text-sm ${
                dark ? "text-white" : "text-primary"
              } ${style.name}`}
            >
              {data?.mealName || data?.nameRU}
            </p>
            <div className={`hidden md:block mt-2 ${style.prop}`}>
              <p
                className={`transition-colors text-sm opacity-70  md:opacity-100 ${
                  dark ? "text-white" : "text-primary"
                }`}
              >
                Белки : {Math.round(data?.protein)} г
              </p>
              <p
                className={`transition-colors text-sm opacity-70  md:opacity-100 ${
                  dark ? "text-white" : "text-primary"
                }`}
              >
                Углеводы : {Math.round(data?.carbs)} г
              </p>
              <p
                className={`transition-colors text-sm opacity-70  md:opacity-100 ${
                  dark ? "text-white" : "text-primary"
                }`}
              >
                Жиры : {Math.round(data?.fat)} г
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center gap-2 md:mt-5 md:mb-7 mr-5 md:mr-0 md:gap-2.5 xl:gap-1.5">
            <button
              onClick={e => {
                e.stopPropagation()
                handleClick()
              }}
              className="hidden md:inline transition-opacity duration-100 bg-white text-primary shadow-xl py-2 px-4 text-sm font-medium rounded-md hover:opacity-90"
            >
              Заменить
            </button>
            {/* <Link to={`/recipes/${data?.slug?.current}?${data?.servings}`}> */}
            <button
              className="hidden md:inline transition-opacity duration-100 bg-white text-primary shadow-xl py-2 px-4 text-sm font-medium rounded-md hover:opacity-90"
              onClick={e => {
                e.stopPropagation()
                navigate(
                  `/recipes/${data?.slug?.current}?${data?.servings || 1}`
                )
              }}
            >
              Рецепт
            </button>
            {/* </Link> */}
            <span
              onClick={e => {
                e.stopPropagation()
                handleClick()
              }}
              className="inline md:hidden text-white"
            >
              <FaExchangeAlt />
            </span>
            {/* <Link to={`/recipes/${data?.slug?.current}?${data?.servings}`}> */}
            <span
              className="inline md:hidden text-white"
              onClick={e => {
                e.stopPropagation()
                navigate(
                  `/recipes/${data?.slug?.current}?${data?.servings || 1}`
                )
              }}
            >
              <GiHotMeal />
            </span>
            {/* </Link> */}
          </div>
          {/* <button
          className={`transition-colors mr-6 text-base font-medium flex items-center justify-center gap-2 md:mt-5 md:mb-7 md:mx-auto ${
            dark ? "text-white" : "text-primary"
          }`}
        >
          <span className="hidden md:inline">Replace meal</span>
          <ArrowRightIcon />
        </button> */}
        </div>
      </div>
    </motion.div>
  )
}

export default MealItem
