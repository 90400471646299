import React from "react"
import { Link } from "gatsby"
import axios from "axios"
import fileDownload from "js-file-download"
import { toast } from "react-toastify"

// assets
import DownloadIcon from "../../images/icons/Download"

// style
import * as style from "./download.module.css"

// data?.asset?.url

const DownloadItem = ({ data, index, dark = false }) => {
  const handleClick = (_url, _name) => {
    axios
      .get(_url, {
        responseType: "blob",
      })
      .then(res => {
        fileDownload(res.data, _name)
      })
      .catch(err => {
        console.log(err)
        toast.error(err.message)
      })
  }

  const getType = (_name, _url) => {
    let arr = _url.split(".")

    return `${_name.replace(" ", "")}.${arr[arr.length - 1]}`
  }

  return (
    <div
      className={`
      ${dark ? style.item_dark : style.item} 
      transition-colors shadow-audio-card rounded-xl rounded-tr-50 rounded-br-50 py-5 pl-5 pr-8 flex items-center cursor-pointer`}
      // onClick={() => handleClick(data?.asset?.url || data.url, "main.pdf")}
      onClick={() =>
        handleClick(
          data?.asset?.url,
          data?.asset?.originalFilename ||
            getType(data?.asset?.title || "file", data?.asset?.url)
        )
      }
    >
      <div className="flex-grow">
        <p
          className={`text-base font-semibold line-clamp-1
          ${dark ? "text-white" : "text-primary"}`}
        >
          {/* {data?.title} */}
          Скачать #{index}
        </p>
        <p
          className={`hidden text-sm font-medium line-clamp-1
          ${dark ? "text-white" : "text-gray-500"}`}
        >
          {/* {data.description} */}
          Click to download this file
        </p>
      </div>
      <DownloadIcon className={`${dark ? "text-white" : "text-primary"}`} />
    </div>
  )
}

export default DownloadItem
