import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import moment from "moment"
import { toast } from "react-toastify"

// component
import Container from "../Common/Container/Container"
import SubTitle from "../Common/SubTitle/SubTitle"
import LineChart from "./LineChart"
import MeasurementChangeModal from "../Common/Modal/MeasurementChangeModal"

// Context
import UserContext from "../../context/UserAuth"

// api
import { updateUserMeasurment } from "../../utils/requests"

// utils
import { getWeeklyStats } from "../../utils/helper"
import { getCurrentWeek } from "../../utils/dateHelper"

// // YYYY-MM-DD
// const currentWeek = getCurrentWeek("2022-08-15")

const ProgressContent = () => {
  const { user, startTime } = useContext(UserContext) || {
    user: null,
    startTime: "2022-08-15",
  }

  const [isOpen, setIsOpen] = useState(false)
  const [weightList, setWeightList] = useState([])
  const [glutesList, setGlutesList] = useState([])
  const [waistList, setWaistList] = useState([])
  const [userData, setUserData] = useState([])

  const [currentWeek] = useState(getCurrentWeek(startTime))

  useEffect(() => {
    if (user) {
      fetchUserData(user.sub)
    }
  }, [user])

  const fetchUserData = async _id => {
    try {
      const { data } = await axios(`https://esteekeyapi.com/getdata?id=${_id}`)

      setWeightList(getWeeklyStats(data.data[0]?.weight2 || []))
      setGlutesList(getWeeklyStats(data.data[0]?.glutes || []))
      setWaistList(getWeeklyStats(data.data[0]?.waist || []))

      setUserData(data.data[0])
    } catch (e) {
      console.log(e)
    }
  }

  const toggle = _check => {
    if (_check) {
      setIsOpen(_check)
    } else {
      setIsOpen(_check)
    }
  }

  const handleMeasurement = _data => {
    console.log("formData", _data)

    if (_data.weight !== "") {
      updateMeasurment(
        _data.weight,
        "weight",
        userData?.weight2 || [],
        setWeightList
      )
    }

    if (_data.glutes !== "") {
      updateMeasurment(
        _data.glutes,
        "glutes",
        userData?.glutes || [],
        setGlutesList
      )
    }

    if (_data.waist !== "") {
      updateMeasurment(
        _data.waist,
        "waist",
        userData?.waist || [],
        setWaistList
      )
    }
  }

  const updateMeasurment = (_value, _type, _list, _setter) => {
    const data = {
      reading: parseInt(_value),
      // day: currentWeek.currDay, // set curr day to get mean of week
      day: currentWeek.currDay,
    }

    updateUserMeasurment(user.sub, data, _type)
      .then(() => {
        updateUI(_list, data, newList => {
          _setter(newList)
        })
      })
      .catch(err => {
        console.log(err)
        toast.error("Please fill out user form first!")
      })
  }

  const updateUI = (_arr, _newItem, _callback) => {
    const filter = _arr.filter(item => item.hasOwnProperty("day"))

    if (filter.length > 0) {
      const index = filter.findIndex(item => item.day === _newItem.day)

      if (index !== -1) {
        filter[index] = _newItem
      } else {
        filter.push(_newItem)
      }
    } else {
      filter.push(_newItem)
    }

    _callback(getWeeklyStats(filter))
  }

  return (
    <div className="py-8 px-4 sm:px-0 sm:py-12 xl:py-16">
      <Container>
        <SubTitle title2="Прогресс" />
        <div className="mt-8">
          <div className="pt-5 pb-4 px-4 bg-gray-100 max-w-[380px] rounded-sm">
            <h3 className="text-lg font-semibold text-txt-dark">
              Привет{" "}
              <span className="font-medium text-blue-700">{user.nickname}</span>
            </h3>
            <p className="text-txt-med text-sm mt-2">Your progress</p>
            <button
              className="mt-3 w-[220px] transition-opacity duration-100 py-3 px-4 text-sm font-medium rounded-md bg-gradient-to-b from-gradient1 to-gradient2 text-white  hover:opacity-90"
              onClick={toggle}
            >
              Добавить замеры
            </button>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8">
            <LineChart key="Вес" title="Вес" unit="кг" data={weightList} />
            <LineChart
              key="Ягодицы"
              title="Ягодицы"
              unit="CM"
              data={glutesList}
            />
            <LineChart key="Талия" title="Талия" unit="CM" data={waistList} />
          </div>
        </div>
      </Container>

      {isOpen && (
        <MeasurementChangeModal
          toggle={toggle}
          handleClick={handleMeasurement}
        />
      )}
    </div>
  )
}

export default ProgressContent
