import React from "react"

const Loader = () => {
  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{
        height: "calc(100vh - 157.66px)",
      }}
    >
      <p>Loading</p>
    </div>
  )
}

export default Loader
