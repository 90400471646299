import React, {
  useEffect,
  forwardRef,
  useState,
  useImperativeHandle,
} from "react"
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar"
import { Link } from "gatsby"

// hook
import { useAudio } from "../../Common/Hooks/useAudio"

// assets
import PlayIcon from "../../../images/icons/Play"
import { FaPause } from "react-icons/fa"

// utils
import { getCurrentTime, getTotalDuration } from "../../../utils/helper"

// styles
import "react-circular-progressbar/dist/styles.css"
import * as styles from "./audioplayer.module.css"

let interval = null

const AudioPlayer = forwardRef(({ data, onChange, dark }, ref) => {
  const [audio, playing, duration, toggle] = useAudio(data.audio.asset.url)

  const [currentTime, setCurrentTime] = useState({
    time: 0,
    label: "00:00",
  })
  const [totalDuration, setTotalDuration] = useState("")

  useEffect(() => {
    interval = setInterval(() => {
      if (playing) {
        setCurrentTime({
          time: audio.currentTime,
          label: getCurrentTime(audio.currentTime),
        })
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [playing])

  useEffect(() => {
    if (duration) {
      setTotalDuration(getTotalDuration(duration))
    }
  }, [duration])

  const handleClick = () => {
    onChange(data.id)
    toggle(!playing)
  }

  useImperativeHandle(ref, () => ({
    listener(_flag) {
      toggle(_flag)
    },
  }))

  const addClass = (_cls1, _cls2) => {
    if (dark) {
      if (!playing) return _cls1
      return _cls2
    } else {
      if (!playing) return _cls2
      return _cls1
    }
  }
  return (
    <div
      className={`${styles.player__wrapper} ${playing && styles.active}
      ${addClass("bg-gradient-to-br from-blue-700 to-blue-800", "bg-white")} 
      w-64 relative shadow-audio-card p-0 rounded-xl rounded-tr-50 rounded-br-50  xxs:w-full sm:p-5 sm:pr-7 sm:rounded-xl`}
    >
      {totalDuration !== "" && (
        <p
          className={`${playing ? "block" : "hidden"} 
            text-blue-500 absolute right-2 -top-4  text-xs sm:hidden`}
        >
          {currentTime.label} / {totalDuration}
        </p>
      )}
      <div className="flex items-center ">
        <Link to={data.to} className="flex-grow">
          <div className="cursor-pointer p-4 pr-0 sm:p-0">
            <h3
              className={`transition-colors
            ${addClass(
              "text-white",
              "text-blue-700"
            )} text-sm font-medium sm:text-base`}
            >
              {data.titleRU}
            </h3>
            {/* <p
            className={`invisible transition-colors
          ${addClass(
            "text-white",
            "text-gray-500"
          )} text-xs pt-0.5 sm:text-sm sm:pt-1.5`}
          >
            {data.shortDescription}
          </p> */}
            {totalDuration && (
              <p
                className={`transition-colors
              ${addClass(
                "text-white",
                "text-blue-500"
              )} hidden sm:block text-sm pt-3.5`}
              >
                {currentTime.label} / {totalDuration}
              </p>
            )}
          </div>
        </Link>
        <div
          className="w-13 h-13 cursor-pointer mr-2 my-2 sm:my-0"
          onClick={handleClick}
        >
          <CircularProgressbarWithChildren
            counterClockwise={true}
            strokeWidth={14}
            value={currentTime.time}
            maxValue={duration ? duration : 100}
            styles={buildStyles({
              pathColor: `${addClass("rgba(255, 255, 255, 1)", "#81ACC9")}`,
              trailColor: `
              ${addClass(
                "rgba(255, 255, 255, .5)",
                "rgba(129, 172, 201, 0.5)"
              )}`,
            })}
          >
            {!playing ? (
              <PlayIcon className="ml-1" fill={addClass("white", "#6797B8")} />
            ) : (
              <FaPause color={addClass("white", "#6797B8")} />
            )}
          </CircularProgressbarWithChildren>
        </div>
      </div>
    </div>
  )
})

export default AudioPlayer
