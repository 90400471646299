import React from "react"

// components
import FeatureSection from "./FeatureSection/FeatureSection"
import PriceSection from "./PriceSection/PriceSection"
import TargetSection from "./TargetSection/TargetSection"
import BenefitSection from "./BenefitSection/BenefitSection"
import AccordionSection from "./AccordionSection/AccordionSection"
import ContactSection from "./ContactSection/ContactSection"
import TimeSection from "./TimeSection/TimeSection"
import Hero from "./Hero/Hero"
import Form from "./Form/Form"

const index = () => {
  return (
    <div className="pt-[69px] xl:pt-0">
      <Hero />
      {/* <FeatureSection />
      <TargetSection />
      <PriceSection />
      <BenefitSection />
      <AccordionSection />
      <ContactSection />
      <TimeSection /> */}
      {/* <Form /> */}
    </div>
  )
}

export default index
