import React from "react"
import { Link } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"

import "./Dropdown.css"

const Profile = ({ user }) => {
  const { logout } = useAuth0()

  const getCharacter = () => {
    return user.nickname.charAt(0)
  }

  return (
    <div className="flex items-center px-0  py-2 mt-2 lg:px-4 lg:py-0 xl:mt-0 xl:ml-4  z-40">
      <ul className="xl:w-full">
        <li className="dropdown flex justify-center items-center cursor-pointer  w-full   text-sm text-left dark-mode:bg-transparent dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:focus:bg-gray-600 dark-mode:hover:bg-gray-600 md:w-auto md:inline  focus:outline-none focus:shadow-outline relative">
          <div className="w-10 h-10 rounded-full cursor-pointer ml-2 flex items-center justify-center bg-primary">
            <span className="text-white text-xl font-semi uppercase">
              {getCharacter()}
            </span>
          </div>
          <div className="transition duration-400 ease-in-out dropdown-menu pt-0 top-0 ml-20  md:ml-0 xl:top-0 absolute hidden xl:flex xl:opacity-0 h-auto  xl:pt-10 w-40">
            <ul className="block w-full bg-white shadow-audio-card">
              <li className="py-1">
                <span className="px-4 py-2 mt-2 text-sm text-primary ">
                  {user?.nickname}
                </span>
              </li>
              <li className="py-1">
                <Link to="/account">
                  <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
                  Аккаунт 
                  </a>
                </Link>
              </li>
              <li className="py-1">
                <button
                  className="w-full text-left transition duration-200 ease-in-out px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline"
                  onClick={() =>
                    logout({
                      returnTo: window.location.origin,
                    })
                  }
                >
                  Выйти 
                </button>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default Profile

// import React from "react"
// import { Link } from "gatsby"

// import "./Dropdown.css"

// const Profile = ({ user }) => {
//   return (
//     <div className="transition duration-400 ease-in-out dropdown-menu pt-0 top-0 ml-20  md:ml-0 xl:top-0 absolute hidden xl:flex xl:opacity-0 h-auto  xl:pt-10 w-40">
//       <ul className="block w-full bg-white shadow-audio-card">
//         <li className="py-1">
//           <span className="px-4 py-2 mt-2 text-sm text-black ">
//             {user?.nickname}
//           </span>
//         </li>
//         <li className="py-1">
//           <Link to="/profile-setting">
//             <a className="transition duration-200 ease-in-out block px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
//               Profile Setting
//             </a>
//           </Link>
//         </li>
//         <li className="py-1">
//           <button className="w-full text-left transition duration-200 ease-in-out px-4 py-2 mt-2 text-sm bg-transparent text-black hover:text-white hover:bg-blue300 focus:text-white focus:bg-blue300 md:mt-0 focus:outline-none focus:shadow-outline">
//             Logout
//           </button>
//         </li>
//       </ul>
//     </div>
//   )
// }

// export default Profile
