import React from "react"

const Item = ({ label, value }) => {
  return (
    <div className="flex  items-center my-1">
      <div className="text-xs font-medium whitespace-nowrap text-txt-light text-left mr-4 md:text-base">
        {label}
      </div>
      <div className="w-2/3 border-t border-gray-EEEEEE flex-grow"></div>
      <div className="text-xs font-medium whitespace-nowrap text-txt-light text-right ml-4 md:text-base">
        {value}
      </div>
    </div>
  )
}

export default Item
