import React, { useState } from "react"
import { useContext } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { loadStripe } from "@stripe/stripe-js"
import keys from "../config/keys"

// assets
import { BlueArrow, Arrow } from "../images/icons/icons"

// styles
import * as styles from "../styles/pricingPlan.module.css"

// components
import Container from "./Common/Container/Container"

// Context
import UserContext from "../context/UserAuth"

export function Img1() {
  return (
    <StaticImage
      src="../images/planwoman1.png"
      alt="Esteekey Pricing Plan"
      style={{ borderRadius: "14px", overflow: "hidden" }}
    />
  )
}

export function Img2() {
  return (
    <StaticImage
      src="../images/planwoman2.png"
      alt="Esteekey Pricing Plan"
      style={{ borderRadius: "14px", overflow: "hidden" }}
    />
  )
}

export function Img3() {
  return (
    <StaticImage
      src="../images/planwoman3.png"
      alt="Esteekey Pricing Plan"
      style={{ borderRadius: "14px", overflow: "hidden" }}
    />
  )
}

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUB_KEY)
  }
  return stripePromise
}

export default function PricingPlan() {
  const { isAuthenticated } = useContext(UserContext) || {
    isAuthenticated: false,
  }
  const [loading, setLoading] = useState(false)

  const handlePlan = async _plan => {
    if (isAuthenticated) {
      setLoading(true)

      let priceId =
        _plan === "standard"
          ? process.env.GATSBY_STANDARD_PRICE_ID
          : _plan === "premium"
          ? process.env.GATSBY_PREMIUM_PRICE_ID
          : process.env.GATSBY_VIP_PRICE_ID

      const stripe = await getStripe()
      const { error } = await stripe.redirectToCheckout({
        mode: "payment",
        lineItems: [{ price: priceId, quantity: 1 }],
        successUrl: `http://localhost:8080/success?${priceId}`,
        cancelUrl: `http://localhost:8080/plan`,
      })

      if (error) {
        alert("payment not processed")
        setLoading(false)
        return
      }
    } else {
      alert("Please Signup First")
    }
  }

  const pricingPlans = [
    {
      id: 1,
      title: ["Standard"],
      subTitle: "Become a Standard member",
      data: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit nemo delectus dolorum, excepturi dolor perferendis tempora at et! Hic modi iste tenetur alias ipsum labore rem quia eligendi, aperiam tempora. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus possimus accusamus dolore assumenda, tempore asperiores expedita fugiat beatae magnam culpa laudantium autem non in officia inventore quasi distinctio doloremque eligendi!",
      price: "$97",
      priceTitle: "Buy now",
      alt: "woman-with-book",
      side: "md:flex-row",
      color: "blue",
      compId: "fat-loss",
      url: "#standard",
      type: "standard",
    },
    {
      id: 2,
      // 1st value of array is in black color and 2nd is in blue color
      title: ["Premium"],
      subTitle: "Become a Premium member",
      data: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit nemo delectus dolorum, excepturi dolor perferendis tempora at et! Hic modi iste tenetur alias ipsum labore rem quia eligendi, aperiam tempora. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus possimus accusamus dolore assumenda, tempore asperiores expedita fugiat beatae magnam culpa laudantium autem non in officia inventore quasi distinctio doloremque eligendi!",
      price: "$97",
      priceTitle: "Buy now",
      alt: "woman-in-park",
      side: "md:flex-row-reverse",
      color: "white",
      compId: "gain-muscle",
      url: "#premium",
      type: "premium",
    },
    {
      id: 3,
      title: ["VIP"],
      subTitle: "Become a VIP member",
      data: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odit nemo delectus dolorum, excepturi dolor perferendis tempora at et! Hic modi iste tenetur alias ipsum labore rem quia eligendi, aperiam tempora. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus possimus accusamus dolore assumenda, tempore asperiores expedita fugiat beatae magnam culpa laudantium autem non in officia inventore quasi distinctio doloremque eligendi!",
      price: "$97",
      priceTitle: "Buy now",
      alt: "woman-clicking-photo",
      side: "md:flex-row",
      color: "blue",
      compId: "body-recomposition",
      url: "#vip",
      type: "vip",
    },
  ]

  return (
    <>
      <div className="my-4 mt-0 bg-gray-lightest flex flex-col justify-center items-center">
        <Container customPad padding="md:px-4 lg:px-8 2xl:px-0">
          {/* with map */}
          {pricingPlans.map(plan => (
            <div
              id={plan.compId}
              className={` w-full flex flex-col justify-center items-center md:my-16 md:rounded-2xl md:p-10 md:relative md:justify-start md:h-117.5 md:mb-36 lg:mt-0 lg:mb-72 ${
                plan.color === "blue" ? "bg-blue300 " : "bg-white"
              } `}
            >
              <div className=" flex justify-between mb-4 px-4 pt-8 w-full md:p-0">
                <div>
                  <span
                    className={`font-bold text-xl ${
                      plan.color === "blue" ? "text-white" : "text-black"
                    }`}
                  >
                    {plan.title[0]}
                    <span className="text-blue300  font-bold text-xl ">
                      {plan.title[1]}
                    </span>
                  </span>
                  <div
                    className={` text-xs font-normal  md:mt-2 ${
                      plan.color === "blue"
                        ? "text-white text-opacity-70"
                        : "text-gray-light2 text-opacity-40"
                    } `}
                  >
                    {plan.subTitle}
                  </div>
                </div>
                <div className="flex justify-center items-start ">
                  <button
                    className={`rounded-full text-xs px-3 py-1 text-opacity-90  md:rounded-lg  md:font-semibold transition-opacity duration-100 hover:opacity-90 ${
                      plan.color === "blue"
                        ? "bg-white text-blue300 "
                        : ` text-white ${styles.plan2join}`
                    } `}
                    // onClick={() => handlePlan(plan.type)}
                  >
                    Join now
                  </button>
                </div>
              </div>
              <div
                className={
                  "flex flex-col px-4 justify-center items-center md:px-0 md:flex-row md:justify-between " +
                  plan.side
                }
              >
                {plan.id === 1 ? (
                  <div
                    className={`${styles.plan_img} shadow-shadow1 max-w-full w-full md:max-w-72 lg:max-w-82 md:relative `}
                  >
                    <Img1 />
                  </div>
                ) : plan.id === 2 ? (
                  <div
                    className={`${styles.plan_img} shadow-shadow1 max-w-full w-full md:max-w-72 lg:max-w-82 md:relative `}
                  >
                    <Img2 />
                  </div>
                ) : (
                  <div
                    className={`${styles.plan_img} shadow-shadow1 max-w-full w-full md:max-w-72 lg:max-w-82 md:relative `}
                  >
                    <Img3 />
                  </div>
                )}

                <div className="md:flex md:flex-col md: justify-center md:w-1/2 lg:w-3/5 ">
                  <p
                    className={`text-xs my-6 font-normal md:my-0 md:mx-0 ${
                      plan.color === "blue"
                        ? "text-white"
                        : "text-gray-light2  text-opacity-40"
                    }`}
                  >
                    {plan.data}
                  </p>

                  {/* <Link to={plan.url}> */}
                  <button
                    className={`flex py-4 px-0 my-8 h-11 mx-auto justify-evenly items-center rounded-2xl w-full md:w-60 md:h-9 md:mx-0 md:my-8 md:rounded-lg lg:h-11 lg:w-64 transition-opacity duration-100 hover:opacity-90 ${
                      plan.color === "blue" ? "bg-white" : styles.plan2BuyButton
                    }`}
                    // onClick={() => handlePlan(plan.type)}
                  >
                    <div
                      className={`text-xs font-medium text-opacity-70 md:text-opacity-100  md:font-semibold ${
                        plan.color === "blue" ? "text-blue300 " : "text-white"
                      }`}
                    >
                      {plan.price}
                    </div>
                    <div
                      className={` text-base leading-5 font-semibold  md:text-sm ${
                        plan.color === "blue" ? "text-blue300 " : "text-white"
                      } `}
                    >
                      {plan.priceTitle}
                    </div>
                    <div
                      className={
                        "flex justify-center items-center " + styles.plan2Arrow
                      }
                    >
                      {plan.color === "blue" ? <BlueArrow /> : <Arrow />}
                    </div>
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </div>
          ))}
        </Container>
      </div>
    </>
  )
}
