import ExerciseImage from "../images/test.jpg"
import PrimaryImage from "../images/woman.png"
import BlogPostImage from "../images/blog-sample.png"

import Logo from "../images/estee7 1.png"

import recipe1 from "../images/recipe/recipe1.png"
import recipe2 from "../images/recipe/recipe2.png"
import recipe3 from "../images/recipe/recipe3.png"
import Img from "../images/img-pdf.png"
import smImg from "../images/img-pdf-sm.png"

export default {
  primary: [
    {
      id: 1,
      title: "Standard",
      description: "Process of losing weight starts\n with a great plan",
      price: "96.99",
      image: PrimaryImage,
      to: "/plans#standard",
    },
    {
      id: 2,
      title: "Premium",
      description: "Process of building muscle starts\n with a great plan",
      price: "96.99",
      image: PrimaryImage,
      to: "/plans#premium",
    },
    {
      id: 3,
      title: "VIP",
      description: "Process of recomp starts\n with a great plan",
      price: "96.99",
      image: PrimaryImage,
      to: "/plans#vip",
    },
  ],
  secondary: [
    {
      id: 1,
      title: "Podcast &\n chill - title 1",
      description: "Short description for this podcast...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/podcast",
    },
    {
      id: 2,
      title: "Podcast &\n chill - title 2",
      description: "Short description for this podcast...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/podcast",
    },
    {
      id: 3,
      title: "Podcast &\n chill - title 3",
      description: "Short description for this podcast...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/podcast",
    },
    {
      id: 4,
      title: "Podcast &\n chill - title 4",
      description: "Short description for this podcast...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/podcast",
    },
  ],
  secondary2: [
    {
      id: 1,
      title: "Miya Meditation - title 1",
      description: "Short description for this meditation...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/meditations",
    },
    {
      id: 2,
      title: "Miya Meditation - title 2",
      description: "Short description for this meditation...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/meditations",
    },
    {
      id: 3,
      title: "Miya Meditation - title 3",
      description: "Short description for this meditation...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/meditations",
    },
    {
      id: 4,
      title: "Miya Meditation - title 4",
      description: "Short description for this meditation...",
      time: "7:00 PM - 19/08/2021",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/meditations",
    },
  ],
  podcast: [
    {
      id: 1,
      title: "Miya’s latest podcast 1",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 2,
      title: "Miya’s latest podcast 2",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 3,
      title: "Miya’s latest podcast 3",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 4,
      title: "Miya’s latest podcast 4",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 5,
      title: "Miya’s latest podcast 5",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 6,
      title: "Miya’s latest podcast 6",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 7,
      title: "Miya’s latest podcast 7",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 8,
      title: "Miya’s latest podcast 8",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
    {
      id: 9,
      title: "Miya’s latest podcast 9",
      description: "Description for podcast",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
      to: "/",
    },
  ],
  dashboard: {
    list: [
      {
        id: 1,
        date: 21,
        day_name: "mon",
        meal_plan: [
          {
            id: 111,
            title: "Pasta",
            category: "Breakfast",
            description: "Process of losing weight starts with a great plan",
            unit: 130,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 222,
            title: "Chicken soup",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 210,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 333,
            title: "Pizza",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 410,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 444,
            title: "Chicken Roast",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 110,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 678,
            title: "Chicken Roast 2",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 110,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 234,
            title: "Chicken Roast 3",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 110,
            image: BlogPostImage,
            url: "/",
          },
        ],
      },
      {
        id: 2,
        date: 22,
        day_name: "tue",
        meal_plan: [
          {
            id: 101,
            title: "Omelet",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 95,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 202,
            title: "Fried Rice",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 210,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 303,
            title: "Beef",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 410,
            image: BlogPostImage,
            url: "/",
          },
        ],
      },
      {
        id: 3,
        date: 23,
        day_name: "wed",
        meal_plan: [
          {
            id: 155,
            title: "Pulses",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 310,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 285,
            title: "Salad",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 210,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 309,
            title: "Zinger Burger",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 410,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 432,
            title: "Chicken Pizza",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 500,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 768,
            title: "Chicken Pizza 1",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 500,
            image: BlogPostImage,
            url: "/",
          },
        ],
      },
      {
        id: 4,
        date: 24,
        day_name: "thu",
        meal_plan: [
          {
            id: 123,
            title: "Fish Fry",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 250,
            image: BlogPostImage,
            url: "/",
          },
          // {
          //   id: 256,
          //   title: "Rice Bryani",
          //   description: "Process of losing weight starts with a great plan",
          //   category: "Breakfast",
          //   unit: 210,
          //   image: BlogPostImage,
          //   url: "/",
          // },
          // {
          //   id: 345,
          //   title: "Desi Polou",
          //   description: "Process of losing weight starts with a great plan",
          //   category: "Breakfast",
          //   unit: 410,
          //   image: BlogPostImage,
          //   url: "/",
          // },
          // {
          //   id: 456,
          //   title: "Toast Bread",
          //   description: "Process of losing weight starts with a great plan",
          //   category: "Breakfast",
          //   unit: 110,
          //   image: BlogPostImage,
          //   url: "/",
          // },
        ],
      },
      {
        id: 5,
        date: 25,
        day_name: "fir",
        meal_plan: [
          {
            id: 178,
            title: "Shawarma",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 310,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 267,
            title: "Tower Burger",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 433,
            image: BlogPostImage,
            url: "/",
          },
          // {
          //   id: 389,
          //   title: "Smoke Pizza",
          //   description: "Process of losing weight starts with a great plan",
          //   category: "Lunch",
          //   unit: 410,
          //   image: BlogPostImage,
          //   url: "/",
          // },
          // {
          //   id: 423,
          //   title: "Black Coffe",
          //   description: "Process of losing weight starts with a great plan",
          //   category: "Lunch",
          //   unit: 110,
          //   image: BlogPostImage,
          //   url: "/",
          // },
        ],
      },
      {
        id: 6,
        date: 26,
        day_name: "sat",
        meal_plan: [
          {
            id: 198,
            title: "Chainess Dish",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 280,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 299,
            title: "Noodles",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 210,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 376,
            title: "Ricebin",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 410,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 465,
            title: "Veges",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 110,
            image: BlogPostImage,
            url: "/",
          },
        ],
      },
      {
        id: 7,
        date: 27,
        day_name: "sun",
        meal_plan: [
          {
            id: 177,
            title: "Sweet Dish",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 177,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 276,
            title: "Chicken Burger",
            description: "Process of losing weight starts with a great plan",
            category: "Breakfast",
            unit: 210,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 311,
            title: "Jani Burger",
            description: "Process of losing weight starts with a great plan",
            category: "Lunch",
            unit: 410,
            image: BlogPostImage,
            url: "/",
          },
          {
            id: 411,
            title: "Fried Egg",
            description: "Process of losing weight starts with a great plan",
            category: "Dinner",
            unit: 110,
            image: BlogPostImage,
            url: "/",
          },
        ],
      },
    ],
  },
  latest_videos: {
    title1: "Latest",
    title2: "Videos",
    url: "/",
    buttonText: "See More",
    text: "See our large collection",
    list: [
      {
        id: 1,
        title: "Consectetur maecenas 1",
        tags: ["Exercise", "Weight loss"],
        url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        preview:
          "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
        to: "/",
      },
      {
        id: 2,
        title: "Consectetur maecenas 2",
        tags: ["Exercise", "Weight loss"],
        url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        preview:
          "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
        to: "/",
      },
      {
        id: 3,
        title: "Consectetur maecenas 3",
        tags: ["Exercise", "Weight loss"],
        url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
        preview:
          "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg",
        to: "/",
      },
    ],
  },
  exercise: [
    {
      id: 1,
      title: "Exercise #1",
      tag: "Cardio",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
    {
      id: 2,
      title: "Exercise #2",
      tag: "Cardio",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
    {
      id: 3,
      title: "Exercise #3",
      tag: "Cardio",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
    {
      id: 4,
      title: "Exercise #4",
      tag: "Cardio",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
  ],
  videos: [
    {
      id: 1,
      title: "Video #1",
      tag: "Mindset",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
    {
      id: 2,
      title: "Video #2",
      tag: "Psychology",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
    {
      id: 3,
      title: "Video #3",
      tag: "Psychology",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
    {
      id: 4,
      title: "Video #4",
      tag: "Psychology",
      image: ExerciseImage,
      to: "/singlevideo",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nisl, sed mi cras turpis pulvinar sit amet, consectetur adipiscing elit. Nisl, sed micras turpis pulvinar",
      url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      preview: "https://video-react.js.org/assets/poster.png",
    },
  ],
  mediations: [
    {
      id: 1,
      title: "Last meditation 1",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 2,
      title: "Last meditation 2",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 3,
      title: "Last meditation 3",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 4,
      title: "Last meditation 4",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 5,
      title: "Last meditation 5",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 6,
      title: "Last meditation 6",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 7,
      title: "Last meditation 7",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 8,
      title: "Last meditation 8",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 9,
      title: "Last meditation 9",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 10,
      title: "Last meditation 10",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 11,
      title: "Last meditation 11",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 12,
      title: "Last meditation 12",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 13,
      title: "Last meditation 13",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
    {
      id: 14,
      title: "Last meditation 14",
      description: "Meditation description",
      url: "https://www.djyogendra.com/upload_file/114/Kina%20-%20Get%20you%20the%20moon%209D%20AUDIO%20-with%20rain-%20DjYogendra.Com.mp3",
    },
  ],
  video_library: {
    title1: "Видео",
    title2: "библиотека",
    url: "/",
    buttonText: "See More",
    text: "Наша коллекция",
    list: [
      {
        id: 1,
        title: "Enim ipsum a a ut. 1",
        tags: ["Exercise", "Weight loss", "Cardio"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 2,
        title: "Enim ipsum a a ut. 2",
        tags: ["Exercise", "Weight loss", "Cardio"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 3,
        title: "Enim ipsum a a ut. 3",
        tags: ["Exercise", "Weight loss", "Cardio"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 4,
        title: "Enim ipsum a a ut. 4",
        tags: ["Exercise", "Weight loss", "Psychology"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 5,
        title: "Enim ipsum a a ut. 5",
        tags: ["Exercise", "Weight loss", "Psychology"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 6,
        title: "Enim ipsum a a ut. 6",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 7,
        title: "Enim ipsum a a ut. 7",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 8,
        title: "Enim ipsum a a ut. 8",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 9,
        title: "Enim ipsum a a ut. 9",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 10,
        title: "Enim ipsum a a ut. 10",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 11,
        title: "Enim ipsum a a ut. 11",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
      {
        id: 12,
        title: "Enim ipsum a a ut. 12",
        tags: ["Exercise", "Weight loss", "Others"],
        url: "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4",
        preview: "https://video-react.js.org/assets/poster.png",
        to: "/singlevideo",
      },
    ],
  },
  blog_post: {
    id: 1,
    title: "Blog Post Title",
    overview:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placerat sit enim ultricies egestas sit amet, erat turpis. Egestas faucibus a etiam vestibulum malesuada. Laoreet egestas odio enim et arcu lectus tempus. Sed eget eget vitae sapien nisl. Lacus, auctor adipiscing morbi velit, pellentesque.",
    image: BlogPostImage,
    publish: "5:00 PM - 28/07/2021",
    url: "/",
    content: [
      {
        title: "This post’s sub-titile",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid.\n\n Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit ",
      },
      {
        title: "This post’s sub-titile 2",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid.",
      },
    ],
    recent_post: [
      {
        id: 1,
        title: "Blog Post Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam praesent suspendisse consectetur adipiscing elit. Nullam praesent suspendisse",
        image: BlogPostImage,
        date: "5:00 PM - 29/07/2021",
        url: "/",
      },
      {
        id: 2,
        title: "Another Blog Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam praesent suspendisse consectetur adipiscing elit. Nullam praesent suspendisse",
        image: BlogPostImage,
        date: "5:00 PM - 29/07/2021",
        url: "/",
      },
      {
        id: 3,
        title: "Blog Post Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam praesent suspendisse consectetur adipiscing elit. Nullam praesent suspendisse",
        image: BlogPostImage,
        date: "5:00 PM - 29/07/2021",
        url: "/",
      },
      {
        id: 4,
        title: "Another Blog Title",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam praesent suspendisse consectetur adipiscing elit. Nullam praesent suspendisse",
        image: BlogPostImage,
        date: "5:00 PM - 29/07/2021",
        url: "/",
      },
    ],
  },
  meal_plan: [
    {
      id: 303,
      title: "Beef",
      description: "Process of losing weight starts with a great plan",
      category: "Dinner",
      unit: 410,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 404,
      title: "Beef Steak",
      description: "Process of losing weight starts with a great plan",
      category: "Breakfast",
      unit: 110,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 155,
      title: "Pulses",
      description: "Process of losing weight starts with a great plan",
      category: "Lunch",
      unit: 310,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 309,
      title: "Zinger Burger",
      description: "Process of losing weight starts with a great plan",
      category: "Breakfast",
      unit: 410,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 285,
      title: "Salad",
      description: "Process of losing weight starts with a great plan",
      category: "Lunch",
      unit: 210,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 345,
      title: "Desi Polou",
      description: "Process of losing weight starts with a great plan",
      category: "Breakfast",
      unit: 410,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 178,
      title: "Shawarma",
      description: "Process of losing weight starts with a great plan",
      category: "Lunch",
      unit: 310,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 423,
      title: "Black Coffe",
      description: "Process of losing weight starts with a great plan",
      category: "Lunch",
      unit: 110,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 376,
      title: "Ricebin",
      description: "Process of losing weight starts with a great plan",
      category: "Dinner",
      unit: 410,
      image: BlogPostImage,
      url: "/",
    },
    {
      id: 465,
      title: "Veges",
      description: "Process of losing weight starts with a great plan",
      category: "Dinner",
      unit: 110,
      image: BlogPostImage,
      url: "/",
    },
  ],
  single_video: {
    id: 1,
    title: "This Exercise Video’s Title Here",
    overview:
      "Video description here sit amet, consectetur adipiscing elit. Nullam praesent suspendisse dignissim pellentesque arcu in velit, mauris. Ipsum viverra velit euismod ultricies facilisis nullam magna phasellus posuere. Morbi tincidunt et, quis.  Ipsum viverra velit euismod ultricies facilisis nullam magna ",
    video_url:
      "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
    video_preview: "https://video-react.js.org/assets/poster.png",
    tags: ["Exercise", "Weight loss", "Other Tags"],
    url: "/",
    download_list: [
      {
        id: 1,
        title: "Downloadable #1",
        description: " Click to download this file",
        url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
      },
      {
        id: 2,
        title: "Downloadable #2",
        description: " Click to download this file",
        url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
      },
      {
        id: 3,
        title: "Downloadable #3",
        description: " Click to download this file",
        url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
      },
      {
        id: 4,
        title: "Downloadable #4",
        description: " Click to download this file",
        url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
      },
    ],
  },
  week_days: [
    {
      id: 0,
      day: 21,
      day_name: "mon",
    },
    {
      id: 1,
      day: 22,
      day_name: "tue",
    },
    {
      id: 2,
      day: 23,
      day_name: "wed",
    },
    {
      id: 3,
      day: 24,
      day_name: "thu",
    },
    {
      id: 4,
      day: 25,
      day_name: "fri",
    },
    {
      id: 5,
      day: 26,
      day_name: "sat",
    },
    {
      id: 6,
      day: 27,
      day_name: "sun",
    },
  ],
  test: [{ name: "just to push the commit to gatsy production" }],
  shopping_list: [
    { id: 1, name: "Spinach", quantity: 2, unit: "kg" },
    { id: 2, name: "Spinach", quantity: 2, unit: "kg" },
    { id: 3, name: "Spinach", quantity: 8, unit: "kg" },
    { id: 4, name: "Spinach", quantity: 3, unit: "L" },
    { id: 5, name: "Spinach", quantity: 2, unit: "kg" },
    { id: 6, name: "Spinach", quantity: 5, unit: "L" },
    { id: 7, name: "Spinach", quantity: 2, unit: "kg" },
    { id: 8, name: "Spinach", quantity: 3, unit: "kg" },
    { id: 9, name: "Spinach", quantity: 2, unit: "kg" },
    { id: 10, name: "Spinach", quantity: 8, unit: "L" },
  ],
  recipes: [
    {
      id: 303,
      title: "Chicken Pizza",
      description:
        "To make chicken pizza, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      unit: 410,
      time: "05:00 PM - 29/07/2021",
      image: recipe1,
      alt: "recipe1",
      to: "/",
      tags: ["Fun", "Weight loss", "Blog", "Vegan"],
      category: "Dinner",
    },
    {
      category: "Breakfast",
      id: 404,
      unit: 110,
      title: "Name of this recipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe2,
      alt: "recipe2",
      to: "/",
      tags: ["exercise", "Weight loss", "Blog", "Vegan"],
    },
    {
      category: "Lunch",
      id: 155,
      unit: 310,
      title: "Name of this recipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe3,
      alt: "recipe3",
      to: "/",
      tags: ["exercise", "Weight loss", "News", "Vegan"],
    },
    {
      category: "Breakfast",
      id: 309,
      unit: 410,
      title: "Name of this recipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe2,
      alt: "recipe4",
      to: "/",
      tags: ["exercise", "Weight loss", "Promotion", "Vegan"],
    },
    {
      category: "Lunch",
      id: 285,
      unit: 210,
      title: "Name of this recipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe3,
      alt: "recipe5",
      to: "/",
      tags: ["exercise", "Weight loss", "Promotion", "Vegan", "Pescetarian"],
    },
    {
      category: "Breakfast",
      id: 345,
      unit: 410,
      title: "Name of this recipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe1,
      alt: "recipe6",
      to: "/",
      tags: ["exercise", "Weight loss", "Promotion", "Vegan", "Pescetarian"],
    },
    {
      category: "Lunch",
      id: 178,
      unit: 310,
      title: "Name of this recipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe3,
      alt: "recipe7",
      to: "/",
      tags: ["exercise", "Weight loss", "Nutrition", "Vegetarian"],
    },
    {
      category: "Lunch",
      id: 423,
      unit: 110,
      title: "Recipe 8",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe1,
      alt: "recipe8",
      to: "/",
      tags: ["exercise", "gain", "Blog", "Vegetarian"],
    },
    {
      category: "Dinner",
      id: 376,
      unit: 410,
      title: "Blog title 9",
      description:
        "Shahroon ipsum dolor sit amet, consectetur adipiscing elit.Nullam praesent suspendisse dignissim pellentesque arcu invelit, mauris. Ipsum viverra velit euismod ultricies facilisisnullam magna phasellus posuere. Morbi tincidunt et, quis...",
      time: "05:00 PM - 29/07/2021",
      image: recipe2,
      alt: "recipe9",
      to: "/",
      tags: ["skinny", "Weight loss", "Blog", "Vegetarian", "Standard"],
    },
  ],
  recipe_post: {
    id: 1,
    title: "Name of this recipe",
    overview:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Placeratsit enim ultricies egestas sit amet, erat turpis. Egestas faucibus a etiam vestibulum malesuada. Laoreet egestas odio enim et arculectus tempus. Sed eget eget vitae sapien nisl. Lacus, auctor adipiscing morbi velit, pellentesque.",
    image: BlogPostImage,
    publish: "5:00 PM - 28/07/2021",
    url: "/",
    content: [
      {
        title: "How to make this recipe",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid.\n\n Reprehenderit, quia. Quo neque error repudiandae fuga? Ipsa laudantium molestias eos sapiente officiis modi at sunt excepturi expedita sint? Sed quibusdam recusandae alias error harum maxime adipisci amet laborum. Perspiciatis minima nesciunt dolorem! Officiis iure rerum voluptates a cumque velit ",
      },
      {
        title: "Additional info about this recipe",
        description:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid.",
      },
    ],
    ingredients: [
      {
        id: 1,
        name: "Tomatos",
        Quantity: 2,
        units: "kg",
      },
      {
        id: 2,
        name: "Patatos",
        Quantity: 5,
        units: "kg",
      },
      {
        id: 3,
        name: "Carrots",
        Quantity: 3,
        units: "kg",
      },
      {
        id: 4,
        name: "Spinach",
        Quantity: 2,
        units: "kg",
      },
      {
        id: 5,
        name: "Radish",
        Quantity: 5,
        units: "kg",
      },
      {
        id: 6,
        name: "Mustard",
        Quantity: 8,
        units: "kg",
      },
      {
        id: 7,
        name: "Lettuce",
        Quantity: 2,
        units: "kg",
      },
      {
        id: 8,
        name: "Milk",
        Quantity: 12,
        units: "ml",
      },
      {
        id: 9,
        name: "Water",
        Quantity: 30,
        units: "ml",
      },
    ],
    steps: [
      "Lorem ipsum dolor sit amet consectetur adipisicing elit.Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat, odit, tenetur error, harum nesciunt ipsum debitis quas aliquid.",
      "Second String",
      "Third String",
    ],
  },
  pdf_download: [
    {
      id: 1,
      title1: "Main pdf",
      title2: "download title",
      description:
        "Some description for the downloadable files here. Lorem ipsum dolor sit amet,",
      image_sm: smImg,
      image: Img,
      url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
    },
    {
      id: 2,
      title1: "Main pdf",
      title2: "download title",
      description:
        "Some description for the downloadable files here. Lorem ipsum dolor sit amet,",
      image_sm: smImg,
      image: Img,
      url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
    },
    {
      id: 3,
      title1: "Main pdf",
      title2: "download title",
      description:
        "Some description for the downloadable files here. Lorem ipsum dolor sit amet,",
      image_sm: smImg,
      image: Img,
      url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
    },
    {
      id: 4,
      title1: "Main pdf",
      title2: "download title",
      description:
        "Some description for the downloadable files here. Lorem ipsum dolor sit amet,",
      image_sm: smImg,
      image: Img,
      url: "https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf",
    },
  ],
  video_tracks: {
    beginner: [
      {
        orignal: {
          id: 1,
          url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          preview: Logo,
          title: "Double Pulse Squat Jump",
        },
        alternative: {
          id: 11,
          url: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          preview: Logo,
          title: "Double Pulse Squat Jump alter",
        },
      },
      {
        orignal: {
          id: 2,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Push Up",
        },
        alternative: {
          id: 22,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Push Up alter",
        },
      },
      {
        orignal: {
          id: 3,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Pull Up",
        },
        alternative: {
          id: 33,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Pull Up alter",
        },
      },
      {
        orignal: {
          id: 4,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
          preview: Logo,
          title: "BenchPress",
        },
        alternative: {
          id: 44,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
          preview: Logo,
          title: "BenchPress alter",
        },
      },
    ],
    intermediate: [
      {
        orignal: {
          id: 1,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Double Burfee jump",
        },
        alternative: {
          id: 11,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Double Burfee jump alter",
        },
      },
      {
        orignal: {
          id: 2,
          url: "http://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          preview: Logo,
          title: "Squats",
        },
        alternative: {
          id: 22,
          url: "http://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          preview: Logo,
          title: "Squats alter",
        },
      },
      {
        orignal: {
          id: 3,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
          preview: Logo,
          title: "Bench Press",
        },
        alternative: {
          id: 33,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
          preview: Logo,
          title: "Bench Press alter",
        },
      },
      {
        orignal: {
          id: 4,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
          preview: Logo,
          title: "DeadLift",
        },
        alternative: {
          id: 44,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
          preview: Logo,
          title: "DeadLift alter",
        },
      },
    ],
    advanced: [
      {
        orignal: {
          id: 1,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
          preview: Logo,
          title: "Jumping Jack",
        },
        alternative: {
          id: 11,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
          preview: Logo,
          title: "Jumping Jack alter",
        },
      },
      {
        orignal: {
          id: 2,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
          preview: Logo,
          title: "Wall Sit",
        },
        alternative: {
          id: 22,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4",
          preview: Logo,
          title: "Wall Sit alter",
        },
      },
      {
        orignal: {
          id: 3,
          url: "http://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          preview: Logo,
          title: "Burpees",
        },
        alternative: {
          id: 33,
          url: "http://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
          preview: Logo,
          title: "Burpees alter",
        },
      },
      {
        orignal: {
          id: 4,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Crunches",
        },
        alternative: {
          id: 44,
          url: "https://commondescriptionstorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4",
          preview: Logo,
          title: "Crunches alter",
        },
      },
    ],
  },
  workout_new: [
    {
      node: {
        id: "-69f5fe44-e87c-572b-8466-0cb578d18e44",
        title: "day 1 - IG",
        day: 1,
        workoutLocation: {
          name: "Gym",
          nameRU: "Для зала",
        },
        workoutLevel: {
          nameRU: "Продвинутый",
          name: "Intermediate",
        },
        exercises: [
          {
            name: "Test day 1, 1",
            nameRU: "Test day 1, 1",
            exercise: {
              main_exercise: {
                name: "Push up int",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Push up alt int",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
          {
            name: "Test day 1, 2",
            nameRU: "Test day 1, 2",
            exercise: {
              main_exercise: {
                name: "Push down int",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Push down alt int",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
          {
            name: "Test day 1, 3",
            nameRU: "Test day 1, 3",
            exercise: {
              main_exercise: {
                name: "Pull up int",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Pull up alt int",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
        ],
      },
    },
    {
      node: {
        id: "-69f5fe44-e87c-572b-8466-0cb578d18e44",
        title: "day 1 - IG",
        day: 1,
        workoutLocation: {
          name: "Gym",
          nameRU: "Для зала",
        },
        workoutLevel: {
          nameRU: "Опытный",
          name: "Advanced",
        },
        exercises: [
          {
            name: "Test day 1, 1",
            nameRU: "Test day 1, 1",
            exercise: {
              main_exercise: {
                name: "Push up adv",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Push up alt adv",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
          {
            name: "Test day 1, 2",
            nameRU: "Test day 1, 2",
            exercise: {
              main_exercise: {
                name: "Push down adv",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Push down alt adv",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
          {
            name: "Test day 1, 3",
            nameRU: "Test day 1, 3",
            exercise: {
              main_exercise: {
                name: "Pull up adv",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Pull up alt adv",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
        ],
      },
    },
    {
      node: {
        id: "-69f5fe44-e87c-572b-8466-0cb578d18e44",
        title: "day 1 - BH",
        day: 1,
        workoutLocation: {
          name: "Home",
          nameRU: "Для дома",
        },
        workoutLevel: {
          nameRU: "Новичок",
          name: "Beginner",
        },
        exercises: [
          {
            name: "Test day 1, 1",
            nameRU: "Test day 1, 1",
            exercise: {
              main_exercise: {
                name: "Push up",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Incline push up",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
          {
            name: "Test day 1, 2",
            nameRU: "Test day 1, 2",
            exercise: {
              main_exercise: {
                name: "Push down beg",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Push down alt beg",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
          {
            name: "Test day 1, 3",
            nameRU: "Test day 1, 3",
            exercise: {
              main_exercise: {
                name: "Pull up beg",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
              alternative_exercise: {
                name: "Pull up alt beg",
                sets: 3,
                reps: 20,
                pause: 2,
                url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              },
            },
          },
        ],
      },
    },
  ],
}
