import React, { useState, useRef, useEffect } from "react"
import { FaPause, FaExpandAlt } from "react-icons/fa"
import { BsArrowsAngleExpand } from "react-icons/bs"
// import screenfull from "screenfull"
import { FullScreen, useFullScreenHandle } from "react-full-screen"

// components
import WorkoutPlayer from "./WorkoutPlayer"
import Container from "../Common/Container/Container"
import Dropdown from "../Common/Modal/Dropdown"
import VideoPlayer from "../LatestVideos/VideoPlayer"

import "./workout.css"

import Logo from "../../images/estee7 1.png"
import Down from "../../images/VectorDown.png"
import Up from "../../images/VectorUp.png"

// utils
import { getCurrentTime } from "../../utils/helper"

let interval = null

const types = [
  { id: 1, title: "Beginner", titleRU: "Новичок" },
  { id: 2, title: "Intermediate", titleRU: "Продвинутый" },
  { id: 3, title: "Advanced", titleRU: "Опытный" },
]

const locations = [
  { id: 1, title: "Home", titleRU: "Для дома" },
  { id: 2, title: "Gym", titleRU: "Для зала " },
]

export default function Workout({ workouts, weekDay, userData }) {
  const player = useRef()
  const testRef = useRef()

  const handle = useFullScreenHandle()

  const [list, setList] = useState([])
  const [isPlaying, setIsPlaying] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const [currTrack, setCurrTrack] = useState({})
  const [currTime, setCurrTime] = useState("00:00")
  const [currWorkouts, setCurrWorkouts] = useState([])
  const [temp, setTemp] = useState([])

  const [value, setValue] = useState()
  const [location, setLocation] = useState()
  const [isDisable, setIsDisable] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    let localLoc = locations[0]
    let localType = types[0]

    if (localStorage.getItem("type")) {
      let parse = JSON.parse(localStorage.getItem("type"))
      localType = parse
    }

    if (localStorage.getItem("location")) {
      let parse = JSON.parse(localStorage.getItem("location"))
      localLoc = parse
    }

    setValue(localType)
    setLocation(localLoc)

    if (workouts && userData) {
      let workoutFilter = workouts.filter(item => item.day === weekDay + 1)

      let workout = workoutFilter.filter(
        item =>
          item.workoutLocation.name === localLoc.title &&
          item.workoutLevel.name === localType.title
      )

      // check if exercistList has cardio as true
      if (workout.length > 0) {
        let exercises = []
        workout[0].allExercises.exerciseList.forEach(exercise => {
          if (exercise.isCardio && userData.goal !== -20) return

          exercises.push(exercise)
        })

        setTemp(workoutFilter)
        setCurrWorkouts([
          {
            ...workout[0],
            allExercises: {
              exerciseList: exercises,
            },
          },
        ])
      } else {
        setTemp(workoutFilter)
        setCurrWorkouts(workout)
      }
    }
  }, [workouts, weekDay, userData])

  useEffect(() => {
    interval = setInterval(() => {
      if (isPlaying) {
        let timer = Math.round(
          player.current.getDuration() - player.current.getCurrentTime()
        )
        setCurrTime(getCurrentTime(timer))
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [isPlaying])

  useEffect(() => {
    if (currWorkouts && currWorkouts.length > 0) {
      let current = currWorkouts[0].allExercises.exerciseList[0].exerciseMain
      let alt = currWorkouts[0].allExercises.exerciseList[0].exerciseAlt

      setCurrTrack({
        index: 0,
        data: current,
        type: "main_exercise",
      })

      if (!alt) setIsDisable(true)
    }
  }, [currWorkouts])

  // here we added [isToggled, setIsToggled] as a second parameter
  const toggle = () => {
    setIsPlaying(!isPlaying)
  }

  const onNext = () => {
    if (
      currTrack.index <
      currWorkouts[0].allExercises.exerciseList.length - 1
    ) {
      setIsDisable(false)

      let track =
        currWorkouts[0].allExercises.exerciseList[currTrack.index + 1]
          .exerciseMain

      let alt =
        currWorkouts[0].allExercises.exerciseList[currTrack.index + 1]
          .exerciseAlt

      setCurrTrack({
        index: currTrack.index + 1,
        data: track,
        type: "main_exercise",
      })

      setIsPlaying(false)
      setCurrTime("00:00")

      if (!alt) setIsDisable(true)
    }
  }

  const onPrevious = () => {
    if (currTrack.index > 0) {
      setIsDisable(false)

      let track =
        currWorkouts[0].allExercises.exerciseList[currTrack.index - 1]
          .exerciseMain

      let alt =
        currWorkouts[0].allExercises.exerciseList[currTrack.index - 1]
          .exerciseAlt

      setCurrTrack({
        index: currTrack.index - 1,
        data: track,
        type: "main_exercise",
      })

      setIsPlaying(false)
      setCurrTime("00:00")

      if (!alt) setIsDisable(true)
    }
  }

  const handleAlternative = () => {
    if (isDisable) return

    if (currTrack.type === "main_exercise") {
      let track =
        currWorkouts[0].allExercises.exerciseList[currTrack.index].exerciseAlt
      setCurrTrack({
        ...currTrack,
        data: track,
        type: "alternative_exercise",
      })
    } else {
      let track =
        currWorkouts[0].allExercises.exerciseList[currTrack.index].exerciseMain
      setCurrTrack({
        ...currTrack,
        data: track,
        type: "main_exercise",
      })
    }
    setIsPlaying(false)
    setCurrTime("00:00")
  }

  const onTypeChange = _data => {
    saveType(_data)
    setValue(_data)

    if (_data.id === 3) {
      onLocationChange(locations[1])
      return
    }

    let workout = temp.filter(
      item =>
        item.workoutLocation.name === location.title &&
        item.workoutLevel.name === _data.title
    )

    if (workout && workout.length > 0) {
      // check if exercistList has cardio as true
      let exercises = []
      workout[0].allExercises.exerciseList.forEach(exercise => {
        if (exercise.isCardio && userData.goal !== -20) return

        exercises.push(exercise)
      })

      setCurrWorkouts([
        {
          ...workout[0],
          allExercises: {
            exerciseList: exercises,
          },
        },
      ])
    } else {
      setCurrWorkouts([])
    }

    setIsPlaying(false)
    setCurrTime("00:00")
    setIsDisable(false)
  }

  const onLocationChange = _data => {
    saveLocation(_data)
    setLocation(_data)

    let workout = temp.filter(
      item =>
        item.workoutLocation.name === _data.title &&
        item.workoutLevel.name === value.title
    )

    if (workout && workout.length > 0) {
      let exercises = []
      workout[0].allExercises.exerciseList.forEach(exercise => {
        if (exercise.isCardio && userData.goal !== -20) return

        exercises.push(exercise)
      })

      setCurrWorkouts([
        {
          ...workout[0],
          allExercises: {
            exerciseList: exercises,
          },
        },
      ])
    } else {
      setCurrWorkouts([])
    }

    setIsPlaying(false)
    setCurrTime("00:00")
    setIsDisable(false)
  }

  const saveLocation = _location => {
    localStorage.setItem("location", JSON.stringify(_location))
  }

  const saveType = _type => {
    localStorage.setItem("type", JSON.stringify(_type))
  }

  const printMessage = () => {
    if (currTrack.type === "main_exercise") return "no exercise"
    else return "no alternative exercise"
  }

  const currentDay = () => {
    let curr = weekDay + 1

    if (curr < 10) return "0" + curr
    return curr
  }

  const handleFullScreen = () => {
    if (!isFullScreen) {
      setIsFullScreen(true)
      handle.enter()
    } else {
      // setIsFullScreen(false)
      handle.exit()
    }
  }

  const getMaxDay = () => {
    if (workouts) {
      let days = workouts.map(item => item.day)
      days = [...new Set(days)]

      return Math.max(...days)
    } else {
      return 0
    }
  }

  return (
    <Container>
      {weekDay > getMaxDay() ? (
        <p className="text-base xl:text-xl text-primary my-4 mx-4 sm:mx-0">
          Тренировочные дни завершены, но вы можете вернуться к прошедшим
          тренировкам, нажав на нужный день (1,2,3..).
        </p>
      ) : (
        <div>
          <div className="mb-4 sm:max-w-xs mx-4 sm:mx-0">
            <p className="text-sm text-txt-md">Тип тренировок:</p>
            {location && (
              <Dropdown
                list={types}
                value={value}
                setValue={onTypeChange}
                type={location}
                // canDisabled
              />
            )}
          </div>
          <div className="mb-4 sm:max-w-xs mx-4 sm:mx-0">
            <p className="text-sm text-txt-md">Локация тренировок:</p>
            {value && (
              <Dropdown
                list={locations}
                value={location}
                setValue={onLocationChange}
                type={value}
                // canDisabled
              />
            )}
          </div>
          {currWorkouts.length > 0 ? (
            <div className="block bg-primary p-4 lg:p-6 xl:p-8 sm:gap-4 lg:gap-6 xl:gap-8 h-auto md:rounded-2xl shadow-lg sm:flex select-none sm:flex-row-reverse sm:overflow-hidden">
              <div className="flex-grow w-full flex flex-col mt-5 ">
                <div className="sm:mt-5 flex justify-between">
                  <div>
                    <h1 className="text-lg sm:text-base font-semibold text-white xl:text-xl">
                      ROUNDS
                    </h1>
                    <p className="text-white text-xs sm:text-xs sm:w-36">
                      Exercise {currTrack?.index + 1}/
                      {currWorkouts[0]?.allExercises?.exerciseList?.length}
                    </p>
                  </div>
                  <p className="flex justify-end text-white font-semibold text-4xl sm:text-3xl">
                    {currTime}
                  </p>
                </div>
                <hr className="hidden opacity-50 sm:block sm:text-white sm:mt-5 " />
                <div className="hidden sm:block sm:mt-5">
                  <h1 className="text-lg font-semibold text-primary line-clamp-3  sm:text-white sm:text-lg xl:text-2xl">
                    {currTrack?.data?.exercise?.nameRU || printMessage()}
                  </h1>
                  <p className="text-primary text-xs mt-2 font-semibold  sm:text-white xl:text-base">
                    {/* {currTrack?.data?.reps} ROUNDS | {currTrack?.data?.pause}{" "}
                Minutes */}
                    {currTrack?.data?.category}
                    <p className="font-normal mt-2 text-xs sm:text-sm">
                      {currTrack?.data?.description}
                    </p>
                  </p>

                  {currTrack?.data?.exerciseWeight?.nameRU && (
                    <p className="text-white text-xs my-0.5 font-semibold">
                      Вес: {currTrack?.data?.exerciseWeight?.nameRU || "-"}
                    </p>
                  )}
                  {currTrack?.data?.sets && (
                    <p className="text-white text-xs my-0.5 font-semibold">
                      Подходы: {currTrack?.data?.sets || "-"}
                    </p>
                  )}
                  {currTrack?.data?.reps && (
                    <p className="text-white text-xs my-0.5 font-semibold ">
                      Повторы: {currTrack?.data?.reps || "-"}
                    </p>
                  )}
                  {currTrack?.data?.pause && (
                    <p className="text-white text-xs my-0.5 font-semibold">
                      Паузы: {currTrack?.data?.pause || "-"}
                    </p>
                  )}
                </div>
                <div className="hidden flex-grow sm:flex flex-col items-end justify-end relative">
                  <div>
                    <p className="text-xs w-10 text-primary sm:text-white sm:text-xl font-light">
                      DAY
                    </p>
                    <div class="rounded-full h-10 w-10 flex items-center justify-center bg-primary boxshadow-round  mt-1.5 sm:bg-transparent sm:shadow-none sm:text-5xl">
                      <span className="font-semibold text-white">
                        {currentDay()}
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={handleAlternative}
                    style={{ opacity: isDisable ? ".5" : 1 }}
                    className="absolute bottom-0 left-0 transition-opacity duration-100 bg-white text-primary shadow-xl py-3 px-10 text-sm font-bold rounded-lg -mr-1 hover:opacity-90"
                  >
                    Альтернатива
                  </button>
                </div>
              </div>
              <div className="img-workout rounded-xl bg-white relative sm:w-full ">
                <div className="text-center block sm:hidden">
                  <div className="relative">
                    <h1 className="title-workout text-lg font-semibold text-primary mt-4 pt-5 line-clamp-3 sm:pt-0 sm:absolute sm:text-white sm:text-lg sm:top-24 xl:text-2xl">
                      {currTrack?.data?.exercise?.nameRU || printMessage()}
                    </h1>
                  </div>
                  <div className="relative">
                    <p className="round-time text-primary text-xs mt-2 font-semibold sm:absolute sm:text-white top-36 xl:text-base">
                      {/* {currTrack?.data?.reps} ROUNDS | {currTrack?.data?.pause}{" "}
                  Minutes */}
                      {currTrack?.data?.category}
                      <p className="font-normal mt-2">
                        {currTrack?.data?.description}
                      </p>
                    </p>
                    {currTrack?.data?.exerciseWeight?.nameRU && (
                      <p className="text-primary text-xs my-0.5 font-semibold">
                        Вес: {currTrack?.data?.exerciseWeight?.nameRU || "-"}
                      </p>
                    )}
                    {currTrack?.data?.sets && (
                      <p className="text-primary text-xs my-0.5 font-semibold">
                        Подходы: {currTrack?.data?.sets || "-"}
                      </p>
                    )}
                    {currTrack?.data?.reps && (
                      <p className="text-primary text-xs my-0.5 font-semibold">
                        Повторы: {currTrack?.data?.reps || "-"}
                      </p>
                    )}
                    {currTrack?.data?.pause && (
                      <p className="text-primary text-xs my-0.5 font-semibold">
                        Паузы: {currTrack?.data?.pause || "-"}
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <p className="round-title text-xs mt-2 text-primary sm:absolute sm:text-white">
                      DAY
                    </p>
                  </div>
                  <div className="relative mb-4 sm:mb-0">
                    <div class="round-number rounded-full h-10 w-10 flex items-center justify-center bg-primary boxshadow-round mx-auto mt-2 sm:bg-transparent sm:absolute sm:shadow-none sm:text-5xl">
                      <span className="font-semibold text-white">
                        {currentDay()}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={`justify-center bg-center bg-cover ${
                    showPreview ? "flex" : "hidden"
                  }`}
                >
                  <img
                    src={currTrack?.data?.preview}
                    alt="Logo"
                    className="w-44 h-60 mt-8 mb-8 sm:w-64 sm:h-80 sm:mb-0 sm:mt-0 md:w-72 md:h-96"
                  />
                </div>
                <div
                  // h-60 sm:h-80 md:h-96
                  className={`custom-border w-full h-full  bg-gray-700 ${
                    showPreview ? "hidden" : "block"
                  }`}
                >
                  {currTrack?.data && (
                    <>
                      <WorkoutPlayer
                        ref={player}
                        url={currTrack?.data?.exercise?.video?.link || ""}
                        isPlaying={isPlaying}
                        toggle={toggle}
                        title={currTrack?.data?.exercise?.name || "no name"}
                      />
                    </>
                  )}
                </div>
                <div className="flex justify-center absolute bottom-0 right-100 sm:justify-end pb-3 md:-mt-10 md:ml-12">
                  <div>
                    <button
                      className="bg-btn-blue-workout focus:bg-primary btn-shadow-updown text-white font-bold py-3 px-3 sm:px-2 sm:py-2 rounded mx-2 mt-3 md:px-3 md:py-3"
                      onClick={onPrevious}
                    >
                      <img src={Up} alt="Vector Up" className="sm:w-3" />
                    </button>
                  </div>
                  <div>
                  </div>
                  <div>
                    <button
                      className="bg-btn-blue-workout focus:bg-primary btn-shadow-updown text-white font-bold py-3 px-3 rounded mx-2 mt-3 sm:px-2 sm:py-2 md:px-3 md:py-3"
                      onClick={onNext}
                    >
                      <img src={Down} alt="Vector Down" className="sm:w-3" />
                    </button>
                  </div>
                </div>
              </div>
              <button
                className="inline mt-4 w-full transition-opacity duration-100 bg-white text-primary shadow-xl py-3 px-10 text-sm font-bold rounded-lg -mr-1 hover:opacity-90 sm:hidden"
                style={{ opacity: isDisable ? ".5" : 1 }}
                onClick={handleAlternative}
              >
                Alternative exercise
              </button>
            </div>
          ) : (
            <p className="text-xs xl:text-base text-primary my-4">
              No exercise found for this combination
            </p>
          )}
        </div>
      )}
    </Container>
  )
}
