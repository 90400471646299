import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// assets
import ClockDarkIcon from "../../images/icons/ClockDark"

// component
import Container from "../Common/Container/Container"
import BlogPost from "../BlogPost/BlogPost"
import { StaticImage } from "gatsby-plugin-image"

// utils
import { getUserData } from "../../utils/requests"

const BlogPostOpen = ({
  title,
  overview,
  publish,
  url,
  list,
  content,
  image,
}) => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  const [isAccess, setIsAccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user && isAuthenticated) {
      setLoading(true)
      getUserData(user.sub)
        .then(res => {
          if (res.status === 200) {
            setLoading(false)
            if (res.data.app_metadata?.plan !== undefined) {
              if (res.data.app_metadata?.plan !== "none") setIsAccess(true)
            }
          } else {
            alert("something went wrong")
            setLoading(false)
          }
        })
        .catch(err => {
          setLoading(false)
        })
    }
  }, [user, isAuthenticated])

  const factorContent = () => {
    let text = ""

    content?.forEach(raw => {
      raw?._rawChildren?.forEach(item => {
        text += item?.text
      })
    })

    return text
  }

  if (isLoading || loading) {
    return (
      <Container>
        <h3>Loading...</h3>
      </Container>
    )
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <button
          className="bg-primary text-white p-2"
          onClick={() => loginWithRedirect()}
        >
          Pease Sign up First
        </button>
      </Container>
    )
  }

  if (!isAccess) {
    return (
      <Container>
        <h3>This page is available only for course members.</h3>
        <Link to="/plans">
          <button className="bg-primary text-white p-2">
            Purchase Course Now
          </button>
        </Link>
      </Container>
    )
  }

  return (
    <div className="py-8 px-4 sm:px-0 sm:py-12 xl:py-16">
      <Container>
        <p className="hidden items-center gap-3 md:flex">
          <ClockDarkIcon className="w-3.5 h-3.5" />
          <span className="text-sm text-txt-light">{publish}</span>
        </p>
        <div className="mt-5 flex flex-col">
          <h1 className="text-3xl text-txt-dark font-bold capitalize hidden md:block">
            {title}
          </h1>
          <p className="mt-2.5 text-base text-txt-light hidden md:block">
            {overview}
          </p>
          {/* <img
            src={image}
            alt="blog post"
            className="w-full  object-cover object-center rounded-xl md:h-100 md:mt-7 xl:h-125 xl:mt-10"
            style={{
              boxShadow: "0px 12px 20px rgba(123, 165, 199, 0.15)",
            }}
          /> */}
          <GatsbyImage
            className="object-center rounded-xl md:h-100 md:mt-7 xl:h-125 xl:mt-10"
            imgClassName="rounded-xl"
            style={{
              boxShadow: "0px 12px 20px rgba(123, 165, 199, 0.15)",
            }}
            image={getImage(image.localFile)}
            alt={title}
          />
        </div>
        <div className="flex gap-7 flex-col xl:flex-row">
          <div className="flex-grow">
            <div className="mt-5 md:mt-10">
              <h2 className="text-2xl font-bold text-txt-dark mb-2.5 md:font-semibold md:text-1/5xl">
                Need title
              </h2>
              <p className="text-sm opacity-70 text-txt-light whitespace-pre-line md:text-base md:opacity-100">
                {factorContent()}
              </p>
            </div>
          </div>
          <div className="w-full flex-shrink-0  md:mt-10 xl:w-1/3">
            <h2 className="text-1/5xl text-txt-dark font-semibold mb-6 xl:mb-5">
              Recent Blog Posts
            </h2>
            <BlogPost list={list} />
          </div>
        </div>
        <div className="flex mt-10 justify-between md:mt-14 md:justify-end">
          <p className="flex items-center gap-3 md:hidden ">
            <ClockDarkIcon className="w-3.5 h-3.5" />
            <span className="text-sm text-txt-light">{publish}</span>
          </p>
          <Link to="/blog">
            <button className="py-2.5 px-4 bg-gray-100 rounded-md cursor-pointer text-sm text-gray-500 font-medium capitalize">
              Go Back
            </button>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default BlogPostOpen
