export const chartOptions = {
  //   colors: ["#00AB55", "#FFE700", "#2D99FF", "#826AF9", "#2CD9C5", "#FF6C40"],
  colors: ["#7A9BC1", "#81ACC9", "#7788C2", "#9299B8"],

  chart: {
    toolbar: { show: false },
    zoom: { enabled: false },
    // animations: { enabled: false },
    foreColor: "#919EAB",
    fontFamily: `"Montserrat", sans-serif`,
  },

  states: {
    hover: {
      filter: {
        type: "lighten",
        value: 0.04,
      },
    },
    active: {
      filter: {
        type: "darken",
        value: 0.88,
      },
    },
  },

  fill: {
    opacity: 1,
    gradient: {
      type: "vertical",
      shadeIntensity: 0,
      opacityFrom: 0.4,
      opacityTo: 0,
      stops: [0, 100],
    },
  },

  dataLabels: { enabled: false },

  grid: {
    strokeDashArray: 3,
    borderColor: "rgba(145,158,171, .24)",
  },

  markers: {
    size: 0,
    strokeColors: "#ffffff",
  },

  legend: {
    show: true,
    fontSize: String(13),
    position: "top",
    horizontalAlign: "right",
    markers: {
      radius: 12,
    },
    fontWeight: 500,
    itemMargin: { horizontal: 12 },
    labels: {
      colors: "#212B36",
    },
  },

  plotOptions: {
    // Bar
    bar: {
      columnWidth: "28%",
      borderRadius: 4,
    },
  },

  responsive: [
    {
      // sm
      breakpoint: 600,
      options: {
        plotOptions: { bar: { columnWidth: "40%" } },
      },
    },
    {
      // md
      breakpoint: 900,
      options: {
        plotOptions: { bar: { columnWidth: "32%" } },
      },
    },
  ],
  tooltip: { x: { show: false }, marker: { show: false } },
}
