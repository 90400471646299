import React, { useState } from "react"
import { navigate } from "gatsby"

// assets
import CloseIcon from "../../../images/icons/Close"

const MealGenerateModal = ({ toggle, onCaloryChange }) => {
  const [calories, setCalories] = useState(2000)
  const [isError, setIsError] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    toggle(false)
  }

  const handleClick = () => {
    let cal = Number(calories)
    if (cal >= 800 && cal <= 3500) {
      setLoading(true)
      onCaloryChange(calories, callBack)
    } else {
      setIsError(true)
    }
  }

  const callBack = () => {
    setLoading(false)
  }

  const onNavigate = () => {
    toggle(false)
    navigate("/userinput")
  }

  return (
    <div className="w-full h-full fixed z-10 flex items-center justify-center top-0 bg-black bg-opacity-10">
      <div className="w-11/12 max-w-90 bg-white relative flex flex-col items-center justify-center rounded-2xl shadow-video-card xxs:w-full">
        <CloseIcon
          className="self-end mt-2 mr-2 cursor-pointer"
          onClick={handleClose}
        />
        <p className="text-xl text-txt-dark font-semibold">Создать новое меню</p>
        <p className="mt-2.5 text-sm max-w-13 text-center text-txt-med opacity-50">
          Создать новый план питания или изменить информацию
        </p>
        <div className="mt-4 w-full px-4">
          <p className="text-sm text-txt-md">Калории</p>
          <div className="mt-2 flex items-center gap-2.5">
            <input
              value={calories}
              type="number"
              className="bg-gray-200 px-3 py-1.5 rounded-md w-full"
              onChange={({ target }) => {
                setCalories(target.value)
                if (isError) setIsError(false)
              }}
            />
            <button
              className={`transition-colors text-white bg-primary rounded-xl  py-2.5 px-4 shadow-btn-xl  hover:bg-white hover:text-primary ${
                loading ? "cursor-wait" : "cursor-pointer"
              }`}
              onClick={handleClick}
            >
              {!loading ? "Создать" : "Создать..."}
            </button>
          </div>
          {isError && (
            <span className="text-xss text-txt-light mt-2 ml-0.5">
              calories should be between 800 - 3500
            </span>
          )}
        </div>
        <p className="my-4 text-sm text-center text-txt-med">или</p>
        <p className="text-sm max-w-13 text-center text-txt-med opacity-50">
          Regenerate with all new data
        </p>
        <button
          className={`transition-colors text-white bg-primary rounded-xl py-2.5 px-4 shadow-btn-xl mt-4 mb-6 hover:bg-white hover:text-primary`}
          onClick={onNavigate}
        >
          Создать
        </button>
      </div>
    </div>
  )
}

export default MealGenerateModal
