import React from "react"
import Select, { components } from "react-select"

import * as styles from "../../styles/userInput.module.css"

const sexs = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
]
const goals = [
  {
    id: 1,
    valueEN: "loseWeight",
    labelEN: "Lose weight",
    value: "Сжечь жир",
    label: "Сжечь жир",
  },
  {
    id: 2,
    valueEN: "gainWeight",
    labelEN: "Gain weight",
    label: "Набрать мышечную массу",
    value: "Набрать мышечную массу",
  },
  {
    id: 3,
    valueEN: "bodyRecomp",
    labelEN: "Body recomp",
    value: "Достичь рекомпозиции тела",
    label: "Достичь рекомпозиции тела",
  },
]

const IndicatorsContainer = props => {
  return (
    <div className="bg-gray-lightest md:bg-white">
      <components.IndicatorsContainer {...props} />
    </div>
  )
}

const controlStyles = {
  color: "white",
}

const ControlComponent = props => (
  <div style={controlStyles}>
    <components.Control className={"w-full " + styles.control} {...props} />
  </div>
)

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer
    className="bg-gray-lightest md:bg-white h-6 md:h-8"
    {...props}
  >
    {children}
  </components.ValueContainer>
)
const MultiValueContainer = props => {
  return (
    <components.MultiValueContainer
      className={styles.multiValueContainer}
      {...props}
    />
  )
}

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>{children}</components.SingleValue>
)

const BasicInfo = ({ formData, setFormData, totalCalories }) => {
  const defaultSexValue = sexs.find(sex => sex.value === formData.gender)

  return (
    <div>
      <div className="flex flex-col md:my-2 md:mx-6">
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70  font-medium">
          Возраст:
          <input
            type="text"
            className="w-full h-6 md:h-8 bg-gray-lightest md:bg-white opacity-100 text-gray rounded my-1 md:my-2 p-3 focus:outline-blue"
            placeholder="8 - 90"
            id="age"
            name="age"
            value={formData?.age}
            onChange={e => {
              const value = e.target.value
              setFormData({
                ...formData,
                age: value,
              })
            }}
          />
        </div>
        <div className="mt-2 text-xs text-gray-darkest text-opacity-70 font-medium">
          Пол:
          <Select
            closeMenuOnSelect={true}
            components={{
              IndicatorsContainer,
              ValueContainer,
              SingleValue,
              ControlComponent,
            }}
            defaultValue={defaultSexValue || [sexs[0]]}
            options={sexs}
            onChange={({ value }) => {
              setFormData({
                ...formData,
                gender: value,
              })
            }}
            styles={{
              singleValue: base => ({ ...base, color: "white" }),
              valueContainer: base => ({
                ...base,
                color: "#1F1F1F",
                width: "100%",
                padding: "4px",
                fontSize: "10px",
                borderRadius: "5px 0 0 5px",
              }),
              singleValue: base => ({
                ...base,
                padding: 5,
                borderRadius: 5,
                opacity: "60%",
                color: "#1F1F1F",
                display: "flex",
              }),
              indicatorsContainer: base => ({
                ...base,
                borderRadius: "0 5px 5px 0",
                height: "25px",
                display: "flex",
              }),
              indicatorSeparator: base => ({
                ...base,
                display: "none",
              }),
              control: base => ({
                ...base,
                minHeight: "25px",
                border: "none",
                marginTop: "0.2rem",
              }),
            }}
            className={
              "opacity-100 text-gray-darkest md:my-2 md:hidden text-opacity-60 border-none " +
              styles.select
            }
            id="sex"
            name="sex"
          />
          <div className="md:flex hidden">
            <div className="md:flex md:my-2 md:mx-2 ">
              <div>
                <label for="male" className="inline-flex items-center">
                  <input
                    id="male"
                    name="Male"
                    type="radio"
                    className="form-radio text-blue"
                    value="male"
                    onChange={e => {
                      setFormData({
                        ...formData,
                        gender: e.currentTarget.value,
                      })
                    }}
                    checked={formData?.gender === "male"}
                  />
                  <span className="ml-2">Мужской</span>
                </label>
              </div>
              <div className="md:mx-10">
                <label for="female" className="inline-flex items-center">
                  <input
                    id="female"
                    name="Female"
                    type="radio"
                    className="form-radio text-blue"
                    value="female"
                    onChange={e => {
                      setFormData({
                        ...formData,
                        gender: e.currentTarget.value,
                      })
                    }}
                    checked={formData?.gender === "female"}
                  />
                  <span className="ml-2">Женский</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 text-xs text-gray-darkest text-opacity-70 font-medium">
          Вы хотите:
          <Select
            closeMenuOnSelect={true}
            components={{
              IndicatorsContainer,
              ValueContainer,
              SingleValue,
              ControlComponent,
            }}
            options={goals}
            onChange={data =>
              setFormData({
                ...formData,
                goal: data,
              })
            }
            value={formData?.goal}
            styles={{
              singleValue: base => ({ ...base, color: "white" }),
              valueContainer: base => ({
                ...base,
                color: "#1F1F1F",
                width: "100%",
                padding: "4px",
                fontSize: "10px",
                borderRadius: "5px 0 0 5px",
              }),
              singleValue: base => ({
                ...base,
                padding: 5,
                borderRadius: 5,
                opacity: "60%",
                color: "#1F1F1F",
                display: "flex",
              }),
              indicatorsContainer: base => ({
                ...base,
                borderRadius: "0 5px 5px 0",
                height: "25px",
                display: "flex",
              }),
              indicatorSeparator: base => ({
                ...base,
                display: "none",
              }),
              control: base => ({
                ...base,
                minHeight: "25px",
                border: "none",
                marginTop: "0.2rem",
              }),
            }}
            className={
              "opacity-100 text-gray-darkest md:my-2 text-opacity-60 border-none md:shadow-xs " +
              styles.select
            }
          />
        </div>
        {/* <div className="mt-4">
          <p className="text-primary text-base">
            TotalCalories: {totalCalories}
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default BasicInfo
