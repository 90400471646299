import React from "react"
import { Link } from "gatsby"
import ReactPlayer from "react-player"

// components
import VideoPlayer from "../LatestVideos/VideoPlayer"

// assets
import PlaySmIcon from "../../images/icons/Playsm"

const CarouselItem = ({ data, index }) => {
  return (
    //pt-30
    <div className="bg-white w-42 relative shadow-video-card rounded-18 pt-2 pb-2 px-2 mt-4 mx-2 md:w-full md:mx-0 md:mt-0 md:pt-0 md:px-5 md:overflow-hidden">
      <div className="bg-dark w-32 h-32 absolute -top-3.5 -right-6 rounded-full overflow-hidden shadow-img hidden">
        <img className="h-full object-cover" src={data.image} alt="exercise" />
      </div>
      <div className="bg-dark md:-mx-5 md:h-40 xl:h-48 overflow-hidden rounded-xl relative">
        {/* <VideoPlayer
          // url={data?.link || data.url}
          url={"https://vimeo.com/595010954"}
          // url={
          //   "https://www.peach.themazzone.com/durian/movies/sintel-1024-surround.mp4"
          // }
          preview={data?.preview || ""}
          id={index}
        /> */}
        {/* <ReactPlayer
          className="bg-dark"
          url={data?.link || data.url}
          height="100%"
          width="100%"
          controls
        /> */}
        <VideoPlayer
          url={data?.link || data.url}
          center
          to={`/video/${data.title}`}
        />
      </div>
      <Link to={`/video/${data.title}`}>
        <div className="cursor-pointer">
          <div className="flex items-center gap-2">
            {data?.tags?.length > 0 && (
              <span className="w-1.5 h-1.5 rounded-full inline-block bg-primary opacity-30 md:hidden"></span>
            )}
            <span
              className={`text-xs text-txt-med opacity-40 text-capitalize mt-1 md:mt-3 md:text-primary md:font-semibold md:opacity-100 xl:mt-4 xl:text-sm ${
                data?.tags?.length === 0 ? "invisible" : ""
              }`}
            >
              {data?.tags?.length > 0
                ? data?.tags?.map(item => `${item.nameRU} `)
                : "other"}
            </span>
          </div>
          <p className="text-md break-words min-h-84 text-txt-dark opacity-70 font-bold md:mt-2 md:text-sm xl:text-lg">
            {data?.titleRU || data?.title}
          </p>
          <p className="line-clamp-2 text-xs text-txt-med opacity-40 mt-6 md:mt-1.5 md:text-md md:text-txt-light md:opacity-100 md:line-clamp-3">
            {/* {data.description} */}
          </p>
        </div>
      </Link>
      <Link to={data.to}>
        <button className="transition-colors mt-5 py-2 px-4 w-full md:w-auto text-white flex items-center justify-center gap-2 rounded-xl shadow-btn-sm bg-gradient-to-br from-blue-700 to-blue-800 md:hidden">
          <span className="text-xs font-bold">Play exercise</span>
          <PlaySmIcon />
        </button>
      </Link>
    </div>
  )
}

export default CarouselItem
