import React, { useEffect } from "react"

import { translateWeekday } from "../../utils/helper"

const Item = ({ data, activeIndex, handleActive }) => {
  const handleClick = () => {
    handleActive(data.day_id, data.day)
  }

  const showDay = () => {
    if (data.day < 9) return "0" + (data.day + 1)
    else return data.day + 1
  }

  useEffect(() => {
    if (activeIndex == data?.day) {
      const dayElement = document.getElementById(showDay())
      if (dayElement) {
        dayElement.focus()
      }
    }
  }, [])
  return (
    <div
      className={`transition-colors group cursor-pointer flex flex-col items-center justify-center rounded-lg py-2 flex-auto gap-1 sm:flex-3 md:flex-3.5 md-800:py-0 md-800:gap-0  md-800:flex-4 hover:bg-primary 
      ${activeIndex == data?.day ? "bg-primary" : "bg-white sm:bg-gray-100"}`}
      id={showDay()}
      onClick={handleClick}
    >
      {data.day < 42 && (
        <span
          className={`text-xs font-medium text-center sm:text-sm group-hover:text-white
        ${
          activeIndex == data.day
            ? "text-white"
            : "opacity-70 text-txt-med sm:text-gray-500 sm:opacity-100"
        }
      `}
        >
          {/* {data.prettier.day} */}
          Day {showDay()}
        </span>
      )}
      <span
        className={`text-xs  lowercase font-medium sm:text-base sm:capitalize  group-hover:text-white 
        ${
          activeIndex == data?.day
            ? "text-white opacity-100"
            : "opacity-40 text-txt-med sm:text-gray-500 sm:opacity-100"
        }`}
      >
        {translateWeekday(data.prettier.day_name)}
      </span>
    </div>
  )
}

export default Item
