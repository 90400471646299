import { getTotalCount } from "../TodoList/utils"

export function getPercentage(_data, _day) {
  const currTasks = _data?.taskList?.find(t => t.day === _day)

  if (!currTasks) return 0

  const total = _data.totalTasksCount
  const basicTasks = currTasks.basicTasks.length
  const extraTasks = currTasks.extraTasks.length
  const count = basicTasks + extraTasks

  const percentage = (100 * count) / total

  return percentage
}

export function getPercentageNew(_data) {
  const userSelectedTasks = _data?.taskList

  if (!userSelectedTasks) return 0

  const totalTasksCount = _data.totalTasksCount
  const userSelectedTasksCount = getTotalCount(userSelectedTasks)

  const percentage = (100 * userSelectedTasksCount) / totalTasksCount

  return percentage
}

export function getMaxWaterIntake(_weight, _age) {
  const wp = Math.floor(_weight * 2.205)
  let value = wp / 2.2

  if (_age < 30) {
    value *= 40
  } else if (_age >= 30 && _age < 55) {
    value *= 35
  } else {
    value *= 30
  }

  value /= 28.3

  let eachDay = Math.round(value * 29.5)
  eachDay = Math.round(eachDay / 250) * 250

  return eachDay
}
