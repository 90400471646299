import * as React from "react"

function NextArrowIcon(props) {
  return (
    <svg
      width={8}
      height={15}
      viewBox="0 0 11 18"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 16.5L9 9 1 1.5"
        stroke="#ffffff"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default NextArrowIcon
