import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

// component
import Tag from "../Common/Tag/Tag"

import VideoPlayer from "./VideoPlayer"

import FadeIn from "../Animation/FadeIn"

const VideoItem = ({ i, data }) => {
  return (
    <FadeIn>
      <Link to={`/video/${data?.node?.title}` || data?.to}>
        <h2
          className={`cursor-pointer pb-1.5 pl-0.5 text-xl opacity-90 font-bold lg:text-hd-lg xl:text-xl md:min-h-62`}
        >
          {data?.node?.titleRU || data?.title}
        </h2>
      </Link>
      <div className="flex flex-wrap pb-2.5 gap-1 md:gap-2.5 xl:gap-2">
        {data?.node?.tags?.length > 0 ? (
          (data?.node?.tags).map((item, i) => (
            <Tag key={i} label={item?.nameRU || item} />
          ))
        ) : (
          <Tag label="other" hide />
        )}
      </div>
      <VideoPlayer
        url={data?.node?.link || data.url}
        preview={data?.preview || ""}
        link={`/video/${data?.node?.title}` || data?.to}
      />
    </FadeIn>
  )
}

export default VideoItem
