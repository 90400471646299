import React, { useRef } from "react"
import { Link } from "gatsby"

// components
import AudioPlayer from "../Common/AudioPlayer/AudioPlayer"
import Container from "../Common/Container/Container"
import SubTitle from "../Common/SubTitle/SubTitle"
import FadeIn from "../Animation/FadeIn"
import useMeditationAudio from "../../hooks/useMeditationAudio"

const Mediation = ({
  title = "Mediations",
  subtitle = "Meditate with us",
  list,
  dark,
}) => {
  let refs = new useRef([])
  const meditationAudioData = useMeditationAudio()

  const onAudioChange = _id => {
    refs.current.forEach(el => {
      if (el.id !== _id) {
        el.listener(false)
      }
    })
  }

  return (
    <div className="py-8 px-4 sm:px-0 sm:py-12 xl:py-16">
      <Container>
        <FadeIn>
          <SubTitle title1={title} text={subtitle} />
        </FadeIn>
        <div className="pt-14 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 justify-items-center sm:justify-items-stretch">
          {list &&
            list.map((item, i) => {
              const { audio } = meditationAudioData.find(audio => {
                return audio.id === item.id
              })
              const data = {
                ...item,
                audio: {
                  asset: {
                    url: audio.publicURL,
                  },
                },
              }
              return (
                <FadeIn>
                  <AudioPlayer
                    key={i}
                    id={item.id}
                    ref={el => (refs.current[i] = { ...el, id: item.id })}
                    data={data}
                    onChange={onAudioChange}
                    dark={dark}
                  />
                </FadeIn>
              )
            })}
        </div>
      </Container>
    </div>
  )
}

export default Mediation
