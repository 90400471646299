import React from "react"

const Tag = ({ label, isActive, changeTag, hide }) => {
  const handleClick = () => {
    const check = changeTag || null

    if (check) {
      changeTag(label)
    }
  }

  return (
    <button
      className="transition-colors duration-100 bg-gray-100 hover:bg-blue-700 text-gray-400  hover:text-blue-100 text-xs font-medium py-1 px-2 sm:py-2 sm:px-4 rounded-lg lg:text-base lg:py-3 lg:px-5 xl:text-sm xl:py-2 xl-px-4"
      style={{
        background:
          isActive && "linear-gradient(180deg, #8FC6EC 0%, #6897B7 100%)",
        boxShadow:
          isActive && "1.36px 9.53px 20.43px rgba(134, 176, 205, 0.42)",
        color: isActive && "white",
        visibility: hide && "hidden",
      }}
      onClick={handleClick}
    >
      {label}
    </button>
  )
}

export default Tag
