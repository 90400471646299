import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"

import { chartOptions } from "./options"

const ReactApexChart = loadable(() =>
  import("../../../../node_modules/react-apexcharts/src/react-apexcharts")
)

export default function ChartLine({ title, unit, data }) {
  const getLastValue = () => {
    let value = data[0]

    for (let i = data.length - 1; i > 0; i--) {
      if (data[i] !== 0) {
        value = data[i]
        break
      }
    }

    return value
  }

  const options = {
    ...chartOptions,
    plotOptions: {
      bar: {
        columnWidth: "24%",
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: { enabled: true, style: { fontSize: "10px" } },
    stroke: { show: false },
    xaxis: {
      categories: ["15-22", "22-29", "29-05", "05-12", "12-19", "19-26"],
    },
  }

  return (
    <div className="bg-gray-100 text-txt-md overflow-hidden relative rounded-[16px]">
      <div className="pt-2.5 px-4 flex items-center justify-between">
        <div className="flex flex-col text-txt-dark">
          <span className="text-base font-semibold">{title}</span>
          <span className="text-sm font-semibold">
            {data[0]} <span className="text-xss">{unit}</span>
          </span>
        </div>
        <div className="py-1.5 px-1 bg-blue-800 text-white rounded-lg">
          <span className="text-xs">{getLastValue()} </span>
          <span className="text-xss"> {unit}</span>
        </div>
      </div>

      <ReactApexChart
        type="bar"
        series={[
          {
            name: title,
            data,
          },
        ]}
        options={options}
        height={320}
      />
    </div>
  )
}
