import React, { useState, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "gatsby"

// components
import SubTitle from "../Common/SubTitle/SubTitle"
import Container from "../Common/Container/Container"
import Search from "../Common/Search/Search"
import Tag from "../Common/Tag/Tag"
import LatestVideos from "../LatestVideos"

let tagList = []

const VideoContent = ({ videos, category }) => {
  const [list, setList] = useState([])
  const [tempList, setTempList] = useState([])
  const [activeTag, setActiveTag] = useState("")
  const [tags, setTags] = useState([])
  console.log("category", category)
  useEffect(() => {
    if (videos) {
      // setList(videos)
      setTempList(videos)
    }
  }, [videos])

  useEffect(() => {
    if (tempList) changeTag("Питание")
  }, [tempList])

  useEffect(() => {
    if (category) {
      let newList = category.map(item => item.nameRU)
      // newList.push("Все")
      setTags(newList)
    }
  }, [category])

  const handleSearch = _value => {
    if (activeTag !== "Все") {
      if (_value !== "") {
        setList(filterList(list, _value))
      } else {
        setList(tagList)
      }
    } else {
      if (_value !== "") {
        setList(filterList(tempList, _value))
      } else {
        setList(tempList)
      }
    }
  }

  const filterList = (_list, _value) => {
    return _list.filter(
      item =>
        item.node.titleRU.toLowerCase().indexOf(_value.toLowerCase()) !== -1 ||
        item.node.tagsRU.findIndex(item =>
          item.name.toLowerCase().includes(_value.toLowerCase())
        ) > -1
    )
  }

  const changeTag = _label => {
    setActiveTag(_label)

    if (_label !== "Все") {
      let filter = tempList.filter(
        item =>
          item.node.tags.findIndex(tag =>
            tag.nameRU.toLowerCase().includes(_label.toLowerCase())
          ) > -1
      )

      tagList = filter
      setList(filter)
    } else {
      setList(tempList)
    }
  }

  const checkActive = _value => {
    return activeTag === _value
  }

  return (
    <div className="py-8 px-4 sm:px-0 sm:py-12 xl:py-16">
      <Container>
        <SubTitle title1="Видео" title2="библиотека" text="Наша коллекция" />
        {/* gap-5 md:gap-0 */}
        <div className="flex justify-between items-baseline my-5 flex-col-reverse  md:flex-row  md:my-10 ">
          <div className="flex items-center gap-2 flex-wrap justify-center sm:justify-start mt-5 md:mt-0">
            {tags.map((value, i) => (
              <Tag
                key={i}
                label={value}
                isActive={checkActive(value)}
                changeTag={changeTag}
              />
            ))}
          </div>
          <Search handleSearch={handleSearch} />
        </div>
        <LatestVideos list={list} />
      </Container>
    </div>
  )
}

export default VideoContent
