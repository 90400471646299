import React from "react"

import SearchIcon from "../../../images/icons/Search"

export default function Search({ handleSearch }) {
  return (
    <div className="relative w-full md:w-auto">
      <SearchIcon className="absolute mt-4 ml-4" />
      {/* TODO: make this search bg color work */}
      <input
        className="w-full bg-gray100 text-gray-500 rounded-xl py-3 pl-12 pr-5"
        onChange={e => handleSearch(e.target.value)}
        placeholder="Search..."
      />
    </div>
  )
}
