import React, { useState } from "react"

// assets
import ChevronIcon from "../../../images/icons/Chevron"

const Dropdown = ({ list, value, setValue, type, canDisabled = false }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClick = _data => {
    setValue(_data)
    toggle()
  }

  const checkIsDisabled = _title => {
    // comment out if need lock combination
    // if (type?.title === "Beginner" && _title === "Gym") return true
    // if (type?.title === "Advanced" && _title === "Home") return true
    // else if (type?.title === "Gym" && _title === "Beginner") return true
    // else if (type?.title === "Home" && _title === "Advanced") return true

    return false
  }

  return (
    <div className="mt-2 relative">
      <div
        className="bg-gray-200 px-3 py-1.5 rounded-md flex justify-between items-center cursor-pointer"
        onClick={toggle}
      >
        <span className="text-sm text-txt-md opacity-70 select-none font-semibold">
          {value?.titleRU || value?.title}
        </span>
        <ChevronIcon
          className={`transition-transform transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      <ul
        className={`transition-all z-10 absolute top-9 w-full bg-white border-t-2 shadow-md select-none rounded max-h-28  ${
          !isOpen
            ? "overflow-hidden h-0 opacity-0"
            : "h-auto opacity-100 overflow-y-auto"
        }`}
      >
        {list.map(item => (
          <li
            key={item.id}
            className={`p-1 text-sm cursor-pointer hover:bg-primary hover:text-white 
            ${value.id === item.id ? "bg-primary text-white" : "text-txt-light"}
            ${
              canDisabled && checkIsDisabled(item.title)
                ? "cursor-not-allowed opacity-70"
                : ""
            }
            `}
            onClick={() => {
              if (!checkIsDisabled(item.title)) {
                handleClick(item)
              }
            }}
          >
            {item?.titleRU || item.title}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Dropdown
