import React, { Component } from "react"

import "./Badges.css"

import Pic from "../../images/Ellipse.png"
import btnWhite from "../../images/Vector-btn.png"
import btnBlue from "../../images/Vector-blue.png"

export default class Badges extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }

    this.toggleHover = this.toggleHover.bind(this)
  }

  toggleHover() {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    var linkStyle
    if (this.state.hover) {
      linkStyle = {
        textColor: "white",
        img: btnWhite,
        shadow: "5px 5px 15px rgba(127, 166, 199, 0.28)",
        cursor: "pointer",
      }
    } else {
      linkStyle = {
        textColor: "#81ACC9",
        img: btnBlue,
        shadow: "5px 5px 15px rgba(127, 166, 199, 0.28)",
      }
    }

    return (
      <div className="min-h-screen shadow-2xl flex flex-col bg-gray-100">
        <div className="m-auto">
          <div
            className="card-badges max-w-sm shadow-md px-4 transition-all duration-500 bg-white hover:bg-blue300 "
            style={{
              backgroundColor: `${linkStyle.backgroundColor}`,
              boxShadow: `${linkStyle.shadow}`,
            }}
            onMouseEnter={this.toggleHover}
            onMouseLeave={this.toggleHover}
          >
            <div className="flex flex-row items-center">
              <div className="text-center">
                <img class="" src={Pic} alt="Picture" />
              </div>
              <div className="text-left md:text-left">
                <div
                  className="font-medium text-xs p-5"
                  style={{ color: `${linkStyle.textColor}` }}
                >
                  <h1 className="font-semibold">
                    Breakfast{" "}
                    <span className="font-xs">
                      {" "}
                      <span className="font-bold">-</span> 310 kcal
                    </span>
                  </h1>
                  <p className="text-xs">Senior Software Developer</p>
                </div>
              </div>
              <div className="px-3">
                <a href="#">
                  <img class="mx-auto" src={linkStyle.img} alt="Button" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
