import * as React from "react"

function ArrowRightIcon(props) {
  return (
    <svg
      width={7}
      height={10}
      viewBox="0 0 7 10"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.5 1l4 4-4 4"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowRightIcon
