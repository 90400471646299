import React, { useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

// components
import Container from "../../Common/Container/Container"
import SubTitle from "../../Common/SubTitle/SubTitle"
import VideoPlayer from "../../LatestVideos/VideoPlayer"

// styles
import "../../../styles/landing.css"

// assets
// import HeroBg from "../../../images/landing/hero-bg-new.png"
import { BsArrow90DegUp } from "react-icons/bs"

// animation
import FadeIn from "../../Animation/FadeIn"

// Context
import UserContext from "../../../context/UserAuth"

const Hero = () => {
  const { loginWithRedirect } = useAuth0() || {
    loginWithRedirect: null,
  }

  const { isAuthenticated, userMetaData } = useContext(UserContext) || {
    isAuthenticated: false,
    userMetaData: null,
  }

  return (
    <div
      className="hero-bg"
      style={{
        minHeight: isAuthenticated ? "calc(100vh - 160px)" : "auto",
        display: isAuthenticated ? "flex" : "block",
      }}
    >
      <div
        className={`pt-8 px-4 sm:px-0 sm:pt-16 ${
          !isAuthenticated ? "xl:pt-20" : "!pt-0 w-full my-auto"
        }`}
        // style={{
        //   borderBottom: `2px solid rgba(146, 153, 184, 1)`,
        // }}
      >
        <Container>
          <FadeIn>
            <div className="flex items-center flex-col md:flex-row">
              <div className="flex-grow">
                <h1 className="text-xl md:text-40/49 lg:text-50/60 font-black">
                  Body Sculpting
                </h1>
                <h1 className="text-xl md:text-40/49 lg:text-50/60 font-black">
                  Academy
                </h1>
                <p className="text-sm sm:text-base lg:text-xl mt-4 md:mt-6 lg:mt-8 max-w-630">
                  Зарегистрируйтесь на платформе, используя e-mail указанный на
                  платформе в момент оплаты. Если вы уже зарегистрированы, то
                  нажмите на кнопку "личный кабинет", чтобы получить доступ к
                  курсу.
                </p>
                {!isAuthenticated ? (
                  <button
                    className="hero-btn  sm:text-base mt-6 md:mt-10 lg:mt-14"
                    onClick={() => loginWithRedirect()}
                  >
                    Зарегистрироваться / Войти в личный кабинет
                  </button>
                ) : (
                  // <div className="mt-6 md:mt-10 lg:mt-14">
                  //   <SubTitle
                  //     title1="Welcome "
                  //     title2={` ${userMetaData?.nickname || ""}!`}
                  //   />
                  // </div>
                  <button
                    className="hero-btn  sm:text-base mt-6 md:mt-10 lg:mt-14"
                    onClick={() => navigate("/dashboard")}
                  >
                    Личный кабинет
                  </button>
                )}
              </div>
              {isAuthenticated ? (
                <div className="w-full max-w-[600px]  mt-8 md:my-0">
                  <div className=" relative">
                    <VideoPlayer
                      url="6d6f8ebd-9e02-4a70-a4c2-48a7a6bd973e"
                      overlay
                      hasFullScreen
                    />
                  </div>
                  <p className="flex mt-3 text-xs text-primary">
                    <BsArrow90DegUp size={28} />{" "}
                    <span className="mt-4 pl-2">
                      Смотри скорее приветственное видео и начни свой путь к
                      изменениям!
                    </span>
                  </p>
                </div>
              ) : (
                <div className="max-w-630 xl:max-w-full">
                  <StaticImage
                    src="../../../images/landing/hero-bg-new.png"
                    alt="..."
                  />
                </div>
              )}
            </div>
          </FadeIn>
        </Container>
      </div>
    </div>
  )
}

export default Hero
