import * as React from "react"
import { useState, useEffect } from "react"

import Recipes from "../Recipes"
import SubTitle from "../Common/SubTitle/SubTitle"
import Container from "../Common/Container/Container"
import Search from "../Common/Search/Search"
import Tag from "../Common/Tag/Tag"

let tagList = []

const RecipesBlog = ({ recipes, category }) => {

  const [list, setList] = useState(recipes)
  const [tempList] = useState(recipes)
  const [activeTag, setActiveTag] = useState("Все")
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (category) {
      let newList = category.map(item => item?.nameRU || item.name)
      newList.push("Все")
      setTags(newList)
    }
  }, [category])

  const handleSearch = _value => {
    if (activeTag !== "Все") {
      if (_value !== "") {
        setList(filterList(list, _value))
      } else {
        setList(tagList)
      }
    } else {
      if (_value !== "") {
        setList(filterList(tempList, _value))
      } else {
        setList(tempList)
      }
    }
  }

  const filterList = (_list, _value) => {
    return _list.filter(
      item =>
        item.nameRU.toLowerCase().indexOf(_value.toLowerCase()) !== -1 ||
        item.mealTime.nameRU.toLowerCase().indexOf(_value.toLowerCase()) !== -1
      // || item.tags.findIndex(item =>
      //   item.toLowerCase().includes(_value.toLowerCase())
      // ) > -1
    )
  }

  const checkActive = _value => {
    return activeTag === _value
  }

  const changeTag = _label => {
    setActiveTag(_label)

    if (_label !== "Все") {
      let filter = tempList.filter(
        item =>
          // item.tags.findIndex(tag =>
          //   tag.toLowerCase().includes(_label.toLowerCase())
          // ) > -1
          item.mealTime.nameRU.toLowerCase().indexOf(_label.toLowerCase()) !==
          -1
      )

      tagList = filter
      setList(filter)
    } else {
      setList(tempList)
    }
  }

  return (
    <>
      <div className="mt-6 xl:mt-16 mb-12 mx-4 sm:mx-0 ">
        <Container>
          <SubTitle
            title1="Our"
            title2="Recipes"
            text="Prepare meals with us"
          />
          {/* gap-5 lg:gap-0 */}
          <div className="flex justify-between items-center my-5 flex-col-reverse lg:flex-row  md:my-10 ">
            <div className="flex items-center gap-2 flex-wrap justify-center sm:justify-start mt-5 lg:mt-0">
              {tags.map((value, i) => (
                <Tag
                  key={i}
                  label={value}
                  isActive={checkActive(value)}
                  changeTag={changeTag}
                />
              ))}
            </div>
            <Search handleSearch={handleSearch} />
          </div>
        </Container>
      </div>
      <Recipes
        list={list}
        title1="Видео"
        title2="библиотека"
        text="Наша коллекция"
      />
    </>
  )
}

export default RecipesBlog
