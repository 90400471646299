import * as React from "react"

function SearchIcon(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 14l-2.99-2.996L14 14zm-1.333-7A5.667 5.667 0 111.333 7a5.667 5.667 0 0111.334 0v0z"
        stroke="#7A9BC1"
        strokeWidth={1.333}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SearchIcon
