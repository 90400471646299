import React from "react"
import { Link } from "gatsby"

// assets
// import CartIcon from "../../images/icons/Cart";
import { FaShoppingCart } from "react-icons/fa"

import * as styles from "./primary.module.css"

import { StaticImage } from "gatsby-plugin-image"

export function PlanImg1() {
  return (
    <StaticImage
      src="../../images/planwoman1.png"
      alt="Esteekey Hero"
      style={{
        borderRadius: "50%",
        overflow: "hidden",
      }}
    />
  )
}
export function PlanImg2() {
  return (
    <StaticImage
      src="../../images/planwoman2.png"
      alt="Esteekey Hero"
      style={{
        borderRadius: "50%",
        overflow: "hidden",
      }}
    />
  )
}
export function PlanImg3() {
  return (
    <StaticImage
      src="../../images/planwoman3.png"
      alt="Esteekey Hero"
      style={{
        borderRadius: "50%",
        overflow: "hidden",
      }}
    />
  )
}

const CarouselItem = ({ data }) => {
  return (
    <div className={"slick-item-primary " + styles.card}>
      <div className={styles.image__wrapper}>
        {/* <img src={data.image} alt="primary slider" /> */}
        {data.id === 1 ? (
          <PlanImg1 />
        ) : data.id === 2 ? (
          <PlanImg2 />
        ) : (
          <PlanImg3 />
        )}
      </div>
      <div className={"flex items-center gap-2 " + styles.price__wrapper}>
        <span className={"sm:none " + styles.dot}></span>
        <span className={styles.price__tag}>${data.price}</span>
      </div>
      <h2>{data.title}</h2>
      <p className="mt-6 text-xs opacity-40 text-txt-med">{data.description}</p>
      <Link to={data.to}>
        <button className="transition-colors text-white hover:bg-white hover:text-primary">
          <span>Buy now</span>
          {/* <CartIcon /> */}
          <FaShoppingCart />
        </button>
      </Link>
    </div>
  )
}

export default CarouselItem
