import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Clock } from "../../../images/icons/icons"

import * as styles from "../../../styles/recipes.module.css"

export default function RecipeList({ list, handleChange }) {
  return (
    <div className="new-modal mt-3 max-h-lg overflow-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {list.map(item => (
        <div key={item.id} className={`flex flex-col`}>
          {/* Recipe Image */}
          <div>
            {/* <img
              className={"w-full " + styles.img}
              src={item?.image?.asset?.url || item?.image}
              alt="recipes"
            /> */}
            <GatsbyImage
              imgClassName={styles.img}
              image={getImage(item.image.asset.localFile)}
              alt={item.nameRU}
            />
          </div>
          <div className="">
            {/* Recipe Name */}
            <h1 className="text-2xl text-black font-bold my-3">
              {item?.nameRU || item?.name || item?.title}
            </h1>
            <div>
              {/* Recipe Data */}
              <p className="text-sm leading-4 text-gray-light2 text-opacity-50 text-left">
                {item?.description || (
                  <>
                    <div className="flex items-center">
                      <span>
                        <b>Калории:</b> {item?.calories}
                      </span>
                      {item?.inRange && (
                        <span className="text-xs text-primary ml-1">
                          - close to calorie range
                        </span>
                      )}
                    </div>
                    <b>Белки:</b> {item?.protein} <br />
                    <b>Углеводы:</b> {item?.carbs} <br />
                    <b>Жиры:</b> {item?.fat}
                  </>
                )}
              </p>
              <div className="flex justify-between my-4 items-center">
                {/* <span className="text-xs text-gray-light2 text-opacity-50 leading-3 mx-2">
                  {item?.unit} kcal
                </span> */}
                <div className="flex items-center">
                  <Clock />
                  {/* Time and Date */}
                  <span className="text-xs text-gray-light2 text-opacity-50 leading-3 mx-2">
                    {item?.mealTime?.nameRU ||
                      item?.mealTime?.name ||
                      item?.time}
                  </span>
                </div>
                <button
                  className={
                    "bg-white text-xs font-light text-gray-light2 text-opacity-60 bor leading-3 border-opacity-30 px-4 py-2 border border-black rounded hover:text-white hover:border-transparent bg-gradient-to-r hover:from-gradient1 hover:to-gradient2 transition-all  "
                  }
                  onClick={() => handleChange(item)}
                >
                  Заменить блюдо
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
