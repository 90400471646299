import * as React from "react"

function DownloadIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 22l2.5-2M12 22v-9 9zm0 0l-2.5-2 2.5 2zM5.034 9.117A4.002 4.002 0 006 17h1"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.83 7.138a5.5 5.5 0 00-10.795 1.98S5.188 10 5.5 10.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 17a5.002 5.002 0 004.285-7.571 5 5 0 00-5.455-2.291L14.5 7.5"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DownloadIcon
