import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

// components
import { SubTitle } from ".."
import Todo from "./Todo"
import Spinner from "../Spinner"

// api
import { getTaskList, updateUserTaskList } from "../../utils/requests"

// helper
import {
  getListOfTasks,
  addTask,
  removeTask,
  findTask,
  getTotalCount,
} from "./utils"

// hooks
import { useDebounce } from "../../hooks/useDebounce"

// assets
import { MdOutlineAddShoppingCart } from "react-icons/md"

let fetchCalls = []

const TodoList = ({
  selectedDay,
  userData,
  setUserData,
  isDisable,
  setIsDisable,
}) => {
  const [taskList, setTaskList] = useState([])
  const [tempList, setTempList] = useState([])
  // const [networkCallCount, setNetworkCallCount] = useState(0)

  // const debouncedValue = useDebounce(networkCallCount, 1000)

  useEffect(() => {
    if (Number.isInteger(selectedDay)) {
      reFetch(selectedDay)
    }
  }, [selectedDay])

  // useEffect(() => {
  //   if (Number.isInteger(debouncedValue) > 0 && fetchCalls.length > 0) {
  //     Promise.all(fetchCalls)
  //       .then(_ => {
  //         setIsDisable(false)
  //         setNetworkCallCount(0)
  //         fetchCalls = []
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //   }
  // }, [debouncedValue])

  const reFetch = async selectedDay => {
    try {
      if (tempList.length === 0) {
        const res = await getTaskList()
        const _taskList = res.data
        const _tasks = getListOfTasks(
          _taskList,
          userData?.taskList,
          selectedDay
        )
        setTaskList(_tasks)
        setTempList(_taskList)
        setUserData({
          ...userData,
          // totalTasksCount: _tasks?.length || 0,
          totalTasksCount: getTotalCount(_taskList),
        })
      } else {
        const _tasks = getListOfTasks(tempList, userData?.taskList, selectedDay)

        setTaskList(_tasks)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleTaskClick = (_task, _isAdd) => {
    let newTaskList = []
    if (_isAdd) {
      newTaskList = addTask(_task, userData?.taskList || [], selectedDay)
    } else {
      newTaskList = removeTask(_task, userData?.taskList || [], selectedDay)
    }

    const _tasks = getListOfTasks(tempList, newTaskList, selectedDay)
    console.log({ userData, newTaskList, _tasks })

    // setIsDisable(true)
    updateUserTaskList(userData._id, findTask(newTaskList, selectedDay))
      .then(() => {
        setTaskList(_tasks)
        setUserData({
          ...userData,
          taskList: newTaskList,
        })

        // setIsDisable(false)
      })
      .catch(err => {
        console.log(err)
      })

    // const promise = updateUserTaskList(
    //   userData._id,
    //   findTask(newTaskList, selectedDay)
    // )
    //   .then(() => {
    //     setTaskList(_tasks)
    //     setUserData({
    //       ...userData,
    //       taskList: newTaskList,
    //     })

    //     return { _tasks, newTaskList }
    //   })
    //   .catch(err => {
    //     console.log(err)
    //   })

    // fetchCalls.push(promise)
    // setNetworkCallCount(count => count + 1)

    // if (!isDisable) setIsDisable(true)
  }

  return (
    <div className="mt-8 md:mt-[138px]">
      <div className="flex flex-col gap-2 items-start sm:gap-0 sm:flex-row sm:items-center justify-between">
        <SubTitle title2="Задачи на день:" />
        {/* <Link
          to="/shopping-list"
          className="flex items-center gap-2.5 self-end sm:self-auto"
        >
          <span className="text-sm font-semibold text-txt-dark">
            Shopping List
          </span>
          <MdOutlineAddShoppingCart className="text-primary w-5 h-5 " />
        </Link> */}
      </div>
      <div className="relative mt-8 py-8 px-6 border border-gray-EEEEEE rounded-2xl grid grid-cols-1 md:grid-cols-2 gap-3">
        {!taskList && taskList?.length === 0 && (
          <p className="text-sm text-txt-md my-2">Loading....</p>
        )}
        {taskList &&
          taskList.length > 0 &&
          taskList.map((task, i) => (
            <Todo
              key={`${selectedDay}-${i}`}
              id={`${selectedDay}-${i}`}
              data={task}
              toRight={i % 2 !== 0}
              hanldeClick={handleTaskClick}
            />
          ))}
        {!taskList && (
          <p className="text-base text-medium text-txt-dark">
            NO Tasks for Today
          </p>
        )}

        {/* {isDisable && (
          <div className="flex items-center justify-center absolute inset-[0] bg-[rgba(177,177,177,.25)] z-10 sm:rounded-2xl">
            <Spinner />
          </div>
        )} */}
      </div>
    </div>
  )
}
export default TodoList
