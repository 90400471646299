import React from "react"
import { Link } from "gatsby"

// components
import Container from "../Common/Container/Container"

// assets
import TargetIcon from "../../images/icons/Target"
// import ArrowImg from "../../images/arrow.png"
import DotsIcon from "../../images/icons/Dots"
import * as styles from "./hero.module.css"

// animation
import FadeIn from "../Animation/FadeIn"

import { StaticImage } from "gatsby-plugin-image"

export function ArrowImg() {
  return <StaticImage src="../../images/arrow.png" alt="Arrow Hero" />
}

export function HeroImg2() {
  return <StaticImage src="../../images/hero-img.png" alt="Esteekey Hero" />
}

export function HeroImgMob2() {
  return (
    <StaticImage
      src="../../images/hero-img-mob.png"
      alt="Esteekey Hero"
      style={{ borderRadius: "1rem" }}
    />
  )
}

const Hero = () => {
  return (
    <div className="py-8 px-4 sm:px-0 lg:py-32 bg-light overflow-hidden sm:overflow-visible">
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:block relative">
          <div className="relative  md:w-hero-lg xl:w-hero-xl">
            <FadeIn>
              <DotsIcon className="sm:hidden absolute -top-6 -left-1.5" />
              <h1 className="font-bold text-txt-dark">
                <span className="text-extra md:text-4xl md:leading-12 xl:text-hd-xl">
                  Structure
                </span>
                <br className="sm:hidden" />
                <span className="text-extra  inline-block md:ml-4 md:capitalize  md:text-4xl md:leading-12 xl:text-hd-xl">
                  your
                </span>
                <span className="text-extra text-primary ml-2 inline-block md:capitalize md:ml-2 md:text-4xl md:leading-12 xl:text-hd-xl">
                  body
                </span>
                <span className="block w-32 text-2xl text-txt-med font-normal opacity-40 mt-3 md:mt-0 md:w-auto md:font-bold md:text-txt-dark md:capitalize md:text-4xl md:leading-12  md:opacity-100 xl:text-hd-xl">
                  from a <span className="md:text-primary">scientific</span>{" "}
                  point of view
                </span>
              </h1>
              <div className="ml-9 my-8 sm:hidden sm:m-0">
                <ArrowImg />
              </div>
              {/* <img
              src={ArrowImg}
              alt="arrow"
              className="ml-9 my-8 sm:hidden sm:m-0"
            /> */}
              <p className="text-txt-md text-base lg:mt-4 lg:max-w-86 lg:text-sm xl:mt-5 xl:text-lg xl:max-w-md">
                <span className="font-bold text-hd-sm block lg:inline lg:text-sm xl:text-lg">
                  I am <span className="text-primary">Miya</span> and...
                </span>
                <spna className="text-base opacity-40 lg:opacity-100 lg:text-sm xl:text-lg">
                  let me teach you all the secrets of a beautiful body.
                </spna>
              </p>
              <Link to="/plans">
                <button className="transition-colors w-full bg-primary text-white flex items-center justify-center gap-2.5 py-4 pl-12 pr-4 mt-7 rounded-xl sm:w-auto lg:mt-10 lg:py-3.5 lg:px-4 lg:rounded-lg lg:shadow-btn-md xl:py-4.5 xl:px-8 xl:shadow-btn-xl  xl:rounded-10 hover:bg-white hover:text-primary">
                  {/* <FiTarget className="order-1" /> */}
                  <TargetIcon className="order-1" />
                  <span className="font-semibold lg:text-sm xl:text-lg ">
                    Start your journey
                  </span>
                </button>
              </Link>
            </FadeIn>
          </div>
          <div
            className={
              styles.hero_img +
              " hidden absolute bottom-44 -right-10 w-68 sm:inline lg:w-hero-img-lg  xl:w-auto lg:-top-32 lg:right-2.5 xl:-top-24 xl:right-0"
            }
          >
            <FadeIn>
              <HeroImg2 alt="Esteekey hero section" />
            </FadeIn>
          </div>
          <div
            className={
              styles.hero_img +
              " absolute bottom-44 -right-10 w-68 sm:hidden lg:w-hero-img-lg  xl:w-auto lg:-top-32 lg:right-2.5 xl:-top-24 xl:right-0"
            }
          >
            <HeroImg2 alt="Esteekey hero section" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Hero
