import React, { useState, useEffect } from "react"
import { motion, useAnimation } from "framer-motion"

// components
import MealItem from "./MealItem"

// utils
import { addOrder } from "../../utils/helper"

// animations
import FadeTop from "../Animation/FadeTop"

// xl:grid-cols-6 md:grid-cols-2

const MealPlan = ({ list, dark, toggle }) => {
  const [mealList, setMealList] = useState([])

  useEffect(() => {
    if (list) {
      let orderList = list.map(item => ({
        ...item,
        order: addOrder(item?.mealTime.name || item?.time),
      }))
      orderList = orderList.sort((a, b) => a.order - b.order)

      let uniqueList = removeDuplicate(orderList)
      setMealList(uniqueList)
    }
  }, [list])

  const removeDuplicate = _list => {
    let uniqueList = _list.filter(
      (value, index, self) => index === self.findIndex(t => t._id === value._id)
    )

    return uniqueList
  }

  return (
    // <div className="grid grid-cols-1  md:grid-cols-auto gap-6 md:gap-y-28 xl:gap-x-7">
    <div className="grid grid-cols-1  md:grid-cols-auto gap-x-6 gap-y-6 md:gap-x-2 md:gap-y-28">
      {mealList.length > 0 &&
        mealList.map((item, i) => (
          <MealItem
            key={item._id}
            i={i}
            data={item}
            dark={dark}
            toggle={toggle}
          />
        ))}
    </div>
  )
}

export default MealPlan
