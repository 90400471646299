import React, { forwardRef, useState, useEffect } from "react"
// import { Player, ControlBar, BigPlayButton } from "video-react"
import ReactPlayer from "react-player"

// assets
import CircleProgress from "../../images/loading.svg"

const WorkoutPlayer = forwardRef(({ url, isPlaying, toggle, title }, ref) => {
  const [videoUrl, setVideoUrl] = useState("")
  const [buffered, setBuffered] = useState(false)

  // return (
  //   <Player
  //     ref={ref}
  //     fluid={false}
  //     height="100%"
  //     width="100%"
  //     aspectRatio={"4:3"}
  //   >
  //     <source src={url} />
  //     <BigPlayButton disabled />
  //     <ControlBar disableCompletely></ControlBar>
  //   </Player>
  // )

  useEffect(() => {
    // if (url) setVideoUrl(url)
  }, [url])

  const bunnyUrl = `https://iframe.mediadelivery.net/embed/65143/${url}`

  return (
      <div
        style={{ paddingTop: "56.25%", position: "relative" }}
      >
        <iframe src={bunnyUrl}
          loading="lazy" style={{ border: "none", position: "absolute", top: "0", height: "100%", width: "100%" }}
          allow="accelerometer; gyroscope; encrypted-media; picture-in-picture; autoplay: false" allowfullscreen="true">
        </iframe>
      </div>
  )
})

export default WorkoutPlayer
