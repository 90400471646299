import React, { useContext } from "react"
import { Link } from "gatsby"

// components
import Container from "../Common/Container/Container"
import Tag from "../Common/Tag/Tag"
import VideoPlayer from "../LatestVideos/VideoPlayer"
import DownloadList from "../Download/DownloadList"
import NoAuth from "../Common/NoAuth/NoAuth"
import NoAccess from "../Common/NoAccess/NoAccess"

// utils
import { getUserData } from "../../utils/requests"

import { BiArrowBack } from "react-icons/bi"

import UserContext from "../../context/UserAuth"

const SingleVideo = ({
  title,
  overview,
  tags,
  videoUrl,
  videoPreview,
  list,
  mockupList,
  url,
}) => {
  const { isAccess, isAuthenticated, isLoading } = useContext(UserContext) || {
    isAccess: false,
    isAuthenticated: false,
    isLoading: false,
  }

  if (isLoading) {
    return (
      <Container>
        <h3>Loading...</h3>
      </Container>
    )
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <NoAuth />
      </Container>
    )
  }

  if (!isAccess) {
    return (
      <Container>
        <NoAccess />
      </Container>
    )
  }

  return (
    <div className="py-8 px-4 sm:px-0 sm:py-12 xl:py-16">
      <Container>
        <h1 className="text-xl text-txt-dark font-bold capitalize mb-2 sm:text-3xl sm:mb-3.5">
          {title}
        </h1>
        <div className="flex flex-wrap  gap-1 mb-4 sm:mb-8 sm:gap-2.5 xl:gap-2">
          {tags.map((item, i) => (
            <Tag key={i} label={item.name} />
          ))}
        </div>
        <div className="flex flex-col-reverse sm:flex-col gap-5 sm:gap-8 xl:block">
          <p className="hidden mt-2.5 text-sm  text-txt-med opacity-50 sm:text-base xl:hidden">
            {overview}
          </p>
          <div className="w-full relative">
            <VideoPlayer
              url={videoUrl}
              preview={videoPreview}
              overlay
              hasFullScreen
            />
          </div>
        </div>
        <div className="flex gap-7 flex-col xl:flex-row">
          <div className="flex-grow">
            <div className="mt-5 sm:mt-10 hidden">
              <h2 className="text-xl font-bold text-txt-dark mb-2.5 md:font-semibold md:text-1/5xl">
                Additional video description
              </h2>
              <p className="text-sm opacity-70 text-txt-light whitespace-pre-line md:text-base md:opacity-100">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Placerat sit enim ultricies egestas sit amet, erat turpis.
                Egestas faucibus a etiam vestibulum malesuada. Laoreet egestas
                odio enim et arcu lectus tempus. Sed eget eget vitae sapien
                nisl. Lacus, auctor adipiscing morbi velit, pellentesque.
                Facilisi erat suspendisse ultrices velit tortor, tincidunt
                semper in. Dolor lacus, vitae sit pellentesque aenean morbi
                integer vitae. At ac faucibus volutpat tellus. Turpis curabitur
                amet eu odio enim. Eleifend molestie sed arcu, vel in adipiscing
                in est. Ut condimentum placerat semper condimentum proin turpis
                venenatis risus id.
              </p>
            </div>
            <div className="mt-6 sm:mt-10 hidden">
              <h2 className="text-xl font-bold text-txt-dark mb-2.5 md:font-semibold md:text-1/5xl">
                more info for the video
              </h2>
              <p className="text-sm opacity-70 text-txt-light whitespace-pre-line md:text-base md:opacity-100">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Placerat sit enim ultricies egestas sit amet, erat turpis.
                Egestas faucibus a etiam vestibulum malesuada. Laoreet egestas
                odio enim et arcu lectus tempus. Sed eget eget vitae sapien
                nisl. Lacus, auctor adipiscing morbi velit, pellentesque.
                Facilisi erat suspendisse ultrices velit tortor, tincidunt
                semper in. Dolor lacus, vitae sit pellentesque aenean morbi
                integer vitae. At ac faucibus volutpat tellus. Turpis curabitur
                amet eu odio enim. Eleifend molestie sed arcu, vel in adipiscing
                in est. Ut condimentum placerat semper condimentum proin turpis
                venenatis risus id.
              </p>
            </div>
          </div>
          <div className="w-full flex-shrink-0  sm:mt-10 xl:w-1/3">
            <h2 className="text-xl text-txt-dark font-semibold sm:text-1/5xl  sm-6 xl:mb-5">
              Скачайте файлы к этому видео
            </h2>
            {list.length > 0 ? (
              <DownloadList list={list} />
            ) : (
              <p className="text-primary text-sm md:text-base mt-0 md:-mt-2.5">
                There are no downlodable files for this video
              </p>
            )}
          </div>
        </div>
        <div className="hidden sm:flex mt-10 justify-end sm:mt-14">
          <Link to={"/videos"}>
            <button className="py-2.5 px-4 bg-gray-100 rounded-md cursor-pointer text-sm text-gray-500 font-medium capitalize">
              Go Back
            </button>
          </Link>
        </div>
        <div className="sm:hidden fixed bottom-20 left-2 rounded-full w-10 h-10 bg-white shadow-btn-md z-10 flex items-center justify-center">
          <Link to={"/videos"}>
            <BiArrowBack />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default SingleVideo
