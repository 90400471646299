import React from "react"

// components
import Container from "../Common/Container/Container"
import Post from "./Post"

// mockup
import mockup from "../../utils/data"

const BlogPost = ({ list }) => {
  return (
    <div className="grid grid-cols-1 gap-6 sm:gap-5 sm:grid-cols-2 xl:grid-cols-1">
      {list.map(item => (
        <Post key={item.id} data={item} />
      ))}
    </div>
  )
}

export default BlogPost
