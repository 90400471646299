import * as React from "react"

function PlayIcon(props) {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.843 14.133l-10.34 6C7.627 20.64 6.5 20.024 6.5 19V7c0-1.023 1.125-1.64 2.004-1.13l10.34 5.999a1.302 1.302 0 010 2.264z"
        fill={props?.fill || "#fff"}
      />
    </svg>
  )
}

export default PlayIcon
