import React from "react"
import { Link } from "gatsby"
import { AiOutlineClockCircle } from "react-icons/ai"

const NoAccess = () => {
  return (
    <div
      className="flex flex-col items-center justify-center"
      style={{
        height: "calc(100vh - 157.66px)",
      }}
    >
      <p className="text-txt-light text-base font-medium break-all md:break-normal px-6 2xl:px-0">
        <p>Привет, красотка! Доступ к платформе открывается 15-ого Августа в</p>
        <span className="flex mt-6">
          <AiOutlineClockCircle className="mt-1 mr-2" />
          <p> 10 утра по Москве</p>
        </span>
        <span className="flex">
          <AiOutlineClockCircle className="mt-1 mr-2" />
          <p> 9 AM - Warsaw</p>
        </span>
        <span className="flex">
          <AiOutlineClockCircle className="mt-1 mr-2" />
          <p> 8 AM - London</p>
        </span>
        <span className="flex">
          <AiOutlineClockCircle className="mt-1 mr-2" />
          <p> 3 AM - NYC</p>
        </span>
        <span className="flex">
          <AiOutlineClockCircle className="mt-1 mr-2" />
          <p> 12 AM - LA</p>
        </span>
        <div className="mt-6"></div>
        <p>
          На платформе, тебя ожидает видео, где мы расскажем как пользоваться
          платформой и как устанавливать его к себе на телефон. До встречи!
        </p>
      </p>
      {/* <Link to="/plans">
        <button className="bg-primary text-white p-2">
          Purchase Course Now
        </button>
      </Link> */}
    </div>
  )
}

export default NoAccess
