import * as React from "react";

function CirclePlusIcon(props) {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={34.5}
        cy={34.5}
        r={33.5}
        fill="#BABFD6"
        stroke="#fff"
        strokeWidth={2}
      />
      <path stroke="#fff" strokeWidth={3} d="M18 33.5h34M35.5 51V19" />
    </svg>
  );
}

export default CirclePlusIcon;
