import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"

// components
import { Header, Recipes, Footer, BottomNavbar } from "./index"
import SubTitle from "./Common/SubTitle/SubTitle"
import Container from "./Common/Container/Container"
import Search from "./Common/Search/Search"
import Tag from "./Common/Tag/Tag"

// const tags = ["Blog", "News", "Promotion", "Nutrition", "All"]
let tagList = []

export const Blog = ({ mockup, category }) => {
  const [list, setList] = useState([])
  const [tempList, setTempList] = useState([])
  const [activeTag, setActiveTag] = useState("Все")
  const [tags, setTags] = useState([])

  useEffect(() => {
    if (mockup) {
      let reFactorList = mockup.map(item => ({
        id: item.node.id,
        title: item.node.title,
        description: item.node.preview,
        unit: 410,
        time: item.node.publishedAt,
        image: item.node.mainImage,
        alt: item.node.title,
        to: `/blog/${item.node.slug.current}`,
        tags: ["Blog, Post"],
        category: "Blog",
      }))

      setList(reFactorList)
      setTempList(reFactorList)
    }
  }, [mockup])

  useEffect(() => {
    if (category) {
      let newList = category.map(item => item.title)
      newList.push("Все")
      setTags(newList)
    }
  }, [category])

  const handleSearch = _value => {
    if (activeTag !== "Все") {
      if (_value !== "") {
        setList(filterList(list, _value))
      } else {
        setList(tagList)
      }
    } else {
      if (_value !== "") {
        setList(filterList(tempList, _value))
      } else {
        setList(tempList)
      }
    }
  }

  const filterList = (_list, _value) => {
    return _list.filter(
      item =>
        item.title.toLowerCase().indexOf(_value.toLowerCase()) !== -1 ||
        item.description.toLowerCase().indexOf(_value.toLowerCase()) !== -1 ||
        item.tags.findIndex(tag =>
          tag.toLowerCase().includes(_value.toLowerCase())
        ) > -1
    )
  }

  const checkActive = _value => {
    return activeTag === _value
  }

  const changeTag = _label => {
    setActiveTag(_label)

    if (_label !== "Все") {
      let filter = tempList.filter(
        item =>
          item.tags.findIndex(tag =>
            tag.toLowerCase().includes(_label.toLowerCase())
          ) > -1
      )

      tagList = filter
      setList(filter)
    } else {
      setList(tempList)
    }
  }

  return (
    <div className="pt-[69px] xl:pt-0">
      <Container>
        <div className="mt-6 xl:mt-16 mb-6  mx-4 sm:mx-0 md:mb-12">
          <SubTitle title1="Blog" title2="Posts" text="Written for you" />
        </div>
        <div className="flex justify-between items-center my-5 flex-col-reverse mx-4 sm:mx-0 md:flex-row gap-5 md:gap-0 md:my-10 ">
          <div className="flex items-center gap-2">
            {tags.map((value, i) => (
              <Tag
                key={i}
                label={value}
                isActive={checkActive(value)}
                changeTag={changeTag}
              />
            ))}
          </div>
          <Search handleSearch={handleSearch} />
        </div>
      </Container>

      <Recipes list={list} blog={false} />
    </div>
  )
}
