import React from "react"
import axios from "axios"
import fileDownload from "js-file-download"
import { StaticImage } from "gatsby-plugin-image"
import { toast } from "react-toastify"

import "./PdfDownload.css"

import Container from "../Common/Container/Container"

export function PdfSMImg() {
  return <StaticImage src="../../images/img-pdf-sm.png" alt="Pdf" />
}

export function PdfImg() {
  return (
    <StaticImage src="../../images/pdf.jpg" alt="Pdf" width="100" height="99" />
  )
}

const pdfList = [
  {
    fileId: "1lo5h_Cvpw-h_WSuz4_4oOEFDBDrSPcFh",
    title: "Гайд Питание",
  },
  {
    fileId: "1Jetij1_Y9GXLkLodykZA8mVP2bAGeP2G",
    title: "Главные вопросы по курсу",
  },
  {
    fileId: "1eiDyYoUMETM-jkvv-8XiMR8jt12Oqfxu",
    title: "Дыхательные упражнения",
  },
  {
    fileId: "1JLfed9rUKM-iDD3aXwhwWS0_g7HhAU9m",
    title: "Информация о конкурсе",
  },
  {
    fileId: "1uqG64RnUnm0rF8PoEOkHo0yy3Fk6TxlD",
    title: "Массажи лица",
  },
  {
    fileId: "1Oqk71Wf0eRdBova0Z9rM1wCTchNb2Q5B",
    title: "Осанка",
  },
  {
    fileId: "1HXJmqvs9SyduP_yFU7gNrc6aG2DObhGR",
    title: "Тест уровень стресса",
  },
  {
    fileId: "1vL0tFy3oZpGD8ZBkYYeQtJxcK3a4hrqG",
    title: "Трекер воды",
  },
  {
    fileId: "1SKIBEu-4XczEabw-hSGbqmbg7WyaLEVK",
    title: "Трекер привычек",
  },
  {
    fileId: "1qNr2eON7nxH56qKpJcDrb1lhDtw_MT5P",
    title: "Тренировки",
  },
  {
    fileId: "1r_V1gg04XchgSrmB-0R8KZ3XGEdTJKBp",
    title: "Уход за телом",
  },
]

export default function PdfDownload({ list }) {
  // const handleClick = (_url, _name) => {
  //   console.log({ _url, _name })
  //   axios
  //     .get(_url, {
  //       responseType: "blob",
  //     })
  //     .then(res => {
  //       fileDownload(res.data, _name)
  //     })
  //     .catch(err => {
  //       console.log("error", err)
  //       toast.error(err.message)
  //     })
  // }

  // const getType = (_name, _url) => {
  //   let arr = _url.split(".")

  //   return `${_name.replace(" ", "")}.${arr[arr.length - 1]}`
  // }

  return (
    <Container>
      <div className="bg-white mt-10">
        {/* mb-20 */}
        <div class="grid grid-cols-1 sm:grid-cols-2  md:grid-cols-4 gap-5 mx-4 sm:mx-0">
          {(pdfList.length > 12 ? pdfList.slice(0, 12) : pdfList).map(
            (item, index) => (
              <a
                key={index}
                href={`https://drive.google.com/uc?export=download&id=${item.fileId}`}
                download
                className="cursor-pointer w-full h-full grid grid-cols-3 card-pdf rounded-3xl bg-white text-blue300 sm:grid-cols-none sm:bg-gray100 xl:h-72 2xl:h-80 transition-opacity duration-100 hover:opacity-80"
                // onClick={() =>
                //   handleClick(
                //     item?.file?.asset?.url || item.url,
                //     getType(item.titleRU, item?.file?.asset?.url)
                //   )
                // }
              >
                <div className="col-span-2 sm:col-span-1 inline-block px-4 py-4 sm:px-6 sm:py-4">
                  <div className="font-semibold sm:text-black sm:mb-2 md:text-sm xl:text-2xl">
                    {item.title}
                    <br className="hidden md:block" />
                  </div>
                  <p className="card-title text-xs line-clamp-1 sm:line-clamp-none">
                    Click here to download this PDF
                  </p>
                </div>
                <div className="flex items-center mr-5 justify-end sm:hidden">
                  <PdfSMImg />
                </div>
                <div className="hidden sm:flex sm:justify-end sm:mr-5">
                  <div className="card-img sm:h-15 sm:w-20 lg:w-28 2xl:w-32 opacity-10">
                    <PdfImg />
                  </div>
                </div>
              </a>
            )
          )}
        </div>
      </div>
    </Container>
  )
}
