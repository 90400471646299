import React from "react"
import { Link } from "gatsby"
import { Home, Dumbell, Diet, Video } from "../images/icons/icons"

import * as styles from "../styles/navBar.module.css"
import * as style from "../styles/userInput.module.css"

export default function BottomNavbar({ activePage }) {
  // const [page, setPage] = useState(activePage)
  return (
    <div>
      <div className="flex gap-6 mt-10 overflow-x-auto md:overflow-visible overflow-hidden justify-center md:justify-center fixed bottom-0 w-full md:hidden">
        <div className={"flex-100 pt-4  ml-0 " + style.userInputPage}>
          <div
            className={
              "flex-10 h-16 rounded-t-3xl rounded-r-3xl grid grid-cols-4 gap-10 p-4 pt-3 ml-0 md:my-4 shadow-navbar bg-white rounded-br-sm  rounded-bl-sm"
            }
          >
            <Link className="flex justify-center" to="/">
              <div
                className={`w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer  ${
                  activePage === "home" ? styles.navGradient : null
                }`}
              >
                <Home />
              </div>
            </Link>
            <Link className="flex justify-center" to="/dashboard">
              <div
                className={`w-10 h-10 rounded-full bg-white flex justify-center items-center cursor-pointer transition-all ${
                  activePage === "dashboard" ? styles.dumbell : null
                }`}
              >
                <Dumbell />
              </div>
            </Link>
            <Link className="flex justify-center" to="/recipes">
              <div
                className={`w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer  ${
                  activePage === "recipes" ? styles.navGradient : null
                }`}
              >
                <Diet />
              </div>
            </Link>
            <Link className="flex justify-center" to="/videos">
              <div
                className={`w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer  ${
                  activePage === "videos" ? styles.navGradient : null
                }`}
              >
                <Video />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
