import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
// component
import Container from "../Common/Container/Container"
import ClockDarkIcon from "../../images/icons/ClockDark"
import Item from "./Item"
import NoAuth from "../Common/NoAuth/NoAuth"
import NoAccess from "../Common/NoAccess/NoAccess"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

// util
import { changeUnit, convertToFraction } from "../../utils/helper"

import UserContext from "../../context/UserAuth"

import { BiArrowBack } from "react-icons/bi"

const RecipeOpen = ({
  title,
  overview,
  image,
  ingredients,
  content,
  steps,
  calories,
  carbs,
  fat,
  protein,
  mealTime,
  servings,
}) => {
  const { isAuthenticated, isAccess, isLoading } = useContext(UserContext) || {
    isAuthenticated: false,
    isAccess: false,
    isLoading: false,
  }

  const [toggle, setToggle] = useState(true)
  const [list, setList] = useState([])

  useEffect(() => {
    if (ingredients) {
      setList(ingredients)
    }
  }, [ingredients])

  const handleClick = () => {
    setList(changeUnit(list))
  }

  if (isLoading) {
    return (
      <Container>
        <h3>Loading...</h3>
      </Container>
    )
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <NoAuth />
      </Container>
    )
  }

  if (!isAccess) {
    return (
      <Container>
        <NoAccess />
      </Container>
    )
  }

  const convert = (_unit, _amount) => {
    _amount *= servings
    if (_unit === "teaspoon" || _unit === "tablespoon" || _unit === "scoop") {
      if (_amount % 1 !== 0) return convertToFraction(_amount)
    }

    return _amount
  }

  return (
    <div className="py-8 px-4 sm:px-0 sm:py-12 xl:py-16">
      <Container>
        <div className="flex flex-col-reverse md:flex-col-reverse lg:flex-col">
          <div>
            <div className="text-txt-dark text-2xl font-bold my-3">{title}</div>
            <div className="flex items-center">
              <ClockDarkIcon width={16} height={16} />
              <span className="text-base text-txt-med text-opacity-50 leading-3 mx-2">
                {mealTime}
              </span>
            </div>
          </div>
          {/* <img
            src={image}
            alt="blog post"
            className="w-full  object-cover object-center rounded-xl md:h-100 md:mt-7 lg:my-8 xl:h-125"
            style={{
              boxShadow: "0px 12px 20px rgba(123, 165, 199, 0.15)",
            }}
          /> */}
          <GatsbyImage
            // className="!object-cover !rounded-xl md:!mt-7 lg:!my-8"
            className="rounded-xl md:h-100 md:mt-7 lg:my-8 xl:h-125"
            imgClassName="rounded-xl"
            style={{
              boxShadow: "0px 12px 20px rgba(123, 165, 199, 0.15)",
            }}
            image={getImage(image.localFile)}
            alt={title}
          />
        </div>

        <div className="flex gap-7 flex-col xl:flex-row">
          <div className="flex-grow xl:w-1/2">
            <div className="mt-5 md:mt-0">
              <div className="text-txt-dark text-xl font-medium mb-4">
                Макронутриенты
              </div>
              <Item
                label="Калории"
                value={`${Math.round(calories * servings)} ккал`}
              />
              <Item
                label="Белки"
                value={`${Math.round(protein * servings)} г`}
              />
              <Item
                label="Углеводы"
                value={`${Math.round(carbs * servings)} г`}
              />
              <Item label="Жиры" value={`${Math.round(fat * servings)} г`} />
            </div>
            {steps?.length > 0 && (
              <div className="w-full mt-12">
                <h2 className="text-2xl font-bold text-txt-dark mb-2.5 md:font-semibold md:text-1/5xl">
                  Инструкция:
                </h2>
                <ol>
                  {steps.map((item, i) => (
                    <li key={i}>
                      <span className="font-bold">{i + 1}. </span>
                      <span>{item}</span>
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
          {/* xl:w-1/3 */}
          <div className="w-full flex-shrink-0 xl:w-1/2">
            {list.length > 0 ? (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div className="text-txt-dark text-xl font-medium ">
                    Ингредиенты
                  </div>
                  <div
                    onClick={handleClick}
                    className="hidden text-gray-500 text-sm font-medium leading-5 text-right cursor-pointer md:text-base"
                  >
                    Change Units
                  </div>
                </div>
                {list.map(ingredient => (
                  <div
                    key={ingredient.id}
                    className="flex gap-4 items-center my-1"
                  >
                    <div className="text-xs font-medium whitespace-nowrap text-txt-light text-left md:text-base">
                      {ingredient.name?.nameRU || ingredient.name?.name}
                    </div>
                    <div className="w-2/3 border-t border-gray-EEEEEE flex-grow"></div>
                    <div className="text-xs font-medium whitespace-nowrap text-txt-light text-right md:text-base">
                      {`${
                        convert(
                          ingredient?.unit?.nameRU || ingredient?.unit,
                          ingredient?.amount
                        ) || ""
                      } ${ingredient?.unit?.nameRU || ingredient?.unit}`}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-primary text-base">
                There are no ingredients for this recipe.
              </p>
            )}
          </div>
        </div>
        <div className="hidden w-full mt-6 sm:flex justify-end items-center md:flex-row">
          <Link to="/recipes">
            <button className=" min-w-24 h-9  rounded-md text-opacity-50 text-txt-light text-xs font-normal px-4 py-2 lg:bg-gray-100">
              Вернуться назад
            </button>
          </Link>
        </div>
        <div className="sm:hidden fixed bottom-20 left-2 rounded-full w-10 h-10 bg-white shadow-btn-md z-10 flex items-center justify-center">
          <Link to="/recipes">
            <BiArrowBack />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default RecipeOpen
