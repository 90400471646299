import React from "react"
import { Link } from "gatsby"

// assets
import ClockDarkIcon from "../../images/icons/ClockDark"

const Post = ({ data }) => {
  return (
    <Link to={`/blog/${data?.node?.slug?.current}`}>
      <div className="flex gap-6 items-center sm:gap-4">
        <div className="overflow-hidden w-20 h-24 rounded-xl sm:w-28 sm:h-20 sm:rounded-lg flex-shrink-0">
          <img
            src={data?.node?.mainImage?.asset?.url}
            alt={data.node.title}
            className="object-cover object-center h-full"
          />
        </div>
        <div>
          <h3 className="text-lg opacity-70 font-bold  text-txt-dark sm:font-semibold sm:text-base sm:opacity-100">
            {data?.node?.title}
          </h3>
          <p className="text-xs text-gray-500 line-clamp-2   sm:text-sm xl:line-clamp-3">
            {data?.node?.preview}
          </p>
          <p className="sm:hidden flex items-center gap-1 mt-4">
            <ClockDarkIcon />
            <span className="text-xss text-txt-med opacity-40">
              {data?.node?.date}
            </span>
          </p>
        </div>
      </div>
    </Link>
  )
}

export default Post
