import React from "react"

// component
import DownloadItem from "./DownloadItem"

const DownloadList = ({ list }) => {
  return (
    <div className="grid grid-cols-1 gap-6 sm:gap-5 sm:grid-cols-2 xl:grid-cols-1">
      {list.map((item, i) => (
        <DownloadItem data={item} index={i + 1} />
      ))}
    </div>
  )
}

export default DownloadList
