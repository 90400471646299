import { useState, useEffect, useRef } from "react"

export const useAudio = url => {
  const [audio, setAudio] = useState()
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(null)
  const ref = useRef()

  const toggle = _flag => setPlaying(_flag)

  useEffect(() => {
    const _audio = new Audio(url)
    _audio.src = url
    _audio.muted = false
    _audio.preload = "none"
    setAudio(_audio)
  }, [])

  useEffect(() => {
    return () => {
      ref.current.pause()
    }
  }, [])

  useEffect(() => {
    if (audio) playing ? audio.play() : audio.pause()
  }, [playing])

  useEffect(() => {
    if (audio) {
      audio.addEventListener("ended", () => setPlaying(false))
      ref.current = audio
    }
    return () => {
      if (audio) audio.removeEventListener("ended", () => setPlaying(false))
    }
  }, [audio])

  useEffect(() => {
    if (audio)
      audio.addEventListener("loadeddata", () => {
        console.log(audio)
        setDuration(audio.duration)
      })
  }, [audio])

  return [audio, playing, duration, toggle, setAudio]
}
