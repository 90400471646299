import React from "react"
import Container from "../components/Common/Container/Container"

export default function Footer() {
  let date = new Date()
  let thisYear = date.getFullYear()

  return (
    <div
      className="p-3 pt-6 pb-20 md:pb-6 bg-blue text-blue300 text-left  text-xs md:text-sm"
      style={{
        borderTop: `2px solid rgba(146, 153, 184, .6)`,
      }}
    >
      <Container>
        Copyright Ⓒ {thisYear} Miya's company All Rights Reserved. <br />{" "}
        Designed & Developed by{" "}
        <a href="mailto:hi@greenriverstudio.art">
          <strong>Green River Studio</strong>
        </a>
      </Container>
    </div>
  )
}
