// extracted by mini-css-extract-plugin
export var seconday__wrapper = "secondary-module--seconday__wrapper--e1v3H";
export var header = "secondary-module--header--yuv8a";
export var more = "secondary-module--more--2Z0TU";
export var info = "secondary-module--info--RbGBf";
export var card = "secondary-module--card--u85kB";
export var desc = "secondary-module--desc--8md-q";
export var time__wrapper = "secondary-module--time__wrapper--tikiq";
export var test = "secondary-module--test--qd9uZ";
export var play__wrapper = "secondary-module--play__wrapper--r0DLw";
export var container = "secondary-module--container--FSOZn";
export var play__icon = "secondary-module--play__icon--aXlEz";
export var pause__icon = "secondary-module--pause__icon--3aYdg";
export var action = "secondary-module--action--ZPKmD";
export var icon__wrapper = "secondary-module--icon__wrapper--BATv6";
export var next = "secondary-module--next--tfTwk";
export var prev = "secondary-module--prev--atp6T";
export var dark = "secondary-module--dark--1efMv";